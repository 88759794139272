import React, { useEffect, useState } from 'react'
import CareerHeader from '../careerTemplate/CareerHeader'
import HeaderIMG from '../job/HeaderIMG'
import BewareFraud from '../headerPage/BewareFraud'
import FooterPage from '../headerPage/FooterPage'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';

function ResponForm({ jobID }) {
  const navigate = useNavigate();
  const orientation = useOrientation();
    const [imageLink, setImageLink] = useState("Header.png");

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

  useEffect(() => {
      //getData();
  }, [jobID]);

    const getData = () => {
        var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobListWithID/${jobID}`;   
        axios.get(path)
        .then(res => {
          console.log(res.data.data);
          if (res.data.status = "200"){
              setImageLink(res.data.data.image_link);
          }
        })
        .catch(err => {
          console.log(err);
        })
      }  

  if (orientation === 'landscape')
  return (
    <div className="font-befa" style={{maxHeight: '100%', maxWidth:'100vw' }} >
    <div className="">
        <header className="fixed-header">
          <CareerHeader/> 
        </header>
        <div className="center"    >
          {/* <HeaderIMG link={imageLink}/> */}
          <br/>
          <br/><br/><br/>
          <div id="submitID" className="center" style={{ alignItems: 'flex-start' }} >
            <div className="center" 
                style={{
                    backgroundColor: '#FFFFFF', 
                    backgroundImage: 'url(/CandidateDetailBG.png)', 
                    backgroundPosition: 'top right', 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 150px',
                    width:'70%',
                    marginLeft:'15%',
                    border: '1px solid #E8E9EB',
                    borderRadius:'8px'
                }}
            > 
            <div style={{marginTop:'48px', marginLeft:'80px'}}>
                <div style={{textAlign:'center'}}>
                    <br/>
                    <h2 className="font-befa" style={{fontSize:'32px', fontWeight:'700'}}> Application Submitted </h2>
                    <br></br>
                    <br></br>
                    <p  className="font-befa" style={{fontSize:'16px', fontWeight:'600', color:'#0296D2'}}> Thank you for applying </p>
                    <br/><br/>
                </div>
            </div>
            </div>
            <br/><br/>
          </div>
        </div>
    </div>
    <BewareFraud />
    <FooterPage />
  </div>
  )

  
  if (orientation === 'portrait')
    return (
      <div className="font-befa" style={{maxHeight: '100%', maxWidth:'100vw' }} >
      <div className="">
          <header className="">
          <div className="header-m" style={{marginTop:'-0px'}}>
            <div className="center">
              <h1 className='font-befa'>Career at</h1>
              <h1></h1>
              <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
            </div>
          </div>
          </header>
          <div className="center"    >
            {/* <HeaderIMG link={imageLink}/> */}
            <br/>
            <br/><br/><br/>
            <div id="submitID" className="center" style={{ alignItems: 'flex-start' }} >
              <div className="center" 
                  style={{
                      backgroundColor: '#FFFFFF', 
                      backgroundImage: 'url(/CandidateDetailBG.png)', 
                      backgroundPosition: 'top right', 
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto 150px',
                      width:'90%',
                      marginLeft:'5%',
                      border: '1px solid #E8E9EB',
                      borderRadius:'8px'
                  }}
              > 
              <div style={{marginTop:'48px', marginLeft:'0px'}}>
                  <div style={{textAlign:'center'}}>
                      <br/>
                      <h2 className="font-befa" style={{fontSize:'32px', fontWeight:'700'}}> Application Submitted </h2>
                      <br></br>
                      <br></br>
                      <p className="font-befa" style={{fontSize:'16px', fontWeight:'600', color:'#0296D2'}}> Thank you for applying </p>
                      <br/><br/>
                  </div>
              </div>
              </div>
              <br/><br/>
            </div>
          </div>
      </div>
      <BewareFraud />
      <FooterPage />
    </div>
    )
}

export default ResponForm