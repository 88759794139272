import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function HtmlInput({txt,desc,setDesc}) {
  const handleDescChange = (value) => {
    setDesc(value);
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
    ],
  };

  return (
    <>
    <h5 className='font-befa'>{txt} <span style={{ color: 'red' }}>*</span></h5>
    <div>
        <ReactQuill
            value={desc}
            onChange={handleDescChange}
            modules={modules}
            className='inputText font-befa'
            style={{minHeight:'10vw', width:'90%'}}
        />
    </div>
    
    </>
  )
}

export default HtmlInput