import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import '../../../scripts/custom.css';
import LoginComp from './LoginComp';
import ResetComp from './ResetComp';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
const cookies = new Cookies();

function Login() {
  const [login, setLogin] = useState([]);
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [forgotpassEmail, setForgotpassEmail] = useState("")
  const [errorMsg, setErrorMsg] = useState([]);
  const [loginReset, setLoginReset] = useState([]);
  const [loading, setLoading] = useState([]);
  const [loginButtonText, setLoginButtonText] = useState([]);
  const Navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const changeScreen = () => {
    setErrorMsg("");
    setLoginReset(!loginReset);
  }

  const setFPEmail = (txt) => {
    setForgotpassEmail(txt);
  }

  const handleSubmit = function(e) {
    e.preventDefault();
    setErrorMsg("");
    setLoading(true);
    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/loginValidation`, { email: username, password: password })
    .then(function (res) {
      //console.log("resData",resData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      if (res.data.status === "300" || res.data.status === "401") {
        console.log("login session failed error status " + res.data.status);
        setTimeout(() => {
          setErrorMsg(res.data.message);
        }, 1000);
        
      } else {
        cookies.set('sessionToken', res.data.data.token, { path: '/' });
        cookies.set('sessionData', res.data.data, { path: '/' });
        cookies.set('sessionExist', true, { path: '/' });
        //setErrorMsg("Login Success");
        setTimeout(() => {
          setLoginButtonText("Welcome");
        }, 1000);
        
        // navigate to dashboard

        //alert("writing session");
        setTimeout(() => {
          window.location.replace("/admin");
        }, 1500);
      }
    })
    .catch(err => {
      console.log(err)
    })
    
  }

  const handleForgotPass = function(e) {
    e.preventDefault();
    
    setErrorMsg("");
    // Regular expression to check if the email is in the right format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    if (emailRegex.test(forgotpassEmail)) {
      // Email is in the right format
          
    MySwal.fire({
      title: 'Processing Your Request...',
      text: 'Please wait while we process your request.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
          MySwal.showLoading();
      }
    });

    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/rememberPass`, { email: forgotpassEmail })
      .then(function (res) {
        MySwal.close();
        console.log(res.data);
        setErrorMsg(res.data.message);
      })
      .catch(err => {
        MySwal.close();
        setErrorMsg("Something went wrong");
        console.log(err)
      })
    } else {
      setErrorMsg("Please input a valid email");
    }
  }

  useEffect(() => {
    setLoginReset(false);
    setLoading(false);
    setLoginButtonText("Login");
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {
      headers:{
          ['x-access-token']: sessionToken
      }
    };
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getSession`,config) 
    .then(res => {
      if (res.data.status === "200") {
        //Session Detected. Getting the data
        console.log("session exist");
        cookies.set('sessionExist', true, { path: '/' });
        cookies.set('sessionData', res.data, { path: '/' });
        //window.location.replace(process.env.REACT_APP_SELF_PATH_DESTINATION);
      } else {
        //No session detected. Deleting All known session
        cookies.remove('sessionToken');
        cookies.remove('sessionData');
        cookies.remove('sessionExist');
        
        //just show the normal login stuff if no session
        //window.location.replace(process.env.REACT_APP_PORTAL_DESTINATION);
      }
    })
    .catch(err => {
      //Session Error
      cookies.remove('sessionToken');
      cookies.remove('sessionData');
      cookies.remove('sessionExist');
      console.log(err);
      //just show the normal login stuff if no session
      //window.location.replace(process.env.REACT_APP_PORTAL_DESTINATION);
    })
  }, [])

  if (loginReset){
    return (
      <ResetComp isLoading={loading} changeScreen={changeScreen} handleSubmit={handleForgotPass} forgotpassEmail={forgotpassEmail} setForgotPassEmail={setFPEmail} login={login} errorMsg={errorMsg}/>
    )
  } else {
    return (
      <LoginComp isLoading={loading} buttonText={loginButtonText} changeScreen={changeScreen} handleSubmit={handleSubmit} username={username} password={password} setUsername={setUsername} setPassword={setPassword} login={login} errorMsg={errorMsg}/>
    )
  }
  
}
export default Login