import React from 'react'; import { useState, useEffect} from 'react';
import {FilterListCircle, DownloadCircle, NavArrowRight, MailOut} from 'iconoir-react'
import axios from 'axios' 
import Cookies from 'universal-cookie';
import '../../../scripts/custom.css';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import CareerHeader from '../careerTemplate/CareerHeader';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import CandListing from './CandListing';
const cookies = new Cookies();
function CandidateParent() {
    const MySwal = withReactContent(Swal)
    const { jobID } = useParams();
    const [filterExpand, setFilterExpand] = useState(false);
    const [data, setData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [jobPeriodData, setJobPeriodData] = useState([]);
    const [statusID, setStatusID] = useState("2");
    const [periodID, setPeriodID] = useState("0");
    const [jobTitleString, setJobTitleString] = useState("");
    const [statusIDString, setStatusIDString] = useState("All");
    const [periodIDString, setPeriodIDString] = useState("All");
    const [currentState, setCurrentState] = useState("");
    const [secondLevel, setSecondLevel] = useState("");
    const [hoveredRow, setHoveredRow] = useState(null);
    const [searchStr, setSearchStr] = useState("");
    const controls = useAnimation();
    const navigate = useNavigate();
    useEffect(() => {
      getStatusData();
    }, [])

    useEffect(() => {
      getData();
    }, [jobID,statusID,periodID])

    useEffect(() => {
        setStatusID("0")
        setPeriodID("0")
        getJobPeriodData();
    }, [jobID])
    
    const getData = () => {
      var sessionToken = cookies.get('sessionToken', { path: '/' })
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantList/${jobID}/${statusID}/${periodID}`;   
      const config = {headers:{['x-access-token']: sessionToken}};
      axios.get(path, config)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status = "200"){
          setData(res.data.data);
          setJobTitleString(res.data.data[0].title);
        }
      })
      .catch(err => {
        console.log(err);
      })  
    }  

        
    const getStatusData = () => {
      var sessionToken = cookies.get('sessionToken', { path: '/' })
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantStatus`;   
      const config = {headers:{['x-access-token']: sessionToken}};
      axios.get(path, config)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status = "200"){
          setStatusData(res.data.data);
        }
      })
      .catch(err => {
        console.log(err);
      })  
    } 
        
    const getJobPeriodData = () => {
      var sessionToken = cookies.get('sessionToken', { path: '/' })
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobPeriod/${jobID}`;   
      const config = {headers:{['x-access-token']: sessionToken}};
      axios.get(path, config)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status = "200"){
          setJobPeriodData(res.data.data);
        }
      })
      .catch(err => {
        console.log(err);
      })  
    } 
        
    const sendAcceptEmail = (id) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: "Do you want to send the acceptance email?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: 'Sending email...',
            text: 'Please wait while we send your email.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              MySwal.showLoading();
            }
          });
    
          var sessionToken = cookies.get('sessionToken', { path: '/' });
          var path = process.env.REACT_APP_PATH_DESTINATION + `/sendMailAcc/${id}`;   
          const config = {headers:{['x-access-token']: sessionToken}};
          axios.get(path, config)
            .then(res => {
              console.log(res.data.data);
              if (res.data.status == '200'){
                MySwal.fire({
                  icon: 'success',
                  title: 'Email Sent',
                  text: `Email acceptance sent successfully.`,
                  willClose: () => {
                    getData();
                  }
                });
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `Error request. ${res.data.message}`,
                  willClose: () => {
                    getData();
                  }
                });
              }
            })
            .catch(err => {
              console.log(err);
              MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: `Failed to send email acceptance.`,
                willClose: () => {
                  getData();
                }
              });
            });
        }
      });
    };
    
    const sendRejectEmail = (id) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: "Do you want to send the rejection email?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: 'Sending email...',
            text: 'Please wait while we send your email.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              MySwal.showLoading();
            }
          });
    
          var sessionToken = cookies.get('sessionToken', { path: '/' });
          var path = process.env.REACT_APP_PATH_DESTINATION + `/sendMailReject/${id}`;   
          const config = {headers:{['x-access-token']: sessionToken}};
          axios.get(path, config)
            .then(res => {
              console.log(res.data.data);
              if (res.data.status == '200'){
                MySwal.fire({
                  icon: 'success',
                  title: 'Email Sent',
                  text: `Email rejection sent successfully.`,
                  willClose: () => {
                    getData();
                  }
                });
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `Error request. ${res.data.message}.`,
                  willClose: () => {
                    getData();
                  }
                });
              }
            })
            .catch(err => {
              console.log(err);
              MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: `Failed to send email rejection.`,
                willClose: () => {
                  getData();
                }
              });
            });
        }
      });
    };
    
    
    const tableListDL = () => {
      MySwal.fire({
        title: 'Processing Your Request...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            MySwal.showLoading();
        }
      });

      var sessionToken = cookies.get('sessionToken', { path: '/' })
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantListExport/${jobID}/${statusID}/${periodID}`;   
      const config = {headers:{['x-access-token']: sessionToken}};
      axios.get(path, config)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status == "200"){
          console.log(res.data.data)

          // Trigger file download
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', ''); // Setting download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Download will start shortly. Or <a href = {res.data.data} target="_blank">click here</a> if it doesnt start</p>
          })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: `Error request. ${res.data.message}.`,
          });
        }
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <p>Something went wrong!</p>
        })
      })  
    } ;
    const tableDetailDL = () => {
      MySwal.fire({
        title: 'Processing Your Request...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            MySwal.showLoading();
        }
      });

      var sessionToken = cookies.get('sessionToken', { path: '/' })
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantListDetailExport/${jobID}/${statusID}/${periodID}`;   
      const config = {headers:{['x-access-token']: sessionToken}};
      axios.get(path, config)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status == "200"){
          console.log(res.data.data)

          // Trigger file download
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', ''); // Setting download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Download will start shortly. Or <a href = {res.data.data} target="_blank">click here</a> if it doesnt start</p>
          })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: `Error request. ${res.data.message}.`,
          });
        }
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <p>Something went wrong!</p>
        })
      })  
    } ;
    const filterClicked = () => {
        setFilterExpand(!filterExpand)
        setCurrentState("");
        setSecondLevel("");
    } 

    const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    };

    const rightButtonsStyle = {
    display: 'flex',
    gap: '0px', 
    };

    const tableCand = {
        minWidth: '94vw',
        borderCollapse: 'collapse'
    };
      
    const thtdCand = {
        border: "1px solid #ddd",
        padding: "8px"
    };
    
    const finalColumnStyle = {
        border: "1px solid #ddd",
        padding: "8px",
        whiteSpace: 'nowrap'
    };
    

    const animationVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { opacity: 1, y: 0 },
    };


    
    const candidateListSection = {
      background: "#fff",
      borderRadius:'10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '40px',
      margin: '20px auto',
      overflowX : 'scroll',
    }
    const handleClickStatus = () => {
      changeLevelTwo("status");
      setCurrentState("status");
    };
    const handleClickPeriod = () => {
      changeLevelTwo("period");
      setCurrentState("period");
    };
    const changeLevelTwo = (str) => {
      //enable level 2
      setSecondLevel(str);
    };    
    const chooseFilterStatus = (id,str) => {
      setFilterExpand(!filterExpand)
      setCurrentState("");
      setSecondLevel("");
      setStatusID(id)
      setStatusIDString(str)
    };
    const chooseFilterPeriod = (id,str) => {
      setFilterExpand(!filterExpand)
      setCurrentState("");
      setSecondLevel("");
      setPeriodID(id)
      setPeriodIDString(str)
    };
    const navigateAdminHome = () => {
      navigate("/admin");
    };
    const outerDivClassStatus = currentState === "status" ? 'outerTenantDiv outerClickedClass' : 'outerTenantDiv';
    const outerDivClassPeriod = currentState === "period" ? 'outerTenantDiv outerClickedClass' : 'outerTenantDiv';
  
    const getColorByStatus = (statusName) => {
      switch (statusName) {
        case 'New':
          return '#1CC8B5';
        case 'Read':
          return '#0CA796';
        case 'Unfit':
          return '#E9AC43';
        case 'Process':
          return '#0296D2';
        case 'Pending':
          return '#A3711C';
        case 'Rejected':
          return '#FF553B';
        case 'Accepted':
          return '#4A3DA1';
        default:
          return '#0CA796'; // Default to 'Read' color
      }
    };
      
  const handleSearchChange = (e) => {
    setSearchStr(e.target.value);
  };

  return (
    <div className="">
    <div className="" >
      <div className="">
      <header className="fixed-header">
        <CareerHeader /> 
        </header>
        <main>
        <section style={candidateListSection} >
          <h5 style={{marginTop:'20px'}}> <span className="cursorPointer" onClick={navigateAdminHome} style={{color:'#737E86'}}> Home </span> / {jobTitleString} </h5>
          <h2> Candidate List {jobTitleString} </h2>
          <p> Status Filter: <b>{statusIDString}</b> | Period Filter: <b> {periodIDString}</b></p>
          <div style={buttonContainerStyle}>
            <button onClick={filterClicked} className='cand-button'><FilterListCircle /> Filter</button>
            <div style={rightButtonsStyle}>
              <input type='text' style={{marginRight:'10px', border: "2px solid #ddd",}} className='search-bar-cand' placeholder='type to search' value={searchStr} onChange={handleSearchChange} />
              <button onClick={tableListDL} style={{marginRight:'10px'}} className='cand-button'><DownloadCircle onClick={tableListDL}/> Table Lists</button>
              <button onClick={tableDetailDL} className='cand-button'><DownloadCircle onClick={tableDetailDL}/> Table Details</button>
            </div>
          </div>
          <div>
          {filterExpand && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: '0vw',
                height: `auto`,
                width: 'auto',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              initial="hidden"
              animate={filterExpand ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
            <div style={{marginTop:'0.5vw'}} className={outerDivClassStatus} onClick={handleClickStatus}>
              <div style={{ width:'auto', height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv" >
                Status <NavArrowRight className="tenantTicketIcon" /> 
              </div>
            </div>
            <div style={{marginTop:'0.5vw'}} className={outerDivClassPeriod} onClick={handleClickPeriod}>
              <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv" >
                Application Period <NavArrowRight className="tenantTicketIcon" /> 
              </div>
            </div>
            </motion.div>
            <div style={{marginLeft: '15w'}} >
            {secondLevel === "status" && (
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0vw',
                  marginLeft: '12.5vw',
                  overflowY: 'scroll',
                  height: 'auto',
                  maxHeight: '25vw',
                  width: 'auto',
                  minWidth: '10vw',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F'
                }}
                initial="hidden"
                animate={secondLevel === "status" ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div key={"all"} className="outerTenantDiv">
                  <div onClick={() => chooseFilterStatus(0,"All")} className="tenantTicketDiv">
                    All
                  </div>
                </div>
                {statusData.map((dataStatus) => (
                  <div key={dataStatus.id} className="outerTenantDiv">
                    <div onClick={() => chooseFilterStatus(dataStatus.id,dataStatus.status_name)} className="tenantTicketDiv">
                      <span style={{ color: getColorByStatus(dataStatus.status_name) }}>
                        {dataStatus.status_name}
                      </span>
                    </div>
                  </div>
                ))}
              </motion.div>
            )}
            {secondLevel === "period" && (
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0vw',
                  marginLeft: '12.5vw',
                  overflowY: 'scroll',
                  height: 'auto',
                  maxHeight: '25vw',
                  width: 'auto',
                  minWidth: '12vw',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F'
                }}
                initial="hidden"
                animate={secondLevel === "period" ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div key={"all"} className="outerTenantDiv" style={{paddingRight:'20px'}}>
                  <div onClick={() => chooseFilterPeriod(0,"All")} className="tenantTicketDiv">
                    All
                  </div>
                </div>
                {jobPeriodData.map((dataPeriod) => (
                  <div style={{paddingRight:'20px'}} key={dataPeriod.current_period} className="outerTenantDiv">
                    <div onClick={() => chooseFilterPeriod(dataPeriod.current_period,dataPeriod.current_period_str)} className="tenantTicketDiv">
                      {dataPeriod.current_period_str}
                    </div>
                  </div>
                ))}
              </motion.div>
            )}
            </div>
            </>
          )}
          </div>

          <div className="">
          <br/>
          <CandListing 
            data = {data} 
            jobID={jobID} 
            sendAcceptEmail={sendAcceptEmail} 
            sendRejectEmail={sendRejectEmail}
            searchStr={searchStr}
          /> 
          </div>
        </section>
      </main>
        </div>
    </div>
  </div>
  )
}

  
  
export default CandidateParent