import React, { useEffect, useState } from 'react'
import {MailOut} from 'iconoir-react'
import { useNavigate } from 'react-router-dom';
function CandListing({data,jobID, sendAcceptEmail,sendRejectEmail, searchStr }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
      

    const filteredData = data.filter(data => {
      const title = data.title ? data.title.toLowerCase() : '';
      const name = data.name ? data.name.toLowerCase() : '';
      const bdayDate = data.birth_date ? new Date(data.birth_date) : null;
      const age = data.age ? String(data.age) : '';
      const gpa = data.gpa ? String(data.gpa) : '';
      const companyname = data.company_name ? data.company_name.toLowerCase() : '';
      
      const search = searchStr.toLowerCase();
    
      // Convert the date components to lowercase strings for comparison
      const birthMonth = bdayDate ? bdayDate.toLocaleString('default', { month: 'long' }).toLowerCase() : ''; // Full month name
      const birthYear = bdayDate ? String(bdayDate.getFullYear()) : '';
      const birthDay = bdayDate ? String(bdayDate.getDate()) : '';
    
      return (
        title.includes(search) ||
        name.includes(search) ||
        birthMonth.includes(search) ||  
        birthYear.includes(search) ||   
        birthDay.includes(search) ||  
        age.includes(search) ||
        gpa.includes(search) ||
        companyname.includes(search)
      );
    });
    
    
    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Change page
    const paginate = pageNumber => {
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const tableCand = {
        minWidth: '94vw',
        borderCollapse: 'collapse'
    };
    const thtdCand = {
        border: "1px solid #ddd",
        padding: "8px"
    };
    const getColorByStatus = (statusName) => {
      switch (statusName) {
        case 'New':
          return '#1CC8B5';
        case 'Read':
          return '#0CA796';
        case 'Unfit':
          return '#E9AC43';
        case 'Process':
          return '#0296D2';
        case 'Pending':
          return '#A3711C';
        case 'Rejected':
          return '#FF553B';
        case 'Accepted':
          return '#4A3DA1';
        default:
          return '#0CA796'; // Default to 'Read' color
      }
    };
    const handleRowClick = (id) => {
      navigate(`/admin/candidateDetail/${jobID}/${id}`)
    } ;


    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = months[date.getMonth()];
      const day = date.getDate();
      
      return `${day} ${month} ${year}`;
    }

    useEffect(() => {
      paginate(1);
    }, [data, searchStr]);

    if (currentItems.length > 0 )
    return (<>
    <table style={tableCand}>
      <thead>
        <tr style={{ backgroundColor: '#E8E9EB' }}>
          <th style={thtdCand}>Position</th>
          <th style={thtdCand}>Full Name</th>
          <th style={thtdCand}>Birth Date</th>
          <th style={thtdCand}>GPA</th>
          <th style={thtdCand}>Company's Name</th>
          <th style={thtdCand}>CV</th>
          <th style={thtdCand}>Status</th>
          <th style={thtdCand}>Send Email</th>
          <th style={thtdCand}>Application Period</th>
        </tr>
      </thead>
      <tbody>
      {currentItems && currentItems.length > 0 && currentItems.map((row, index) => (
        <tr onClick={() => handleRowClick(row.id)} key={index} className={index % 2 === 0 ? 'tableWhite tableHover' : 'tableGray tableHover' }>
          <td style={thtdCand}>{row.title}</td>
          <td style={thtdCand}>{row.name}</td>
          <td style={thtdCand}>{formatDate(row.birth_date)} ({row.age})</td>
          <td style={thtdCand}>{row.gpa}</td>
          <td style={thtdCand}>{row.company_name}</td>
          <td style={thtdCand}>
            <a target="_blank" onClick={(e) => e.stopPropagation()} href={`${process.env.REACT_APP_IMG_DESTINATION}${row.cv_address}`}>{row.cv_address}</a>
          </td>
          <td style={thtdCand}>
            <span style={{ color: getColorByStatus(row.status_name) }}>
              {row.status_name}
            </span>
          </td>
          <td style={thtdCand}>
            {row.applicant_status_id === 4 && row.email_accepted_sent === 0 && ( 
              <button onClick={(e) => { e.stopPropagation(); sendAcceptEmail(row.id); }} className='cand-button' style={{widht:'10px'}}><MailOut /> Send</button>
            )}
            {row.applicant_status_id === 5 && row.email_decline_sent === 0 && ( 
              <button onClick={(e) => { e.stopPropagation(); sendRejectEmail(row.id); }} className='cand-button' style={{widht:'10px'}}><MailOut /> Send</button>
            )}
            {row.applicant_status_id === 4 && row.email_accepted_sent === 1 && ( 
              <>Email Sent</>
            )}
            {row.applicant_status_id === 5 && row.email_decline_sent === 1 && ( 
              <>Email Sent</>
            )}
          </td>
          <td style={{ ...thtdCand, whiteSpace: 'nowrap' }}>{row.job_current_period_str}</td>
        </tr>
      ))}
      </tbody>
    </table>

    {/* Pagination */}
    <div className="pagination" style={{marginTop:'60px', marginBottom:'60px'}}>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
          onClick={() => paginate(i + 1)}
        >
          {i + 1}
        </button>
      ))}
      <button
        className="pagination-btn next-page-btn font-befa"
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        <span>&gt;</span>
      </button>
    </div>
  </>)
}

export default CandListing