import React from 'react'; import { useState, useEffect,useRef} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {StatsUpSquare,DownloadSquare,CheckCircle,Prohibition,Clock} from 'iconoir-react'
import Cookies from 'universal-cookie';import axios from 'axios' ;
import CandidDetHelper from './CandidDetHelper';
import CandButton from './CandButton';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import CandDetInput from './CandDetInput';
import CareerHeader from '../careerTemplate/CareerHeader';
import DateTimeInput from '../applyForm/DateTimeInput';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const cookies = new Cookies();
function CandidateDetail() {
  const targetRef = useRef();
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const { jobID,id } = useParams();
  const [data, setData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [statusString, setstatusString] = useState([]);
  const [statusState, setStatusState] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [entity, setEntity] = useState("");
  const [role, setRole] = useState("");
  const [joinDatetime, setJoinDatetime] = useState("");
  const [firstDayLoc, setFirstDayLoc] = useState("");
  const [firstDayAdr, setFirstDayAdr] = useState("");
  const [dressCode, setDressCode] = useState("");
  const [picContact, setPicContact] = useState("");
  const [accEditState, setAccEditState] = useState(false);
  
  const candidateListSection = {
    background: "#fff",
    borderRadius:'10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px',
    margin: '20px auto',
    maxWidth: '100vw',
  }
  const navigateAdminHome = () => {
    navigate("/admin");
  };
  const navigateJob = () => {
    navigate("/admin/candidateList/" + jobID);
  };

  useEffect(() => {
    getData();
  }, [])
  const acceptAction = () => {
    setStatusState("accept");
    setstatusString("Accepted")
  };
  const rejectAction = () => {
    setStatusState("reject");
    setstatusString("Rejected")
  };
  const acceptEditState = () => {
    setAccEditState(true);
  };
  const normalAction = (id, string) => {
    setStatusState("");
    MySwal.fire({
      title: '<strong>Confirmation</strong>',
      html: `<p>Status will change to ${string} </p>`,
      width:'800px',
      showCancelButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed'
    }).then(result => {
      if (result.isConfirmed) {
        // Handle the "Proceed" button click
        setStatusApplicantNormal(id) ;
      } else {
        if (id == 3) { setstatusString("Pending")}
        if (id == 7) { setstatusString("Process")}
        MySwal.close()
      }
    })
  };
  const acceptWithDetail = (editOrAdd) => {
    setStatusState("");
    if (entity === "" || role === "" | joinDatetime === "" | firstDayLoc === ""
      || firstDayAdr === "" || dressCode === "" || picContact === "") {
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <p>Please fill every field</p>
      })
      return 
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicantAccept${editOrAdd}`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: 4, id: id, entity: entity,role:role, 
      joinDatetime:joinDatetime, firstDayLoc:firstDayLoc,
      firstDayAdr:firstDayAdr, dressCode:dressCode, picContact:picContact};  
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Succcess</strong>,
            html: <p>Data Edited</p>
          })
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  };


  const downloadDivAsPDFjj = () => {
    const input = document.getElementById('pdfDiv');
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight()
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth,pdfHeight );
        pdf.save(`Profile Karyawan - ${data.name} (${data.title}).pdf`);
      })
      .catch(error => {
        alert("Error generating PDF: " + error)
        console.error('Error generating PDF:', error);
      });
  };
   
  const downloadDivAsPDsF= () => {
    const input = document.getElementById('pdfDiv');
  
    // Ensure all images in the div are fully loaded before capturing
    const images = input.querySelectorAll('img');
    const loadPromises = Array.from(images).map(img => {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = reject;
        }
      });
    });
  
    Promise.all(loadPromises)
      .then(() => {
        return html2canvas(input, { useCORS: true });
      })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Profile Karyawan - ${data.name} (${data.title}).pdf`);
      })
      .catch(error => {
        alert("Error generating PDF: " + error);
        console.error('Error generating PDF:', error);
      });
  };

  const downloadDivAsPDFdsfdsf = async () => {
    const pdfDiv = document.getElementById("pdfDiv");
  
    // Manually add the image from a URL
    //const imgURL = process.env.REACT_APP_IMG_DESTINATION + data.photo_address; // Replace with your image URL
    //const imgURL = "http://localhost:8123/uploads/" + String(data.photo_address).replaceAll(' ','%20'); // Replace with your image URL
    const imgURL = "http://localhost:8123/uploads/mac.png"
    console.log("imgurl = " + imgURL)
    const pdf = new jsPDF('p', 'mm', 'a4');
  
    // Create an Image object
    const img = new Image();
    img.src = imgURL;
  
    img.onload = function () {
      // Calculate image dimensions to maintain aspect ratio
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (img.height * imgWidth) / img.width;
  
      // Add the image to the PDF document
      pdf.addImage(img, 'PNG', 0, 0, '5', '5');
  
      // Optionally, add other content from the div
      const content = pdfDiv.innerText || pdfDiv.textContent;
      pdf.text(10, imgHeight + 10, content); // Add text below the image
  
      // Save the PDF document
      pdf.save("candidate-detail.pdf");
    };
  
    img.onerror = function () {
      console.error("Failed to load the image.");
    };
  };
  

  
  const downloadDivAsPDFdsfddsf = () => {
    const imgURL = process.env.REACT_APP_IMG_DESTINATION + data.photo_address;
    const img = new Image();
    img.src = imgURL;
    img.onload = () => {
      const imgWidth = 50; // Desired width in px
      const imgHeight = (img.height / img.width) * imgWidth; // Maintain aspect ratio
  
      const pdf = new jsPDF();
  
      // Add content to the first page
      const input = document.getElementById('pdfDiv');
      html2canvas(input, { useCORS: false })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
  
          // Calculate the aspect ratio of the content
          const contentWidth = canvas.width;
          const contentHeight = canvas.height;
          const aspectRatio = contentWidth / contentHeight;
  
          // Scale the content to fit the width of the PDF page
          const scaledWidth = pdfWidth;
          const scaledHeight = pdfWidth / aspectRatio;
  
          pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
  
          // Add a new page for the image
          pdf.addPage();
  
          // Calculate the position to center the image horizontally and place it at the top with a margin
          const xPos = (pdfWidth - imgWidth) / 2;
          const yPos = 30; // Margin from the top
  
          pdf.addImage(img, 'PNG', xPos, yPos, imgWidth, imgHeight);
  
          pdf.save(`Profile Karyawan - ${data.name} (${data.title}).pdf`);
        })
        .catch(error => {
          alert("Error generating PDF: " + error);
          console.error('Error generating PDF:', error);
        });
    };
    img.onerror = (error) => {
      alert("Error loading image: " + error);
      console.error('Error loading image:', error);
    };
  };
  
  const downloadDivAsPDF = () => {
    window.open(`/admin/candidateExport/${jobID}/${id}`, '_blank');
  }
  const rejectWithDetail = () => {
    //alert("sdsadsadsa");
    //gather data, clicked when its 
    setStatusState("");
    if (rejectReason === "") {
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <p>Reject reason is required</p>
      })
      return 
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicantReject`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: 5, id: id, reject_reason: rejectReason };  
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  };

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantDetail/${id}`;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      console.log(res.data.data);
      if (res.data.status = "200"){
        setData(res.data.data);
        setWorkData(res.data.workData);
        setstatusString(res.data.data.status_name);
        //if ACC
        if (res.data.data.applicant_status_id == 4){
          //alert("alerted acc entered id = " + res.data.data.id);
          var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantStatusAcc/${res.data.data.id}`;   
          const config = {headers:{['x-access-token']: sessionToken}};
          axios.get(path, config)
          .then(res => {
            console.log(res.data.data);
            if (res.data.status = "200"){
              setEntity(res.data.data.entity);
              setRole(res.data.data.role);
              setJoinDatetime(res.data.data.join_date);
              setFirstDayLoc(res.data.data.first_day_location);
              setFirstDayAdr(res.data.data.first_day_address);
              setDressCode(res.data.data.dress_code);
              setPicContact(res.data.data.pic_contact);
            }
          })
          .catch(err => {
            console.log(err);
          })  
        }
      }
    })
    .catch(err => {
      console.log(err);
    })  
  }  

  const setStatusApplicantNormal = (statusID) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicant`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: statusID, id: id };  
    
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    
    return `${day} ${month} ${year}`;
  }
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Adding leading zeros to hours, minutes, and seconds if needed
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${day} ${month} ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  function imageDL(link) {
    window.open(link, '_blank');
  }
  return (
    <div className="">
    <div className="" >
      <div className="">
      <header className="fixed-header">
        <CareerHeader />
        </header>
        <main>
        <section style={candidateListSection} >
          <h5 style={{marginTop:'20px'}}> 
            <span className="cursorPointer" onClick={navigateAdminHome} style={{color:'#737E86'}}> Home </span> 
            / 
            <span className="cursorPointer" onClick={navigateJob} style={{color:'#737E86'}}> {data.title} </span> 
            / {data.name} 
          </h5>
          <h2> Candidate Detail  </h2>
          <br/>
          <div className="row" style={{ display: 'flex', alignItems: 'flex-start' }} >
            <div ref={targetRef} id="pdfDiv" className="" 
                style={{
                    backgroundColor: '#F5F5F6', 
                    backgroundImage: 'url(/CandidateDetailBG.png)', 
                    backgroundPosition: 'top right', 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 150px',
                    width:'60%',
                    border:'border: 1px solid #E8E9EB',
                    borderRadius:'8px'
                }}
            >
                <div style={{marginTop:'48px', marginLeft:'80px', paddingRight:'30px'}}>
                    <div className='row' style={{marginBottom:'48px'}}>
                        <CandidDetHelper header="Job Position" text={data.title}/>
                        <CandidDetHelper header="Full Name" text={data.name}/>
                        <CandidDetHelper header="Birth Date" text={formatDate(data.birth_date) + ` (${data.age})`}/>
                        <CandidDetHelper header="Full Domicile Address" text={data.address}/>
                        <CandidDetHelper header="Email" text={data.email}/>
                        <CandidDetHelper header="Phone Number" text={data.phone}/>
                        <CandidDetHelper header="Latest Education Level" text={data.last_education}/>
                        <CandidDetHelper header="Major" text={data.major}/>
                        <CandidDetHelper header="Institution" text={data.school_univ}/>
                        <CandidDetHelper header="Enrollment Year" text={data.enroll_year}/>
                        <CandidDetHelper header="Graduation Year" text={data.grad_year}/>
                        {workData.length > 0 && (
                          <div style={{marginTop:'15px'}} className='col-md-12'>
                             
                          </div>
                        )}
                        {workData.map((experience, index) => (<>
                          <div key={index} style={{marginBottom:'10px'}} className='col-md-12'>
                            <h5 style={{color:'#737E86'}}>Experience {index + 1}</h5>
                          </div>
                          <CandidDetHelper header="Company Name" text={experience.company_name}/>
                          <CandidDetHelper header="Company Sector" text={experience.company_sector}/>
                          <CandidDetHelper header="Start Date" text={formatDate(experience.start_date)}/>
                          <CandidDetHelper workHere={experience.still_work_here} header="End Date" text={formatDate(experience.quit_date)}/>
                          <CandidDetHelper header="Position & Division" text={experience.position_division}/>
                          <CandidDetHelper header="Job Description" text={experience.job_desc}/>
                          <CandidDetHelper header="Reason of Resignation" text={experience.quit_reason}/>
                          <CandidDetHelper header="Notice Period" text={experience.notice_period}/>
                          
                          <div key={index} style={{marginBottom:'15px'}} className='col-md-12'>
                             
                          </div>
                          </>
                        ))}

                        
                        <CandidDetHelper header="Job Info From" text={data.find_from}/>
                        <div style={{marginBottom:'10px'}} className='col-md-4'> <h5>Photo</h5> </div>
                        <div className='col-md-8'>
                          <img className="cursorPointer" onClick={() => imageDL(process.env.REACT_APP_IMG_DESTINATION + data.photo_address)} style={{maxWidth:'350px'}} src={process.env.REACT_APP_IMG_DESTINATION + data.photo_address}/>
                        </div>
                        <div style={{marginBottom:'10px',marginTop:'10px'}} className='col-md-4'> <h5>CV</h5> </div>
                        <div style={{marginBottom:'10px',marginTop:'10px'}} className='col-md-8'>
                          <a target="_blank" href={process.env.REACT_APP_IMG_DESTINATION + data.cv_address}>{data.cv_address}</a>
                        </div>
                        <CandidDetHelper header="Apply Date" text={formatDateTime(data.created_at)}/>
                    </div>
                </div>
            </div>
            
            <div className="" style={{width:'5%'}}>
                
            </div>
            <div className="" style={{border: '1px solid #E8E9EB', borderRadius:'8px', width:'35%'}}>
              <div style={{marginTop:'32px', marginLeft:'32px'}}>
                  <p style={{color:'#A3AEB7'}} ><StatsUpSquare /> Status </p>
                  <h4 style={{marginTop:'-2px'}}>{statusString}</h4>
              </div>
              {/*Read*/ data.applicant_status_id === 2 && (
                <>
                  <CandButton tooltip="Unfit" mrgnRight="32px" Icon={Prohibition} color="#FF553B" onClick={() => normalAction(6, "UNFIT")}/> 
                  <CandButton tooltip="Process" mrgnRight="12px" Icon={CheckCircle} color="#0296D2" onClick={() => normalAction(3, "PROCESS")}/> 
                </>
              )}
              {/*Process*/ data.applicant_status_id === 3 && (
                <>
                  <CandButton tooltip="Reject" mrgnRight="32px" Icon={Prohibition} color="#FF553B" onClick={() => rejectAction()}/> 
                  <CandButton tooltip="Pending" mrgnRight="12px" Icon={Clock} color="#E9AC43" onClick={() => normalAction(7, "PENDING")}/> 
                  <CandButton tooltip="Accept" mrgnRight="12px" Icon={CheckCircle} color="#0296D2" onClick={() => acceptAction()}/> 
                </>
              )}
              {/*Pending*/ data.applicant_status_id === 7 && (
                <>
                  <CandButton tooltip="Reject" mrgnRight="32px" Icon={Prohibition} color="#FF553B" onClick={() => rejectAction()}/> 
                  <CandButton tooltip="Process" mrgnRight="12px" Icon={CheckCircle} color="#0296D2" onClick={() => normalAction(3, "PROCESS")}/> 
                </>
              )}
              {/*Rejected*/ data.applicant_status_id === 5 && (
                <>
                <div style={{marginLeft:'32px', marginTop:'18px'}}>
                  <CandDetInput readOnly={true} text="Reason" data={data.reject_reason}/>
                </div>
                </>
              )}
              {/*Accepted*/ data.applicant_status_id === 4 && (
                <>
                <div style={{marginLeft:'32px', marginTop:'18px'}}>
                <CandDetInput readOnly={!accEditState} text="Entity" data={entity} setData={setEntity}/>
                <CandDetInput readOnly={!accEditState} text="Role" data={role} setData={setRole}/>
                <DateTimeInput readOnly={!accEditState} text="Join Date & Time" data={joinDatetime} setData={setJoinDatetime} typeString="datetime-local"/>
                <CandDetInput readOnly={!accEditState} text="First Day Location" data={firstDayLoc} setData={setFirstDayLoc}/>
                <CandDetInput readOnly={!accEditState} text="First Day Address" data={firstDayAdr} setData={setFirstDayAdr}/>
                <CandDetInput readOnly={!accEditState} text="PIC Contact Name" data={picContact} setData={setPicContact}/>
                <CandDetInput readOnly={!accEditState} text="PIC Contact Phone" data={dressCode} setData={setDressCode}/>
                {/*edit state*/ accEditState === false && (
                  <button onClick={() => acceptEditState()} className="login-button" style={{marginTop:'16px'}} > Edit</button>  
                )}
                {/*edit state*/ accEditState === true && (
                  <button onClick={() => acceptWithDetail("Edit")} className="login-button" style={{marginTop:'16px'}} > Save</button>  
                )}
                </div>
                </>
              )}
              {statusState === "reject" && (
                <div style={{marginLeft:'32px', marginTop:'18px'}}>
                <CandDetInput text="Reason" data={rejectReason} setData={setRejectReason}/>
                <button onClick={rejectWithDetail} className="login-button" style={{marginTop:'16px'}} > Save</button>  
                </div>
              )}
              {statusState === "accept" && (
                <div style={{marginLeft:'32px', marginTop:'18px'}}>
                <CandDetInput text="Entity" data={entity} setData={setEntity}/>
                <CandDetInput text="Role" data={role} setData={setRole}/>
                <DateTimeInput text="Join Date & Time" data={joinDatetime} setData={setJoinDatetime} typeString="datetime-local" defaultVar={true}/>
                <CandDetInput text="First Day Location" data={firstDayLoc} setData={setFirstDayLoc}/>
                <CandDetInput text="First Day Address" data={firstDayAdr} setData={setFirstDayAdr}/>
                <CandDetInput text="PIC Contact Name" data={picContact} setData={setPicContact}/>
                <CandDetInput text="PIC Contact Phone" data={dressCode} setData={setDressCode}/>
                <button onClick={() => acceptWithDetail("Add")} className="login-button" style={{marginTop:'16px'}} > Save</button>  
                </div>
              )}
              <hr style={{color:'#E8E9EB', width:'104%', marginLeft:'-2%',marginTop:'25px'}}/>
              <div style={{ marginTop: '32px', marginLeft: '32px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between'}}>
                <p style={{ color: '#A3AEB7' }}>
                  <DownloadSquare /> Download Document
                </p>
                <a className="cursorPointer" style={{marginRight:'32px', textDecoration: 'underline' }} onClick={() => downloadDivAsPDF()}> <b>PDF File</b></a>
              </div>
            </div>
          </div>
        </section>
      </main>
     </div>
    </div>
  </div>
  )
}

export default CandidateDetail