// Footer.js
import React from 'react';
import './footer.css';  // Create a CSS file for styling
import linkedinIcon from './linkedin.png'; 
import mailIcon from './mailicon.png'; 
import befalogo from './befalogofooter.png'; 

const FooterPage = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="logo">
                    <img src={befalogo} alt="BeFaLogo" className="befaLogo-icon" />
                </div>
                <div className="social-media" style={{ marginBottom: '20px' }}>
                    <p style={{ marginBottom: '10px' }}><b>Find us on:</b></p> <br/>
                    <a href="https://www.linkedin.com/company/pt-bekasi-fajar-industrial-estate-tbk" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinIcon} alt="LinkedIn" className="linkedin-icon" />
                    </a>
                    {/* Add a mail button */}
                    <a href="mailto:hc@befa.id" target="_blank" rel="noopener noreferrer">
                        <img style={{marginLeft:'10px'}} src={mailIcon} alt="Email" className="linkedin-icon" />  {/* Use the same icon for now */}
                    </a>
                </div>
                <p className="copyright">Copyright © BeFa Career 2024</p>
            </div>
        </footer>
    );
};

export default FooterPage;
