import React from 'react'; import { useState, useEffect} from 'react';
import CareerHeader from '../careerTemplate/CareerHeader'
import FooterPage from '../headerPage/FooterPage'
import {PinAlt, TwitterVerifiedBadge, Clock, LargeSuitcase} from 'iconoir-react'; 
import '../../../scripts/detail.css';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import HeaderIMG from './HeaderIMG';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();

function JobDetail() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [imageLink, setImageLink] = useState("");
  const [readMoreM, setReadMoreM] = useState(false);
  const orientation = useOrientation();
  
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    
    return `${day} ${month} ${year}`;
  }
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobListWithTitle/${id}`;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      console.log(res.data.data);
      if (res.data.status = "200"){
        setData(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })  
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getDataImage();
  }, [])

  const applyJob = () => {
    navigate("/applyForm/"+ id)
  }

  const getDataImage = () => {
    var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobListWithTitle/${id}`;   
    axios.get(path)
    .then(res => {
      console.log(res.data.data);
      if (res.data.status = "200"){
          setImageLink(res.data.data.image_link);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }  

  if (orientation === 'landscape')
  return (
    <div>
        <header className="fixed-header">
          <CareerHeader/> 
        </header>
        <main>
            <HeaderIMG link={imageLink}/>
            <section>
            <div className="card-detail" style={{marginLeft:'-19px'}}>
              <div className="card-body">
                <p className='font-befa' style={{marginTop:'-20px', fontWeight:'700', fontSize:'28px', marginLeft:'50px'}}>{data.title}</p>
                <div style={{display:'flex', alignItems:'center', gap:'10px', marginLeft:'50px'}}>
                  <p className='jd-sub font-befa'><TwitterVerifiedBadge className=''/> {data.division_name}</p>
                  <p className='jd-sub font-befa'><PinAlt className=''/> {data.location_name}</p>
                  <p className='jd-sub font-befa'><LargeSuitcase className=''/> {data.job_type_name}</p>
                  <p className='jd-sub font-befa'><Clock className=''/> {formatDate(data.created_at)}</p>
                  <p className='jd-sub font-befa'></p>
                </div>
                <div className="job-action">
                  <button className="applynowdetail-button font-befa" style={{marginRight:'50px', fontWeight:'600', width:'150px'}} onClick={() => applyJob()}>Apply Now</button>
                </div>
              </div>
            </div>
            <br></br>
            <div class="horizontal-line" ></div>
            <div className="card-detail">
              <div className="jd-container">
                <div className=''>
                  <p className='font-befa' style={{ marginTop:'20px', fontWeight:'700', fontSize:'24px', marginLeft:'50px'}}>Responsibilities</p>
                  <br></br>
                  <div style={{ gap:'10px', marginLeft:'50px'}}>
                  <p
                    className='font-befa'
                    style={{ 
                      marginTop: '-20px', 
                      fontWeight: '400', 
                      fontSize: '16px', 
                      marginLeft: '0px' 
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: `
                        <style>
                          .custom-list {
                            margin-left: 0px; /* Adjust left margin for the entire list */
                          }
                          .custom-list li {
                            margin-bottom: 10px; /* Adjust vertical spacing between list items */
                          }
                        </style>
                        <div class="custom-list">
                          ${data.responsibilities}
                        </div>
                      ` 
                    }}
                  />
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className='font-befa' style={{marginTop:'-20px', fontWeight:'700', fontSize:'24px', marginLeft:'50px'}}>Qualifications</p>
                  <br></br>
                  <div style={{ gap:'10px', marginLeft:'50px'}}>
                  <p
                    className='font-befa'
                    style={{ 
                      marginTop: '-20px', 
                      fontWeight: '400', 
                      fontSize: '16px', 
                      marginLeft: '0px' 
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: `
                        <style>
                          .custom-list {
                            margin-left: 0px; /* Adjust left margin for the entire list */
                          }
                          .custom-list li {
                            margin-bottom: 10px; /* Adjust vertical spacing between list items */
                          }
                        </style>
                        <div class="custom-list">
                          ${data.qualifications}
                        </div>
                      ` 
                    }}
                  />

                  </div>
                </div>
                <div className="" style={{marginTop:'50px', border:'1px solid #E8E9EB', borderRadius:'8px', width:'auto'}}>
                <img className="card-img-top" style={{width:'100%',height:'auto', marginLeft:'0%', marginTop:'-25px', borderRadius:'8px 8px 0px 0px'}} src="/dist/img/aboutus.png" alt="Industrial Buildings"/>
                  <div className="card-content font-befa">
                   
                    <p className="font-befa" style={{fontSize:'14px', fontWeight:'600'}}>About Us</p>
                    <p style={{fontSize:'12px', fontWeight:'400'}}>PT Bekasi Fajar Industrial Estate Tbk. (BeFa) is a leading developer and operator of world-class industrial estates in Indonesia. The Company was established on August 24, 1989 as one of the first industrial estate development and management companies in Indonesia.
                    <br></br>
                    <br></br>
                    Over time, BeFa’s flagship product MM2100 Industrial Town located in Greater Jakarta, established reputation as a hub of cutting edge research, innovation and manufacturing for leading domestic and international companies.
                    <br></br>
                    <br></br>
                    On 10 April 2012, building on its excellent track record for quality and innovation, BeFa went public. It is currently traded on the Indonesia Stock Exchange (IDX: BEST).</p>
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
              </div>
              <FooterPage className="footer" />
            </div>
            </section>
            
        </main>
    </div>
  )

  if (orientation === 'portrait')
    return (
      <div className='container' style={{ maxWidth: '100%', overflowY:'', overflowX:'hidden', height:'100vh'}}>
          <header className="">
          <div className="header-m" style={{marginTop:'-0px'}}>
            <div className="center">
              <h1 className="font-befa">Career at</h1>
              <h1></h1>
              <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
            </div>
          </div>
          </header>
          <main style={{ }}>
            <section className="hero-section">
            <div className="hero-content-job">
                <div class="shadow-box"></div>
                <img src={process.env.REACT_APP_IMG_JOB_DESTINATION + imageLink} alt="Job Career Logo" className="background-img-header-m2" />
            </div>
            </section>
            <div style={{marginTop:'-220px', marginLeft:'-50px', maxWidth:'100vw'}}>
              <div className="card-detail-m">
                <div className="card-body-m">
                  <p className='font-befa' style={{marginTop:'-20px', fontWeight:'700', fontSize:'28px', marginLeft:'50px', textAlign:'left'}}>{data.title}</p>
                  <div style={{display:'', alignItems:'center', gap:'10px', width:'100%', marginTop:'-10px', marginBottom:'-25px', marginLeft:'50px'}}>
                    <p className='jd-sub font-befa'><TwitterVerifiedBadge className=''/> {data.division_name}</p>
                    <p className='jd-sub font-befa'><PinAlt className=''/> {data.location_name}</p>
                    <p className='jd-sub font-befa'><LargeSuitcase className=''/> {data.job_type_name}</p>
                    <p className='jd-sub font-befa'><Clock className=''/> {formatDate(data.created_at)}</p>
                    <p className='jd-sub font-befa'></p>
                  </div>
                  <div className="job-action-m2">
                    <br/>
                    <br/>
                    <button className="btn btn-primary font-befa" style={{width:'100px', height:'36px', backgroundColor:'#0296D2', borderRadius:'8px'}} onClick={() => applyJob()}>Apply Now</button>
                  </div>
                </div>
              </div>
              <br></br>
              <div class="horizontal-line" style={{width:'120vw'}} ></div>
              <div className="card-detail" style={{width:'110vw'}}>
                <div className="" >
                  <div className='' >
                    <p className='font-befa' style={{ marginTop:'20px', fontWeight:'700', fontSize:'24px', marginLeft:'50px'}}>Responsibilities</p>
                    <br></br>
                    <div style={{ gap:'10px', marginLeft:'50px'}}>
                    <p
                    className='font-befa'
                    style={{ 
                      marginTop: '-20px', 
                      fontWeight: '400', 
                      fontSize: '16px', 
                      marginLeft: '0px' 
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: `
                        <style>
                          .custom-list {
                            margin-left: 0px; /* Adjust left margin for the entire list */
                          }
                          .custom-list li {
                            margin-left: -20px;
                            margin-bottom: 10px; /* Adjust vertical spacing between list items */
                          }
                        </style>
                        <div class="custom-list">
                          ${data.responsibilities}
                        </div>
                      ` 
                    }}
                  />
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <p className='font-befa' style={{marginTop:'-20px', fontWeight:'700', fontSize:'24px', marginLeft:'50px'}}>Qualifications</p>
                    <br></br>
                    <div style={{ gap:'10px', marginLeft:'50px'}}>
                    <p
                    className='font-befa'
                    style={{ 
                      marginTop: '-20px', 
                      fontWeight: '400', 
                      fontSize: '16px', 
                      marginLeft: '0px' 
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: `
                        <style>
                          .custom-list {
                            margin-left: 0px; /* Adjust left margin for the entire list */
                          }
                          .custom-list li {
                            margin-left: -20px;
                            margin-bottom: 10px; /* Adjust vertical spacing between list items */
                          }
                        </style>
                        <div class="custom-list">
                          ${data.qualifications}
                        </div>
                      ` 
                    }}
                  />
                    </div>
                  </div>
                  <div className="" style={{margin:'100px 100px 0px 50px', border:'1px solid #E8E9EB', borderRadius:'8px', width:'85vw'}}>
                  <img className="card-img-top" style={{width:'100%',height:'auto', marginLeft:'0%', marginTop:'-25px', borderRadius:'8px 8px 0px 0px'}} src="/dist/img/aboutus.png" alt="Industrial Buildings"/>
                    <div className="card-content" >
                     
                      <p className="font-befa" style={{fontSize:'16px', fontWeight:'600'}}>About Us</p>
                      {readMoreM === false && (
                        <p><a onClick={() => setReadMoreM(true)} style={{fontSize:'15px',color:'#0296D2'}}> <u>Read More</u></a></p>
                      )}
                      {readMoreM === true && (<>
                        <p style={{fontSize:'14px', fontWeight:'400', width:'75vw'}}>PT Bekasi Fajar Industrial Estate Tbk. (BeFa) is a leading developer and operator of world-class industrial estates in Indonesia. The Company was established on August 24, 1989 as one of the first industrial estate development and management companies in Indonesia.
                        <br></br>
                        <br></br>
                        Over time, BeFa’s flagship product MM2100 Industrial Town located in Greater Jakarta, established reputation as a hub of cutting edge research, innovation and manufacturing for leading domestic and international companies.
                        <br></br>
                        <br></br>
                        On 10 April 2012, building on its excellent track record for quality and innovation, BeFa went public. It is currently traded on the Indonesia Stock Exchange (IDX: BEST).</p>
                        <p><a onClick={() => setReadMoreM(false)} style={{fontSize:'15px',color:'#0296D2'}}> <u>Read Less</u></a></p>
                      </>)}
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  
                </div>
                <div style={{width:'110vw'}}>
                <FooterPage className="footer" />
                </div>
              </div>
              </div>
              
          </main>
      </div>
    )
}

export default JobDetail
