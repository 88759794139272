import React from 'react';

function CandDetInput({ text, data, setData, readOnly = false }) {
  return (
    <div style={{ marginBottom: '25px' }}>
      <h5 className="font-befa">{text} <span style={{ color: 'red' }}>*</span></h5>
      <input
        readOnly={readOnly}
        type="text"
        className="hdInput font-befa"
        style={{ padding: '10px', width: '92%' }}
        value={data}
        onChange={(e) => setData(e.target.value)}
      />
    </div>
  );
}

export default CandDetInput;