import React from 'react'; import { useState, useEffect} from 'react';
import TypeOfIssue from './TypeOfIssue';
import axios from 'axios' 
import Cookies from 'universal-cookie';
import { BrowserView, MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function TypeOfIssueExtParent({link, byMeFunction, toMeFunction}) {
  const orientation = useOrientation();
  const [activeButton, setActiveButton] = useState('byMe');
  const handleButtonClick = (buttonType) => {
    if (activeButton === buttonType) {
        //do nothing
    } else {
        setActiveButton(buttonType);
        if (buttonType == 'byMe'){
            byMeFunction();
        } else if (buttonType == 'toMe'){
            toMeFunction();
        }
    }
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [link])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + link;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      
      setData(res.data.data);
      if (res.data.status = "200"){
        console.log(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }  

  if (orientation === 'landscape')
  return (
  <>
  <>
    <div className='borderWithRadius'>
      <div className="row" style={{marginTop:"1.3vw"}}>
        <div className="col-md-9 ">
        <p className='dashboardMarginLeft' style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}><b>Types of Issues Current Month</b></p>
            <div className="button-container">
            <div
                className={`byMeButton ${activeButton === 'byMe' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                onClick={() => handleButtonClick('byMe')}
            >
                <span style={{fontFamily:'Blender Pro'}}>By Me</span>
            </div>
            </div>
        </div>
        <div className="col-md-3" style={{textAlign:"right", paddingRight:'2vw'}}>
            
        </div>
      </div>
      {data.map((item, index) => (
        <TypeOfIssue key={'TOI' + index} maxValue={item.max_issue_count} value={item.issue_count} text={item.kategori_name}/>
      ))}
      <div style={{marginBottom:'0.2vw'}}>&nbsp;</div>
    </div>
  </>
  
  </>
  )
  if (orientation === 'portrait')
  return(
    <>
    <div className='borderWithRadius' style={{}}>
      <div className="row" style={{marginTop:"1.5vh"}}>
        <div className="col-md-12 ">
            <p className='dashboardMarginLeft' style={{fontFamily:'Blender Pro Book', fontSize:'2.5vh', marginLeft:'3vw'}}><b>Types of Issues Current Month</b></p>
            <div className="button-container">
            <div
                className={`byMeButtonMobile ${activeButton === 'byMe' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                onClick={() => handleButtonClick('byMe')}
            >
                <span style={{fontFamily:'Blender Pro'}}>By Me</span>
            </div>
          </div>
          <div style={{textAlign:"right", marginRight:'2vw', marginTop:'-10.5vh', marginBottom:'3vh'}}>
            <a href="/reportsSummary" >View more</a>
          </div>
        </div>
        <div className="col-md-3" >
            
        </div>
      </div>
      <div style={{marginTop:'5vh', marginLeft:'1vw'}}>
        {data.map((item, index) => (
          <TypeOfIssue key={'TOI' + index} maxValue={item.max_issue_count} value={item.issue_count} text={item.kategori_name}/>
        ))}
      </div>
      <div style={{marginBottom:'0.2vw'}}>&nbsp;</div>
    </div>
  </>  
  )
}

export default TypeOfIssueExtParent