import React, { useState, useEffect } from 'react';
import { LogOut, Globe, BellNotification,MenuScale, Cancel } from 'iconoir-react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Cookies from 'universal-cookie';
import SingleNotif from './SingleNotif';
import axios from 'axios' ;
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import useOrientation from '../general/useOrientation';

const cookies = new Cookies();
const Header = () => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [notifShow, setNotifShow] = useState(false);
  const [pathFoto, setPathFoto] = useState("");
  const [notifData, setNotifData] = useState({});
  const [newNotifCount, setNewNotifCount] = useState(0);
  const [audio] = useState(new Audio('/newNotif.mp3'));
  const orientation = useOrientation();

  useEffect(() => {
    getNotifData();

    // Interval for 1 second
    const intervalId1 = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);
  
    // Interval for 60 seconds
    const intervalId2 = setInterval(() => {
      getNotifData();
    }, 60000);
  
    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    };
  }, []);
  
  useEffect(() => {
    getImageData();
  }, []);
  

  const toggleNotif= () => {
    setNotifShow(!notifShow);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/readNotifAll";   
    axios.get( dbpath, config)
      .then(res => { 
        if (res.data.status == "200"){
          //we read it all. its success do nth
        } else {
          //swal2 or sth here
        }
      })
  }

  const deleteNotif= (id) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/deleteNotif/" + id;   
    axios.get( dbpath, config)
      .then(res => { 
        if (res.data.status == "200"){
          getNotifData();
        } else {
          //swal2 or sth here
        }
      })
  }
  
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  function getNotifData() {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/getAllNotif/";   
    axios.get( dbpath, config)
      .then(res => { 
        if (res.data.status == "200"){
          setNotifData(res.data.data);
          setNewNotifCount('0');
          //detect and count new notif
          let count = 0;
          let newNotif = false
          for (var i=0; i< res.data.data.length;i++){
            if (res.data.data[i].read === 0){
              count++;
              newNotif = true;
            }
          }
          setNewNotifCount(count);

          if (newNotif){
            audio.play();
          }
        } else {
          console.log("Error comment");
        }
      })
  }
  
  function getImageData() {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/getPathPhoto";   
    axios.get( dbpath, config)
      .then(res => { 
        if (res.data.status == "200"){
          setPathFoto(res.data.data.path_foto);
        } else {
          console.log("Error get image data");
        }
      })
  }


  const sessionData = cookies.get('sessionData', { path: '/' });
  let display_name = "";
  if (sessionData.int_ext === "1") {
    display_name = sessionData.fullname;
  } else {
    display_name = sessionData.display_name_ext;
  }

  if (orientation === 'landscape')
  return (
    <>
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000, marginLeft: '0vw', height:'4vw' }}>
        <ul className="navbar-nav">
        </ul>

        <ul className="navbar-nav ml-auto">
          

          <li className="nav-item" style={{ marginRight: "2vw" }}>
            <a href="#" className="nav-link" role="button" style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <p className="dashboardText" style={{ position: "absolute", right: "0", fontSize: "1.8vw", color: "#737E86" }}> {currentTime}</p>
            </a>
          </li>
          <li className="nav-item" style={{ marginRight: "2vw" }}>
            <a onClick={toggleNotif} data-tooltip-id="tipNotif" data-tooltip-content="Notification" className="nav-link" role="button" style={{ display: "flex", alignItems: "center", position: "relative" }}>
              {newNotifCount !== 0 ? 
                <img src="/NewNotifications.svg" color="#737E86" style={{ position: "absolute", right: "0", height: "20px" }} /> 
                :
                <BellNotification color="#737E86" style={{ position: "absolute", right: "0", height: "3vw" }} />
              }
              
              <Tooltip id="tipNotif" place="left" type="dark" effect="solid" />
            </a>
          </li>
          {sessionData.int_ext === "1" && (
            <li className="nav-item" style={{ marginRight: "2.3vw" }}>
              <a href="/backToPortal" data-tooltip-id="tipPortal" data-tooltip-content="Back to BeFa portal" className="nav-link" role="button" style={{ display: "flex", alignItems: "center", position: "relative" }}>
                <Globe color="#737E86" style={{ position: "absolute", right: "0", height: "3vw" }} />
                <Tooltip id="tipPortal" place="left" type="dark" effect="solid" />
              </a>
            </li>
          )}
          <li className="nav-item" style={{ marginRight: "2.3vw" }}>
            <a href="/logout" data-tooltip-id="tipLogout" data-tooltip-content="Logout" className="nav-link" role="button" style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <LogOut color="#737E86" style={{ position: "absolute", right: "0", height: "3vw" }} />
              <Tooltip id="tipLogout" place="left" type="dark" effect="solid" />
            </a>
          </li>

          <li className="nav-item" style={{ marginRight: "2vw" }}>
            <a href="/profileEdit" data-tooltip-id="tipProfile" data-tooltip-content={display_name} className="nav-link" role="button" style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <img
                src={process.env.REACT_APP_PUBLIC_DESTINATION + pathFoto}
                alt="Profile Pic"
                className="brand-image img-circle"
                style={{ position: "absolute", right: "0", height: "25px",width: "25px" }}
              />
            </a>
            <Tooltip id="tipProfile" place="left" type="dark" effect="solid" />
          </li>
        </ul>
        {notifShow && (
          <div style={{  boxShadow: '0px 4px 40px 0px #0000001F',backgroundColor: 'white', marginTop: '4vw', position: 'absolute', right: '0', top: '0', zIndex: '1001' ,width:'24vw'}}>
            <div style={{height:'3vw', borderBottom:'1px solid #E8E9EB'}}>
              <p style={{marginLeft:'2vw', marginTop:'1vw',fontSize:'1.5vw', fontWeight:'500', fontFamily:'Blender Pro'}}>Notifications</p>
              <p style={{ marginRight:'1.5vw', marginTop:'-3.3vw',position:'absolute',right:'0', fontSize:'1vw', fontFamily:'Blender Pro', color:'#737E86'}}> {newNotifCount} New Notifications</p>
            </div>
            <div style={{maxHeight:'30vw', overflowY: 'auto'}}>
              {notifData.map((data, index) => (
                data && <SingleNotif key={index} data={data} del={deleteNotif} />
              ))}
            </div>
          </div>
        )}
      </nav>
      <div style={{ marginTop: '5.5vw' }}>
       
      </div>
      </>

    </>
  );

  if (orientation === 'portrait')
  return (
    <>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000, height:'7vh' }}>
      <ul className="navbar-nav ml-auto" style={{ marginTop: "0vh", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <li className="nav-item">
          <div>
            <a data-widget="pushmenu" href="/#" className="nav-link" role="button" style={{ display: "flex", alignItems: "center" }}>
              <MenuScale color="#737E86" style={{ height: "5vh", marginLeft:'-1vw'}} />
            </a>
          </div>
        </li>
        <li className="nav-item">
          <img
            src="/dist/img/logo.png"
            alt="BeFa Logo"
            className=""
            style={{ opacity: '1', height: '7vh', marginTop: '0vh'}}
          />
        </li>
  
        <li className="nav-item" style={{ display: "flex", alignItems: "center", position: "relative" , marginTop:'-2vh'}}>
        <a onClick={toggleNotif} className="nav-link" role="button" style= {{marginRight:'2vw'}}>
            {newNotifCount !== 0 ? 
              <img src="/NewNotifications.svg" color="#737E86" style={{ position: "absolute", right: "0", height: "3vh", marginRight:"1vh", marginTop:'0.5vh' }} /> 
              :
              <BellNotification color="#737E86" style={{ position: "absolute", right: "0", height: "5vh", marginRight:"1vh", marginTop:'-0.5vh' }} />
            }
          </a>
          <a href="/profileEdit" data-tooltip-id="tipProfile" data-tooltip-content={display_name} className="nav-link" role="button" >
            <img
              src={process.env.REACT_APP_PUBLIC_DESTINATION + pathFoto}
              alt="Profile Pic"
              className="brand-image img-circle"
              style={{ position: "absolute", right: "0", height: "4vh", width: "4vh" , marginRight:'1vw'}}
            />
          </a>
        </li>
      </ul>
      {notifShow && (
        <div style={{  boxShadow: '0px 4px 40px 0px #0000001F',backgroundColor: 'white', marginTop: '7vh', position: 'absolute', right: '0', top: '0', zIndex: '1001' ,width:'100vw'}}>
          <div style={{height:'4.5vh', borderBottom:'1px solid #E8E9EB'}}>
            <p style={{marginLeft:'6.5vw', marginTop:'1vh',fontSize:' 2.5vh', fontWeight:'500', fontFamily:'Blender Pro'}}><b>Notifications</b></p>
            <Cancel style={{marginLeft:'90vw', marginTop:'-11vh',fontSize:' 2.5vh', fontWeight:'500', fontFamily:'Blender Pro'}} onClick={toggleNotif} /> 
          </div>
          <div style={{maxHeight:'80vh', overflowY: 'auto', marginLeft:'4.5vw'}}>
            {notifData.map((data, index) => (
              data && <SingleNotif key={index} data={data} del={deleteNotif} mobileClick={toggleNotif} />
            ))}
          </div>
        </div>
      )}
    </nav>
    <div style={{ marginTop: '5.5vw' }}>
     
    </div>
  </>
  )
};

export default Header;
