import React from 'react'
import CandDetInput from '../candidate/CandDetInput'
import useOrientation from '../../general/useOrientation';

function AFRowHelper({txt1, txt2, data1, data2, setData1, setData2, Input1, Input2, checked = false}) {
  const orientation = useOrientation();
  
  if (checked === true)
    return (
      <div className='row font-befa' style={{width: orientation === 'portrait'? '105%':'97.5%'}}>
          <div className='col-sm-6'> 
              <Input1 text={txt1} data={data1} setData={setData1} />
          </div>
          <div className='col-sm-6'> 
              <CandDetInput text={txt2} data="Present" setData={setData2} readOnly={true}/>
          </div>
      </div>
    )
  else 
    return (
      <div className='row font-befa' style={{width: orientation === 'portrait'? '105%':'97.5%'}}>
          <div className='col-sm-6'> 
              <Input1 text={txt1} data={data1} setData={setData1} />
          </div>
          <div className='col-sm-6'> 
              <Input2 text={txt2} data={data2} setData={setData2} />
          </div>
      </div>
    )
}

export default AFRowHelper