import React, { useEffect, useState } from 'react';

function DropdownInput({ text = "Pick Dropdown", data, setDropdown, type, defValue }) {
  const [selectedValue, setSelectedValue] = useState(defValue);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setDropdown(event.target.value);
  };

  const getValue = (item) => {
    type = parseInt(type);
    switch (type) {
      case 1:
        return item.division_name;
      case 2:
        return item.location_name;
      case 3:
        return item.job_type_name;
      default:
        return '';
    }
  };

  useEffect(() => {
    setDefaultValue();
    console.log("defvalue = " + defValue)
  }, [defValue]);

  const setDefaultValue = () => {
    setSelectedValue(defValue);
    setDropdown(defValue);
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <h5 className='font-befa'>{text} <span style={{ color: 'red' }}>*</span></h5>
      <select 
        className="hdInput font-befa" 
        style={{ padding: '10px' }} 
        onChange={handleChange}
        value={selectedValue}
      >
        {data && data.map((item) => (
          <option 
            className="hdInput font-befa" 
            style={{ padding: '10px' }} 
            key={item.id} 
            value={item.id}
          >
            {getValue(item)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropdownInput;
