import React from 'react'
import { useNavigate } from 'react-router-dom'
import {Trash} from 'iconoir-react'
import Cookies from 'universal-cookie';import axios from 'axios' ;
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
const cookies = new Cookies();
function JobListingAdmin({ data }) {
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const navigateCandidate = (e) => {
    e.stopPropagation();
    navigate("/admin/candidateList/"+data.id);
  }

  const closeJob = (e) => {
    e.stopPropagation();
    MySwal.fire({
      title: '<strong>Confirmation</strong>',
      html: `<p>Status Job ${data.title} will be changed to OFF</p>`,
      width:'800px',
      showCancelButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed'
    }).then(result => {
      if (result.isConfirmed) {
        // Handle the "Proceed" button click
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + `/jobChangeActiveOff/`+data.id;
        const config = { headers: { 'x-access-token': sessionToken } };
        axios.get(path, config)
        .then(res => {
            //console.log(res.data.data);
            if (res.data.status == "200") {
              window.location.reload(true);
            } else {
              MySwal.fire({
                title: <strong>Error</strong>,
                html: <p>{res.data.message}</p>
              })
            }
        })
        .catch(err => {
            console.log(err);
        });
      } else {
        MySwal.close()
      }
    })
  }

  const openJob = (e) => {
    e.stopPropagation();

    // Get today's date and the date 30 days from today
    const today = new Date().toISOString().split('T')[0];
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + parseInt(process.env.REACT_APP_RUNNING_DAYS_TO_AUTOCLOSE));
    const thirtyDaysFromToday = futureDate.toISOString().split('T')[0];

    MySwal.fire({
      title: '<strong>Confirmation</strong>',
      html: `
        <p>Status Job ${data.title} will be changed to ON. Check if date needs to be modified.</p>
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" className="swal2-input" value="${today}">
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" className="swal2-input" value="${thirtyDaysFromToday}">
      `,
      width: '800px',
      showCancelButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed',
      preConfirm: () => {
        const startDate = document.getElementById('startDate').value;
        const endDate = document.getElementById('endDate').value;
        if (!startDate || !endDate) {
          Swal.showValidationMessage('Please enter both start and end dates');
        }
        return { startDate, endDate };
      }
    }).then(result => {
      if (result.isConfirmed) {
        const { startDate, endDate } = result.value;
        var sessionToken = cookies.get('sessionToken', { path: '/' });
        var path = process.env.REACT_APP_PATH_DESTINATION + `/jobChangeActiveOn` ;
        var requestData = { start_date: startDate, end_date: endDate, id : data.id };
        const config = { headers: { 'x-access-token': sessionToken } };
        
        axios.post(path, requestData, config)
          .then(res => {
            if (res.data.status == "200") {
              window.location.reload(true);
            } else {
              MySwal.fire({
                title: '<strong>Error</strong>',
                html: `<p>${res.data.message}</p>`
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        MySwal.close();
      }
    });
  }
  
  const deleteJob = (e) => {
    e.stopPropagation();
    MySwal.fire({
      title: '<strong>Confirmation</strong>',
      html: `<p>Job ${data.title} DELETED status will be changed</p>`,
      width:'800px',
      showCancelButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed'
    }).then(result => {
      if (result.isConfirmed) {
        // Handle the "Proceed" button click
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + `/jobDelete/`+data.id;
        const config = { headers: { 'x-access-token': sessionToken } };
        axios.get(path, config)
        .then(res => {
            //console.log(res.data.data);
            if (res.data.status == "200") {
              window.location.reload(true);
            } else {
              MySwal.fire({
                title: <strong>Error</strong>,
                html: <p>{res.data.message}</p>
              })
            }
        })
        .catch(err => {
            console.log(err);
        });
      } else {
        MySwal.close()
      }
    })
  }

  const editJob = ()=>{
    navigate('/admin/jobEdit/' + data.id)
  }

    return (
      <div className="card outerDiv" onClick={() => editJob()}>
        <div className="card-body">
          <p className='font-befa' style={{marginTop:'-20px', fontWeight:'600', fontSize:'16px'}}>{data.title}</p>
          <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
            <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#0296D2', marginRight: '2px', margin:'0'}}>{data.division_name}</p>
            <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#737E86', marginLeft: '2px', margin:'0'}}>{data.location_name}</p>
          </div>
          <div className="job-action">
          <p className="job-type">{data.applicant_count}</p>
          <p className="job-type">{data.job_type_name}</p>
            <p className="job-type"><span onClick={(e) => navigateCandidate(e)} className='cand-link'>Candidate List</span></p>
            {data.active === 1 ? (<>
              <img className="job-type" style={{height:'20px'}}src={process.env.REACT_APP_SELF_PATH_DESTINATION + "ontxt.png"}/> 
            <img onClick={(e) => closeJob(e)} className="cursorPointer" style={{height:'32px'}} src={process.env.REACT_APP_SELF_PATH_DESTINATION + "SwitchOn.png"}></img>
            </>):(<>
              <img className="job-type" style={{height:'20px'}}src={process.env.REACT_APP_SELF_PATH_DESTINATION + "offtxt.png"}/> 
              <img className="cursorPointer" onClick={(e) => openJob(e)} style={{height:'32px'}} src={process.env.REACT_APP_SELF_PATH_DESTINATION + "SwitchOff.png"}></img>
            </>)} 
            <p className="job-type"><Trash className="colorHover" style={{marginLeft:'10px', marginRight:'-20px'}} onClick={(e) => deleteJob(e)}/> </p>
          </div>
        </div>
      </div>
    );
  }

export default JobListingAdmin
