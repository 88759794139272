import React, { useEffect, useState } from 'react'
import CareerHeader from '../careerTemplate/CareerHeader'
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';import axios from 'axios' ;
import CandDetInput from '../candidate/CandDetInput';
import CheckboxInput from './CheckboxInput';
import AFRowHelper from './AFRowHelper';
import DateTimeInput from './DateTimeInput';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import FooterPage from '../headerPage/FooterPage';
import BewareFraud from '../headerPage/BewareFraud';
import HeaderIMG from '../job/HeaderIMG';
import ResponForm from './ResponForm';
import CandDropdown from '../candidate/CandDropdown';
import AutocompleteInput from '../candidate/AutocompleteInput';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies(); //cookies for the answer, dont forget this TODO
function ApplyForm() {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const { jobID } = useParams();
    const [applyStateDone, setApplyStateDone] = useState(false);
    const [jobIDBE, setjobIDBE] = useState("");
    const [curPeriod, setCurPeriod] = useState("");
    const [curPeriodStr, setCurPeriodStr] = useState("");
    const [imageLink, setImageLink] = useState("");
    const [jobPosition, setJobPosition] = useState("");
    const [fullName, setFullName] = useState(cookies.get('fullName') || "");
    const [birthDate, setBirthDate] = useState(cookies.get('birthDate') || "");
    const [address, setAddress] = useState(cookies.get('address') || "");
    const [email, setEmail] = useState(cookies.get('email') || "");
    const [phoneNum, setPhoneNum] = useState(cookies.get('phoneNum') || "");
    const [latestEdu, setLatestEdu] = useState(cookies.get('latestEdu') || "");
    const [major, setMajor] = useState(cookies.get('major') || "");
    const [reqgpa, setReqGPA] = useState(false);
    const [gpa, setGPA] = useState(cookies.get('gpa') || "");
    const [institution, setInstitution] = useState(cookies.get('institution') || "");
    const [enrollYear, setEnrollYear] = useState(cookies.get('enrollYear') || "");
    const [gradYear, setGradYear] = useState(cookies.get('gradYear') || "");
    const [howFindJob, setHowFindJob] = useState(cookies.get('howFindJob') || "LinkedIn");
    const [photo, setPhoto] = useState([]);
    const [cv, setCv] = useState([]);
    const orientation = useOrientation();
    const [confirmation, setConfirmation] = useState(cookies.get('confirmation') === 'true');
    const [expData, setExpData] = useState(() => {
        const savedExpData = cookies.get('expData');
        try {
          return savedExpData ? savedExpData : [{
            company_name: '',
            company_sector: '',
            start_date: '',
            end_date: '',
            position_division: '',
            job_desc: '',
            quit_reason: '',
            notice_period: '',
            still_work_here: false,
          }];
        } catch (error) {
          console.error("Error savedExpData:", error);
          return [{
            company_name: '',
            company_sector: '',
            start_date: '',
            end_date: '',
            position_division: '',
            job_desc: '',
            quit_reason: '',
            notice_period: '',
            still_work_here: false,
          }];
        }
      });
      
    const pValHowFindJob = ['LinkedIn', 'Glints', 'Job Street', 'Job Fair', 'Referral from a friend or colleague', 'Others'];
    const pValLatestEdu = ['SMA - Senior High School', 'D1 - Diploma 1', 'D2 - Diploma 2', 'D3 - Diploma 3', 'D4 - Diploma 4', "S1 - Bachelor's Degree ", "S2 - Master's Degree", "S3 - Doctorate"];
    const pValEduMajor = [
      'Administrasi Asuransi dan Aktuaria',
      'Administrasi Bisnis',
      'Administrasi Bisnis/Tata Niaga',
      'Administrasi Keuangan dan Perbankan',
      'Administrasi Pemerintahan',
      'Administrasi Pendidikan',
      'Administrasi Perkantoran dan Sekretari',
      'Administrasi Perpajakan',
      'Administrasi Perumahsakitan',
      'Administrasi Publik',
      'Agribisnis',
      'Agriekoteknologi',
      'Agrobisnis Perikanan',
      'Agronomi',
      'Aktuaria',
      'Akuakultur',
      'Akuntansi',
      'Akuntansi perpajakan',
      'Akuntansi sektor publik',
      'Antropologi Sosial/Antropologi Budaya',
      'Apoteker',
      'Arkeologi',
      'Arsitektur',
      'Arsitektur Interior',
      'Astronomi',
      'Bahasa dan Budaya Tiongkok',
      'Bahasa dan Kebudayaan Korea',
      'Batik dan Fashion',
      'Bimbingan dan Konseling',
      'Biologi',
      'Bioteknologi',
      'Bisnis Digital',
      'Bisnis Internasional',
      'Bisnis Islam',
      'Desain dan Komunikasi Visual',
      'Desain Grafis',
      'Desain Interior',
      'Desain Produk',
      'Ekonomi Pembangunan',
      'Farmasi/Ilmu Farmasi',
      'Film dan Animasi',
      'Film dan Televisi',
      'Fisika',
      'Fisioterapi',
      'Geofisika',
      'Geografi',
      'Geologi',
      'Gizi',
      'Hubungan Internasional',
      'Hubungan Masyarakat',
      'Ilmu Administrasi Fiskal',
      'Ilmu Administrasi Negara',
      'Ilmu Administrasi Niaga',
      'Ilmu Ekonomi',
      'Ilmu Ekonomi Islam',
      'Ilmu Filsafat',
      'Ilmu Hukum',
      'Ilmu Kedokteran',
      'Ilmu Kelautan',
      'Ilmu Keolahragaan',
      'Ilmu Keperawatan',
      'Ilmu Kesejahteraan Sosial',
      'Ilmu Komputer',
      'Ilmu Komunikasi',
      'Ilmu Pemerintahan',
      'Ilmu Perikanan/Teknologi Perikanan',
      'Ilmu Perpustakaan',
      'Ilmu Politik',
      'Jurnalistik',
      'Jurusan Pendidikan, Bahasa, dan Seni',
      'Kearsipan Digital',
      'Kebidanan',
      'Kedokteran Gigi',
      'Kedokteran Hewan',
      'Kehutanan',
      'Kesehatan Lingkungan',
      'Kesehatan Masyarakat',
      'Keselamatan dan Kesehatan Kerja',
      'Keuangan dan Perbankan',
      'Kewirausahaan',
      'Kimia',
      'Kriminologi',
      'Manajemen',
      'Manajemen Informasi dan Dokumentasi',
      'Manajemen Pemasaran Pariwisata',
      'Manajemen Pendidikan',
      'Manajemen Perhotelan',
      'Matematika',
      'Meteorologi',
      'Musik',
      'Okupasi Terapi',
      'Pariwisata',
      'Pemasaran digital',
      'Pendidikan Agama Islam',
      'Pendidikan Bahasa Arab',
      'Pendidikan Bahasa Daerah',
      'Pendidikan Bahasa Indonesia',
      'Pendidikan Bahasa Inggris',
      'Pendidikan Bahasa Jepang',
      'Pendidikan Bahasa Jerman',
      'Pendidikan Bahasa Korea',
      'Pendidikan Bahasa Prancis',
      'Pendidikan Biologi',
      'Pendidikan Dokter',
      'Pendidikan Fisika',
      'Pendidikan Geografi',
      'Pendidikan Guru Anak Usia Dini (PAUD)',
      'Pendidikan Guru Sekolah Dasar (PGSD)',
      'Pendidikan Ilmu Komputer',
      'Pendidikan IPA',
      'Pendidikan IPS',
      'Pendidikan Jasmani, Kesehatan, dan Rekreasi',
      'Pendidikan Kepelatihan Olahraga',
      'Pendidikan Khusus',
      'Pendidikan Kimia',
      'Pendidikan Luar Biasa',
      'Pendidikan Luar Sekolah (PLS)',
      'Pendidikan Masyarakat',
      'Pendidikan Matematika',
      'Pendidikan Pancasila dan Kewarganegaraan',
      'Pendidikan Sejarah',
      'Pendidikan Seni Musik',
      'Pendidikan Seni Rupa',
      'Pendidikan Seni Tari',
      'Pendidikan Sosiologi',
      'Pendidikan Teknik Otomotif',
      'Pendidikan Teknologi Agroindustri',
      'Penyiaran Multimedia',
      'Perencanaan Wilayah dan Kota',
      'Perhotelan',
      'Periklanan Kreatif',
      'Perpustakaan & Sains Informasi',
      'Pertanian dan Agribisnis',
      'Peternakan',
      'Psikologi',
      'Psikologi Pendidikan dan Bimbingan',
      'Rekayasa Keselamatan Kebakaran',
      'Sastra Arab',
      'Sastra Belanda',
      'Sastra Cina',
      'Sastra Indonesia',
      'Sastra Inggris',
      'Sastra Jawa/Sunda/Daerah',
      'Sastra Jepang',
      'Sastra Jerman',
      'Sastra Prancis',
      'Sastra Rusia/Slavia',
      'Sejarah',
      'Sejarah dan Kebudayaan Islam',
      'Sekretari',
      'Seni Kriya',
      'Seni Musik',
      'Seni Rupa Murni',
      'Seni Tari',
      'Sistem Informasi',
      'Sosiologi',
      'Statistika',
      'Studi Agama',
      'Tata Boga',
      'Tata Busana',
      'Tata Kelola Seni',
      'Tata Rias',
      'Teknik Bangunan',
      'Teknik Biomedis',
      'Teknik Dirgantara/Penerbangan',
      'Teknik Elektro',
      'Teknik Geodesi dan Geomatika',
      'Teknik Geofesi',
      'Teknik Geologi',
      'Teknik Industri',
      'Teknik Kimia',
      'Teknik Komputer',
      'Teknik Lingkungan',
      'Teknik Listrik',
      'Teknik Material',
      'Teknik Mesin',
      'Teknik Metalurgi',
      'Teknik Nuklir',
      'Teknik Pengairan',
      'Teknik Perkapalan',
      'Teknik Perminyakan',
      'Teknik Pertambangan',
      'Teknik Pertanian',
      'Teknik Sipil',
      'Teknologi Bioproses',
      'Teknologi Industri Pertanian',
      'Teknologi Informasi',
      'Teknologi Pangan',
      'Teknologi Pendidikan',
      'Teologi',
      'Vokasional Pariwisata'
  ];
  
    const handlePhotoChange = (e) => {
      setPhoto(e.target.files[0]);
    };
  
    const handleCvChange = (e) => {
      setCv(e.target.files[0]);
    };
  
    const handleCheckboxChange = (e) => {
      setConfirmation(e.target.checked);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
      getData();
      console.log("expdata logging")
      console.log(cookies.get('expData'))
    }, [])

    const addExperience = () => {
        if (expData.length < 3) {
          const newExperience = {
            company_name: '',
            company_sector: '',
            start_date: '',
            end_date: '',
            position_division: '',
            job_desc: '',
            quit_reason: '',
            notice_period: '',
            still_work_here: false,
          };
          const updatedExpData = [...expData, newExperience];
          setExpData(updatedExpData);
          cookies.set('expData', JSON.stringify(updatedExpData), { path: '/' });
        }
    };

    const updateExpData = (index, field, value) => {
        const updatedExpData = expData.map((exp, i) =>
          i === index ? { ...exp, [field]: value } : exp
        );
        setExpData(updatedExpData);
        cookies.set('expData', JSON.stringify(updatedExpData), { path: '/' });
      };

    const removeExperience = (index) => {
      if (expData.length > 1) {
          const updatedExpData = expData.filter((_, i) => i !== index);
          setExpData(updatedExpData);
          cookies.set('expData', JSON.stringify(updatedExpData), { path: '/' });
      }
    };

    const getData = () => {
      var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobListWithTitle/${jobID}`;   
      axios.get(path)
      .then(res => {
        console.log(res.data.data);
        if (res.data.status == "200"){
            setImageLink(res.data.data.image_link);
            setjobIDBE(res.data.data.id);
            setCurPeriod(res.data.data.current_period);
            setCurPeriodStr(res.data.data.current_period_str);
            if (res.data.data.require_gpa === 1) {
                setReqGPA(true); 
            } else {
                setReqGPA(false);
            }
            setJobPosition(res.data.data.title)
        }
      })
      .catch(err => {
        console.log(err);
      })
    }  

    const handleInputChange = (setter, value, key) => {
        setter(value);
        cookies.set(key, value, { path: '/' });
    }
    const submitData = () => {
      const missingFields = [];
  
      // Check individual fields
      if (!jobID) missingFields.push('Job ID');
      if (!imageLink) missingFields.push('Image Link');
      if (!jobPosition) missingFields.push('Job Position');
      if (!fullName) missingFields.push('Full Name');
      if (!birthDate) missingFields.push('Birth Date');
      if (!address) missingFields.push('Address');
      if (!email) missingFields.push('Email');
      if (!phoneNum) missingFields.push('Phone Number');
      if (!latestEdu) missingFields.push('Latest Education');
      if (!major) missingFields.push('Major');
      if (!institution) missingFields.push('Institution');
      if (!enrollYear) missingFields.push('Enroll Year');
      if (!gradYear) missingFields.push('Graduation Year');
      if (!howFindJob) missingFields.push('How You Found the Job');
      if (!photo) missingFields.push('Photo');
      if (!cv) missingFields.push('CV');
      if (reqgpa === true && !gpa) missingFields.push('GPA');
  
      // Check experience data
      expData.forEach((exp, index) => {
          if (!exp.company_name) missingFields.push(`Experience ${index + 1} - Company Name`);
          if (!exp.company_sector) missingFields.push(`Experience ${index + 1} - Company Sector`);
          if (!exp.start_date) missingFields.push(`Experience ${index + 1} - Start Date`);
          if (!exp.end_date && !exp.still_work_here) missingFields.push(`Experience ${index + 1} - End Date or Still Working`);
          if (!exp.position_division) missingFields.push(`Experience ${index + 1} - Position Division`);
          if (!exp.job_desc) missingFields.push(`Experience ${index + 1} - Job Description`);
          if (!exp.quit_reason) missingFields.push(`Experience ${index + 1} - Quit Reason`);
          if (!exp.notice_period) missingFields.push(`Experience ${index + 1} - Notice Period`);
      });
  
      if (missingFields.length > 0) {
          MySwal.fire({
              title: <strong>Fill all fields</strong>,
              html: <p>Please fill all the required fields:<br />{missingFields.join(', ')}</p>
          });
          return;
      }
  
      if (confirmation === false) {
          MySwal.fire({
              title: <strong>Confirmation</strong>,
              html: <p>Please confirm that your data is true</p>
          });
          return;
      }
  
      MySwal.fire({
          title: 'Processing Your Request...',
          text: 'Please wait while we process your request.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
              MySwal.showLoading();
          }
      });
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('jobID', jobIDBE);
      formData.append('imageLink', imageLink);
      formData.append('jobPosition', jobPosition);
      formData.append('fullName', fullName);
      formData.append('birthDate', birthDate);
      formData.append('address', address);
      formData.append('email', email);
      formData.append('phoneNum', phoneNum);
      formData.append('latestEdu', latestEdu);
      formData.append('major', major);
      formData.append('institution', institution);
      formData.append('enrollYear', enrollYear);
      formData.append('gradYear', gradYear);
      formData.append('howFindJob', howFindJob);
      formData.append('photo', photo);
      formData.append('cv', cv);
      formData.append('gpa', gpa);
      formData.append('curPeriod', curPeriod);
      formData.append('curPeriodStr', curPeriodStr);
  
      expData.forEach((item, index) => {
          formData.append(`expData${index}`, JSON.stringify(item));
      });
      var path = process.env.REACT_APP_PATH_DESTINATION + `/SubmitApplication`;
      axios.post(path, formData)
          .then(res => {
              if (res.data.status == "200") {
                  //navigate(`/responForm/${jobID}`)
                  MySwal.close();
                  setApplyStateDone(true);
              } else {
                  MySwal.fire({
                      title: <strong>Error</strong>,
                      html: <p>{res.data.message}. Please contact us through the email on the bottom page.</p>
                  });
                  console.log(res.data);
              }
          })
          .catch(err => {
              console.log(err);
          });
  }
  

  if (applyStateDone) 
    return <ResponForm jobID={jobID}/>
  else if (orientation === 'landscape')
  return (
  <div className="font-befa" style={{maxHeight: '100%', maxWidth:'100vw' }} >
    <div className="">
        <header className="fixed-header">
          <CareerHeader/> 
        </header>
        <div className="center">
          <HeaderIMG link={imageLink}/>
          <div className="center" style={{ alignItems: 'flex-start' }} >
            <div className="center" 
                style={{
                    backgroundColor: '#FFFFFF', 
                    backgroundImage: 'url(/CandidateDetailBG.png)', 
                    backgroundPosition: 'top right', 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 150px',
                    width:'70%',
                    marginLeft:'15%',
                    border: '1px solid #E8E9EB',
                    borderRadius:'8px'
                }}
            >   
                <div className="font-befa" style={{marginTop:'48px', marginLeft:'80px'}}>
                    <div style={{textAlign:'center'}}>
                        <br/>
                        <h1 className="font-befa"> Application Form  </h1>
                        <p className="font-befa"> Please complete the form with accurate information  </p>
                        <br/><br/>
                    </div>
                    <div style={{paddingBottom:'20px'}}>
                        <CandDetInput readOnly={true} text="Job Position" data={jobPosition} setData={setJobPosition}/>
                        <CandDetInput text="Full Name" data={fullName} setData={(value) => handleInputChange(setFullName, value, 'fullName')}/>
                        <DateTimeInput text="Birth Date" data={birthDate} setData={(value) => handleInputChange(setBirthDate, value, 'birthDate')}/>
                        <CandDetInput text="Full Domicile Address" data={address} setData={(value) => handleInputChange(setAddress, value, 'address')}/>
                        <CandDetInput text="Email Address" data={email} setData={(value) => handleInputChange(setEmail, value, 'email')}/>
                        <CandDetInput text="Phone Number" data={phoneNum} setData={(value) => handleInputChange(setPhoneNum, value, 'phoneNum')}/>
                        <CandDropdown presetValues={pValLatestEdu} text="Latest Education Level" data={latestEdu} setData={(value) => handleInputChange(setLatestEdu, value, 'latestEdu')}/>
                        <AutocompleteInput presetValues={pValEduMajor} text="Education Major" data={major} setData={(value) => handleInputChange(setMajor, value, 'major')}/>
                        <CandDetInput text="Institution" data={institution} setData={(value) => handleInputChange(setInstitution, value, 'institution')}/> 
                        <AFRowHelper txt1="Enrollment Year" txt2="Graduation Year"  
                        data1={enrollYear} data2={gradYear} setData1={(value) => handleInputChange(setEnrollYear, value, 'enrollYear')} setData2={(value) => handleInputChange(setGradYear, value, 'gradYear')} 
                        Input1={CandDetInput} Input2={CandDetInput} />
                        
                        {reqgpa && (
                            <CandDetInput text="GPA" data={gpa} setData={(value) => handleInputChange(setGPA, value, 'gpa')}/>
                        )} 

                        {expData.map((exp, index) => (
                            <div key={index}>
                                {index === expData.length - 1 && expData.length < 3 ? (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                                    <h5 className='font-befa'>Experience {index + 1}</h5>
                                    <a className="cursorPointer font-befa" style={{marginRight:'8%', color:'#0296D2'}} onClick={addExperience} >
                                        Add More
                                    </a>
                                </div>
                                ) : (<>
                                    <h5 className='font-befa' style={{ marginTop: '20px' }}>Experience {index + 1}</h5>
                                </>)}

                                {expData.length > 1 && index !== 0 && (
                                  <div style={{ textAlign: 'right', marginRight: '8%' }}>
                                    <a className="cursorPointer font-befa" style={{ color: 'red' }} onClick={() => removeExperience(index)}>
                                      Remove
                                    </a>
                                  </div>
                                )}
                      
                                <AFRowHelper txt1="Company Name" txt2="Company Sector" 
                                data1={exp.company_name} data2={exp.company_sector} 
                                setData1={(value) => updateExpData(index, 'company_name', value)} 
                                setData2={(value) => updateExpData(index, 'company_sector', value)} 
                                Input1={CandDetInput} Input2={CandDetInput} />

                                <AFRowHelper txt1="Start Date" txt2="End date" 
                                data1={exp.start_date} data2={exp.end_date} 
                                setData1={(value) => updateExpData(index, 'start_date', value)} 
                                setData2={(value) => updateExpData(index, 'end_date', value)} 
                                Input1={DateTimeInput} Input2={DateTimeInput} checked={exp.still_work_here} />
                                
                                <div className='row' style={{width:'97.5%'}}>
                                    <div className='col-sm-6'> 
                                        <></>
                                    </div>
                                    <div className='col-sm-6'> 
                                    <CheckboxInput
                                        text="Still Work Here"
                                        checked={exp.still_work_here}
                                        setChecked={(value) => updateExpData(index, 'still_work_here', value)}
                                    />
                                    </div>
                                </div>
                                
                                <CandDetInput text="Position Division" data={exp.position_division} setData={(value) => updateExpData(index, 'position_division', value)} />
                                <CandDetInput text="Job Description" data={exp.job_desc} setData={(value) => updateExpData(index, 'job_desc', value)} />

                                <AFRowHelper txt1="Reason of Resignation" txt2="Notice Period" 
                                data1={exp.quit_reason} data2={exp.notice_period} 
                                setData1={(value) => updateExpData(index, 'quit_reason', value)} 
                                setData2={(value) => updateExpData(index, 'notice_period', value)} 
                                Input1={CandDetInput} Input2={CandDetInput} />
                                
                            </div>
                        ))}
                        <CandDropdown presetValues={pValHowFindJob} text="How did you find this job opening information" data={howFindJob} setData={setHowFindJob}/>
                        <div className="form-group" >
                            <h5 className='font-befa'>Photo <span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo">Choose File </label> 
                            <br/> {photo && (<p> Chosen: {photo.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo" accept=".jpeg, .png, .jpg" onChange={handlePhotoChange} />
                        </div>
                        <div className="form-group" >
                            <h5 className='font-befa'>CV <span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="cv">Choose File </label> 
                            <br/> {cv && (<p> Chosen: {cv.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: PDF <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="cv" accept=".pdf" onChange={handleCvChange} />
                        </div>
                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" id="confirmation" checked={confirmation} style={{width: '20px', height: '20px', gap: '0px', borderRadius: '6px 0px 0px 0px', border: '1px 0px 0px 0px', opacity: '0px', }} onChange={handleCheckboxChange} />
                            <div style={{marginTop:'8px'}}><label htmlFor="confirmation" style={{marginLeft:'5px'}}> I confirm the information entered is accurate</label></div>
                        </div>
                    </div>
                    <button className="submit-button font-befa" onClick={submitData} style={{marginBottom:'40px'}}> Submit </button>
                </div>
            </div>
            <br/><br/>
          </div>
        </div>
    </div>
    <BewareFraud />
    <FooterPage />
  </div>
  )
  else if (orientation === 'portrait')
    return (
      <div className='container font-befa' style={{ maxWidth: '100%', overflowY:'', overflowX:'hidden', height:'100vh'}}>
      <div className="">
          <header className="">
          <div className="header-m" style={{marginTop:'-0px'}}>
            <div className="center">
              <h1 className="font-befa">Career at</h1>
              <h1></h1>
              <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
            </div>
          </div>
          </header>
          <div className="center">
            <section className="hero-section">
            <div className="hero-content-job">
                <div class="shadow-box"></div>
                <img src={process.env.REACT_APP_IMG_JOB_DESTINATION + imageLink} alt="Job Career Logo" className="background-img-header-m2" />
            </div>
            </section>
            <br/>
            <br/><br/>
            <div className="center" style={{ alignItems: 'flex-start' }} >
              <div className="center" 
                  style={{
                      backgroundColor: '#FFFFFF', 
                      backgroundImage: 'url(/CandidateDetailBG.png)', 
                      backgroundPosition: 'top right', 
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto 150px',
                      width:'96%',
                      marginLeft:'2%',
                      border: '1px solid #E8E9EB',
                      marginTop:'-250px',
                      borderRadius:'8px'
                  }}
              >   
                  <div className="font-befa" style={{marginTop:'20px', marginLeft:'2%', width:'96%' }}>
                      <div style={{textAlign:'center', marginLeft:'3%', width:'94%'  }}>
                          <br/>
                          <h2 className="font-befa"> Application Form  </h2>
                          <p className="font-befa"> Please complete the form with accurate information  </p>
                          <br/><br/>
                      </div>
                      <div style={{paddingBottom:'20px', marginLeft:'7%', marginTop:'-15px'}}>
                          <CandDetInput readOnly={true} text="Job Position" data={jobPosition} setData={setJobPosition}/>
                          <CandDetInput text="Full Name" data={fullName} setData={(value) => handleInputChange(setFullName, value, 'fullName')}/>
                          <DateTimeInput text="Birth Date" data={birthDate} setData={(value) => handleInputChange(setBirthDate, value, 'birthDate')}/>
                          <CandDetInput text="Full Domicile Address" data={address} setData={(value) => handleInputChange(setAddress, value, 'address')}/>
                          <CandDetInput text="Email Address" data={email} setData={(value) => handleInputChange(setEmail, value, 'email')}/>
                          <CandDetInput text="Phone Number" data={phoneNum} setData={(value) => handleInputChange(setPhoneNum, value, 'phoneNum')}/>
                          <CandDropdown presetValues={pValLatestEdu} text="Latest Education Level" data={latestEdu} setData={(value) => handleInputChange(setLatestEdu, value, 'latestEdu')}/>
                          <AutocompleteInput presetValues={pValEduMajor} text="Education Major" data={major} setData={(value) => handleInputChange(setMajor, value, 'major')}/>
                          <CandDetInput text="Institution" data={institution} setData={(value) => handleInputChange(setInstitution, value, 'institution')}/> 
                          <AFRowHelper txt1="Enrollment Year" txt2="Graduation Year"  
                          data1={enrollYear} data2={gradYear} setData1={(value) => handleInputChange(setEnrollYear, value, 'enrollYear')} setData2={(value) => handleInputChange(setGradYear, value, 'gradYear')} 
                          Input1={CandDetInput} Input2={CandDetInput} />
                          
                          {reqgpa && (
                              <CandDetInput text="GPA" data={gpa} setData={(value) => handleInputChange(setGPA, value, 'gpa')}/>
                          )} 
  
                          {expData.map((exp, index) => (
                              <div key={index}>
                                  {index === expData.length - 1 && expData.length < 3 ? (
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                                      <h5 className='font-befa'>Experience {index + 1}</h5>
                                      <a className="cursorPointer font-befa" style={{marginRight:'8%', color:'#0296D2'}} onClick={addExperience} >
                                          Add More
                                      </a>
                                  </div>
                                  ) : (<>
                                      <h5 className='font-befa' style={{ marginTop: '25px' }}>Experience {index + 1}</h5>
                                  </>)}
  
                                  {expData.length > 1 && index !== 0 && (
                                    <div style={{ textAlign: 'right', marginRight: '8%' }}>
                                      <a className="cursorPointer font-befa" style={{ color: 'red' }} onClick={() => removeExperience(index)}>
                                        Remove
                                      </a>
                                    </div>
                                  )}
                        
                                  <AFRowHelper txt1="Company Name" txt2="Company Sector" 
                                  data1={exp.company_name} data2={exp.company_sector} 
                                  setData1={(value) => updateExpData(index, 'company_name', value)} 
                                  setData2={(value) => updateExpData(index, 'company_sector', value)} 
                                  Input1={CandDetInput} Input2={CandDetInput} />
  
                                  <AFRowHelper txt1="Start Date" txt2="End date" 
                                  data1={exp.start_date} data2={exp.end_date} 
                                  setData1={(value) => updateExpData(index, 'start_date', value)} 
                                  setData2={(value) => updateExpData(index, 'end_date', value)} 
                                  Input1={DateTimeInput} Input2={DateTimeInput} checked={exp.still_work_here} />
                                  
                                  <div className='row' style={{width:'97.5%'}}>
                                      <div className='col-sm-6'> 
                                          <></>
                                      </div>
                                      <div className='col-sm-6'> 
                                      <CheckboxInput
                                          text="Still Work Here"
                                          checked={exp.still_work_here}
                                          setChecked={(value) => updateExpData(index, 'still_work_here', value)}
                                      />
                                      </div>
                                  </div>
                                  
                                  <CandDetInput text="Position Division" data={exp.position_division} setData={(value) => updateExpData(index, 'position_division', value)} />
                                  <CandDetInput text="Job Description" data={exp.job_desc} setData={(value) => updateExpData(index, 'job_desc', value)} />
  
                                  <AFRowHelper txt1="Reason of Resignation" txt2="Notice Period" 
                                  data1={exp.quit_reason} data2={exp.notice_period} 
                                  setData1={(value) => updateExpData(index, 'quit_reason', value)} 
                                  setData2={(value) => updateExpData(index, 'notice_period', value)} 
                                  Input1={CandDetInput} Input2={CandDetInput} />
                                  
                              </div>
                          ))}
                          <CandDropdown presetValues={pValHowFindJob} text="How did you find this job opening information" data={howFindJob} setData={setHowFindJob}/>
                          <div className="form-group" >
                              <h5 className='font-befa'>Photo <span style={{ color: 'red' }}>*</span></h5>
                              <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo">Choose File </label> 
                              <br/> {photo && (<p> Chosen: {photo.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                              <input style={{visibility:"hidden"}} type="file" id="photo" accept=".jpeg, .png, .jpg" onChange={handlePhotoChange} />
                          </div>
                          <div className="form-group" >
                              <h5 className='font-befa'>CV <span style={{ color: 'red' }}>*</span></h5>
                              <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="cv">Choose File </label> 
                              <br/> {cv && (<p> Chosen: {cv.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: PDF <br/> Max Size: 5 MB</small>
                              <input style={{visibility:"hidden"}} type="file" id="cv" accept=".pdf" onChange={handleCvChange} />
                          </div>
                          <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                              <input type="checkbox" id="confirmation" checked={confirmation} style={{width: '20px', height: '20px', gap: '0px', borderRadius: '6px 0px 0px 0px', border: '1px 0px 0px 0px', opacity: '0px', }} onChange={handleCheckboxChange} />
                              <div style={{marginTop:'8px', fontSize:'12px'}}><label htmlFor="confirmation" style={{marginLeft:'5px'}}> I confirm the information entered is accurate</label></div>
                          </div>
                      </div>
                      <button className="submit-button" onClick={submitData} style={{marginBottom:'40px', marginLeft:'5%'}}> Submit </button>
                  </div>
              </div>
              <br/><br/>
            </div>
          </div>
      </div>
      <BewareFraud />
      <div style={{width:'104vw', marginLeft:'-2vw'}}>
      <FooterPage className="footer" />
      </div>
    </div>
    )
}

export default ApplyForm