import React, { useState,  } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { WarningCircle } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import CareerHeader from '../careerTemplate/CareerHeader';

function NewPass() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const backToLogin = (txt) => {
    navigate('/login');
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    setErrorMsg("");
    // Check if passwords match
    if (password !== repeatPassword) {
        setErrorMsg("Passwords don't match");
        return;
    }

    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/setNewPass`, { newPass: password, token: token })
    .then(function (res) {
      console.log(res.data);
      setErrorMsg(res.data.message);
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <div className="">
    <div className="" style={{maxHeight: '100%', maxWidth:'100vw' }} >
      <div className="font-befa" >
        <CareerHeader />
        <div className="hold-transition login-page " style={{
          backgroundImage: "url(/dist/img/bg-login.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw", // Ensure the width is also full screen
        }}>
          
            <div className="login-box login-container">
              <div className="" >
                <div className="card-body login-card-body" style={{marginBottom: "-30px"}}>
                <div className="login-logo" style={{textAlign: 'left' ,marginLeft: '-10%',width:'120%'}}>
                  <p style={{color:'#404041', marginBottom:'2vw', fontSize:'',fontWeight:'500'}}><b>Reset Password </b></p>
                </div>
                  <form style={{width:'120%',marginLeft: '-10%'}} onSubmit={handleSubmit}>
                  <div className="bottomPad textAlign" ><b>New Password</b> <span style={{ color: 'red' }}> *</span> <br/></div>
                    <div className="input-group mb-3">
                      <input style={{width:'100px !important' }} type="password" className="form-control hdInput" placeholder="Input New Password" name="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>

                    <div className="bottomPad" ></div>
                    <div className="bottomPad textAlign" ><b>Confirm New Password</b><span style={{ color: 'red' }}> *</span> <br/></div>
                    <div className="input-group mb-3">
                      <input type="password" className="form-control hdInput" placeholder="Confirm Password" name="repeatpass" autoComplete="off" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}/>
                    </div>
                    
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      {errorMsg.length > 0 && (
                        <div className="input-group mb-3 font-befa">
                          <a className="form-control hdErrorInput">
                            <WarningCircle width="16px" color="red" /> {errorMsg} 
                          </a>
                        </div>
                      )}
                    </div>

                    <br/>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary font-befa"
                          style={{
                            borderRadius: "8px",
                            marginTop: "-10px",
                            backgroundColor: "#0296D2",
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start", // Align content to the left
                            height: "inherit",
                            padding: "0.5rem 1rem" // Adjust padding as needed
                          }}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                    <br/>
                  </form>
                  
                </div>
                <hr style={{width:"116%", marginLeft:"-8%"}}/>
                <div style={{ textAlign: 'left', width: '100%' }}>
                    <a className="cursorPointer" onClick={backToLogin} >Back To Login</a>
                </div>
              </div>
              <div id="requirement" />
            </div>
          </div>
        </div>
    </div>
  </div>
  )

}

export default NewPass;
