import React, { useState , useEffect } from 'react';
import '../../../scripts/custom.css';
import { WarningCircle } from 'iconoir-react';
import useOrientation from '../../general/useOrientation';
function ResetComp({isLoading, changeScreen, handleSubmit, forgotpassEmail, setForgotPassEmail, login, errorMsg}) {
  // Add state variables to track form submission and email field
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const orientation = useOrientation();
  useEffect(() => {
    setSubmittedEmail(true);
  }, [])
  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (forgotpassEmail.length == 0) {
      setSubmittedEmail(false);
      return;
    } else {
      setSubmittedEmail(true);
    }
    handleSubmit(e)
  };

  return (
    <div className="">
    <div className="" style={{maxHeight: '100%', maxWidth:'100vw' }} >
      <div className="">
        <div className="header">
          <div className="center">
            <h1>Career at</h1>
            <h1></h1>
            <img src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
          </div>
        </div>
        <div className="hold-transition login-page " style={{
          backgroundImage: "url(/dist/img/bg-login.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw", // Ensure the width is also full screen
        }}>
          
          {login}   
            <div className="login-box login-container font-befa">
              <div className="font-befa" >
                <div className="card-body login-card-body" style={{marginBottom: "-30px"}}>
                <div className="login-logo" style={{textAlign: 'left' ,marginLeft: '-10%',width:'120%'}}>
                  <p style={{color:'#404041', marginBottom:'2vw', fontSize:'24px',fontWeight:'350'}}><b>Forgot Password </b></p>
                </div>
                  <form style={{width:'120%',marginLeft: '-10%'}} onSubmit={handleFormSubmit}>
                  <div className="bottomPad textAlign" style={{color: '#282828', marginBottom: '8px' }}><b>Email</b> <span style={{ color: 'red' }}> *</span> <br/></div>
                    <div className="input-group mb-3">
                      <input style={{width:'100px !important' }} type="text" className="form-control hdInput" placeholder="Input Email" name="email" autoComplete="off" value={forgotpassEmail} onChange={(e) => setForgotPassEmail(e.target.value)}/>
                    </div>
                   
                   {/* Display email error message only if submitted and email is empty */}
                    {!submittedEmail && (
                      <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                        <p>
                        <WarningCircle width="16px" color="red" />
                          &nbsp;&nbsp;&nbsp;
                          <font color="red">Email is required</font>
                        </p>
                        <div style={{ paddingBottom: '3px' }}></div>
                      </div>
                    )}
                    
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      {errorMsg.length > 0 && (
                        <div className="input-group mb-3 font-befa" >
                          <a className="form-control hdErrorInput" style={{height:'auto'}}>
                            <WarningCircle width="16px" color="red" /> {errorMsg} 
                          </a>
                        </div>
                      )}
                    </div>

                    <br/>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary font-befa"
                          style={{
                            borderRadius: "8px",
                            marginTop: "5px",
                            backgroundColor: "#0296D2",
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start", // Align content to the left
                            height: "inherit",
                            padding: "0.5rem 1rem" // Adjust padding as needed
                          }}
                        >
                          {isLoading && (
                            <span
                              className="fa fa-circle-o-notch fa-spin"
                              style={{
                                marginRight: "5px",// Adjust the margin as needed
                              }}
                            ></span>
                          )}
                        Send Request
                        </button>
                      </div>
                    </div>
                    <br/>
                  </form>
                  
                </div>
                <hr style={{width:"116%", marginLeft:"-8%"}}/>
                <div style={{ textAlign: 'left', width: '100%' }}>
                    <a className="cursorPointer" onClick={changeScreen} >Cancel</a>
                </div>
              </div>
              <div id="requirement" />
            </div>
          </div>
        </div>
    </div>
  </div>
  )
}

export default ResetComp