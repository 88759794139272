import React from 'react';

function CandidDetHelperTwo({ header = "", text = "", workHere = 0 }) {
  return (
    <tr>
      <td style={{ padding: '8px', fontWeight: 'bold' }} className='font-befa'>{header}</td>
      <td style={{ padding: '8px' }} className='font-befa'>
        {workHere === 1 ? "Present" : text}
      </td>
    </tr>
  );
}

export default CandidDetHelperTwo;
