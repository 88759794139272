
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import ContentHeader from './../content/ContentHeader'

function Token() {
    const cookies = new Cookies();
    const { token } = useParams();
    cookies.set('sessionToken', token, { path: '/' });
    const config = {
        headers:{
            ['x-access-token']: token
        }
    };
    //alert (process.env.REACT_APP_SELF_PATH_DESTINATION);
    
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getSession`,config) 
      .then(res => {
        if (res.data.status === "200") {
          //Session Detected. Getting the data
          console.log("session exist");
          cookies.set('sessionExist', true, { path: '/' });
          cookies.set('sessionData', res.data.data, { path: '/' });
          //console.log(res.data.data);
          //self redirect to frontend home
          window.location.replace(process.env.REACT_APP_SELF_PATH_DESTINATION);
        } else {
          //No session detected. Deleting All known session
          cookies.remove('sessionToken');
          cookies.remove('sessionData');
          cookies.remove('sessionExist');
          //redirecting to portal
          window.location.replace(process.env.REACT_APP_PORTAL_DESTINATION);
        }
      })
      .catch(err => {
        //Session Error
        cookies.remove('sessionToken');
        cookies.remove('sessionData');
        cookies.remove('sessionExist');
        console.log(err);
        //redirecting to portal
        window.location.replace(process.env.REACT_APP_PORTAL_DESTINATION);
      })
  return (
    <>
        <ContentHeader title={`Redirecting....`} />
    </>
  )
}

export default Token