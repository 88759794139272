import ReactDOM from "react-dom/client";
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./pages/layout/Navbar";
import NoPage from "./pages/menu/NoPage";
import Logout from "./pages/menu/Logout";
import 'semantic-ui-css/semantic.min.css';
import Cookies from 'universal-cookie';
import axios from 'axios';
import CareerPage from "./pages/menu/dashboard/Dashb";
import Login from "./pages/menu/login/Login";
import Token from "./pages/menu/Token";
import BackToPortal from "./pages/menu/BackToPortal";
import DashboardTwo from "./pages/menu/dashboard/DashboardTwo";
import "./font/montserrat/stylesheet.css";
import './scripts/custom.css';
import useOrientation from "./pages/general/useOrientation";
import { GeolocationProvider } from "./pages/general/GeolocationContext";
import UserAddComp from "./pages/menu/user/UserAddComp";
import CandidateParent from "./pages/menu/candidate/CandidateParent";
import CandidateDetail from "./pages/menu/candidate/CandidateDetail";
import ApplyForm from "./pages/menu/applyForm/ApplyForm";
import JobAddEdit from "./pages/menu/job/JobAddEdit";
import JobDetail from "./pages/menu/job/JobDetail";
import SinglePageTable from "./pages/menu/careerTemplate/SinglePageTable";
import HeroSectionEdit from "./pages/menu/dashboard/HeroSectionEdit";
import UserList from "./pages/menu/user/UserList";
import ResponForm from "./pages/menu/applyForm/ResponForm";
import NewPass from "./pages/menu/login/NewPass";
import CandidateExport from "./pages/menu/candidate/CandidateExport";
const cookies = new Cookies();
export default function App() {
  const orientation = useOrientation();
  const [navState, setNavState] = useState("Dashboard");
  
  const changeNavState = (stateText) => {
    setNavState(stateText);
  }


  useEffect(() => {
    // Function to refresh the page
    const refreshPage = () => {
      window.location.reload();
    };

    // Event listener to detect orientation change and refresh the page
    window.addEventListener('orientationchange', refreshPage);

    return () => {
      window.removeEventListener('orientationchange', refreshPage);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    console.log("Session Token", sessionToken)
    axios.interceptors.request.use(config => { config.headers['x-access-token'] = sessionToken; return config; }, error => { return Promise.reject(error); });
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getSession`)
      .then(res => {
        if (res.data.status === "200") {
          console.log(res.data);
          //Session Detected. Getting the data
          console.log("session exist"); cookies.set('sessionExist', true, { path: '/' });
          cookies.set('sessionData', res.data.data, { path: '/' });
        } else {
          //No session detected. Deleting All known session
          cookies.remove('sessionToken'); cookies.remove('sessionData'); cookies.remove('sessionExist');
        }
      })
      .catch(err => {
        //Session Error
        //alert("session error");
        cookies.remove('sessionToken'); cookies.remove('sessionData'); cookies.remove('sessionExist');
        console.log(err);
      })
  }, [])

  return (
    <GeolocationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/" element={cookies.get('sessionExist') ? (<Navbar navState={navState} changeNavState={changeNavState} />) : (<Navigate replace to={"/dashboard"} />)}>
            <Route index element={<CareerPage changeNavState={changeNavState} state="admin" />} />
            <Route path="dashboardAdmin" element={<Navigate replace to={"/"} />} />
            <Route path="jobAdd" element={<JobAddEdit state="add"/>} />
            <Route path="jobEdit/:jobID" element={<JobAddEdit state="edit"/>} />
            <Route path="editHeroSection" element={<HeroSectionEdit />} />
            <Route path="candidateList/:jobID" element={<CandidateParent changeNavState={changeNavState} />} />
            <Route path="candidateDetail/:jobID/:id" element={<CandidateDetail changeNavState={changeNavState} />} />
            <Route path="candidateExport/:jobID/:id" element={<CandidateExport changeNavState={changeNavState} />} />
            <Route path="singleTable/:tableName/:tableField" element={<SinglePageTable />} />
            <Route path="userList" element={<UserList/>} />
          </Route>
          <Route path="/" element={<CareerPage state="user"/>} />
          <Route path="login" element={<Login />} />
          <Route path="newpass/:token" element={<NewPass />} />
          <Route path="dashboard" element={<CareerPage state="user"/>} />
          <Route path="jobDetail/:id" element={<JobDetail />} />
          <Route path="userAdd" element={<UserAddComp buttonText="Add New 2"/>} />
          <Route path="logout" element={<Logout />} />
          <Route path="backToPortal" element={<BackToPortal />} />
          <Route path="/portalLogin/:token" element={<Token />} />
          <Route path="/applyForm/:jobID" element={<ApplyForm />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </GeolocationProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
