import React from 'react'; import { useState, useEffect} from 'react';
import LatestActivity from './LatestActivity';
import axios from 'axios' 
import Cookies from 'universal-cookie';
const cookies = new Cookies();
function LatestActivityParent({link}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [link])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + link;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      
      setData(res.data.data);
      if (res.data.status = "200"){
        console.log("this is latest activity data");
        console.log(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })  
  }   
  return (
    <div className='borderWithRadius'> 
          <div className="row" style={{marginTop:"1.5vw", marginBottom:'1.25vw'}}>
            <div className="col-md-9">
              <p className='dashboardMarginLeft' style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}><b>My Latest Activity</b></p>
            </div>
            <div className="col-md-3" style={{textAlign:"right"}}>
             
            </div>
          </div>
          {data.map((item, index) => (
             <LatestActivity key={'LAC' + index} title={item.action_name} body={item.title} time={item.created_at} status={item.status_name}/>
          ))}<div style={{marginBottom : '-0.2vw'}}> &nbsp; </div>
    </div>
  )
}

export default LatestActivityParent