import React, { useState, useEffect, useRef } from 'react';

function AutocompleteInput({ presetValues, text, data, setData, readOnly = false }) {
  const [inputValue, setInputValue] = useState(data);
  const [filteredValues, setFilteredValues] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(data);
  }, [data]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const matches = presetValues.filter((preset) =>
        preset.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredValues(matches);
    } else {
      setFilteredValues([]);
    }
  };

  const handleValueClick = (value) => {
    setInputValue(value);
    setData(value);
    setFilteredValues([]);
    inputRef.current.blur(); // Remove focus from the input
  };

  const handleBlur = () => {
    // Delay the hiding of the dropdown to allow click to register
    setTimeout(() => setFilteredValues([]), 100);
  };

  return (
    <div style={{ marginBottom: '25px', position: 'relative' }}>
      <h5 className='font-befa'>{text} <span style={{ color: 'red' }}>*</span></h5>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        className="hdInput font-befa"
        style={{ padding: '10px', width: '92%' }}
        disabled={readOnly}
        onBlur={handleBlur}
      />
      {filteredValues.length > 0 && (
        <ul
          className="autocomplete-dropdown"
          style={{
            border: '1px solid #ccc',
            margin: 0,
            padding: 0,
            listStyleType: 'none',
            width: '92%',
            position: 'absolute',
            zIndex: 1000,
            backgroundColor: '#fff',
          }}
        >
          {filteredValues.map((value, index) => (
            <li
              key={index}
              onClick={() => handleValueClick(value)}
              style={{ padding: '10px', cursor: 'pointer' }}
            >
              {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AutocompleteInput;
