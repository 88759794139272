import React, { useState, useEffect } from 'react';
import '../../../scripts/custom.css';
import { WarningCircle } from 'iconoir-react';

function UserAddComp({ isLoading, buttonText }) {
  // Add state variables to track form fields and submission
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submittedUsername, setSubmittedUsername] = useState(true);
  const [submittedEmail, setSubmittedEmail] = useState(true);
  const [submittedPassword, setSubmittedPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = () => {
    alert("DONE " + username + " + " + email + " + " + password)
  };

  useEffect(() => {
    setSubmittedUsername(true);
    setSubmittedEmail(true);
    setSubmittedPassword(true);
  }, []);

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (username.length === 0) {
      setSubmittedUsername(false);
      return;
    } else {
      setSubmittedUsername(true);
    }

    if (email.length === 0) {
      setSubmittedEmail(false);
      return;
    } else {
      setSubmittedEmail(true);
    }

    if (password.length === 0) {
      setSubmittedPassword(false);
      return;
    } else {
      setSubmittedPassword(true);
    }

    handleSubmit();
  };

  const renderForm = () => (
    <div className="login-box">
      <div className="" style={{ paddingTop: '20px' }}>
        <div className="card-body login-card-body">
          <div className="login-logo" style={{ textAlign: 'left', marginLeft: '-10%', width: '120%' }}>
            <p style={{ fontFamily: 'Blender Pro', color: '#404041', marginBottom: '2vw', fontWeight: '500' }}>
              <b>Add New User</b>
            </p>
          </div>
          <form style={{ width: '120%', marginLeft: '-10%' }} onSubmit={handleFormSubmit}>
            <div className="bottomPad">
              <b>Username</b>
              <br />
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control hdInput"
                placeholder="Input Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            {!submittedUsername && (
              <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                <p>
                  <WarningCircle width="16px" color="red" />
                  &nbsp;&nbsp;&nbsp;
                  <font color="red">Username is required</font>
                </p>
                <div style={{ paddingBottom: '3px' }}></div>
              </div>
            )}

            <div className="bottomPad">
              <b>Email</b>
              <br />
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control hdInput"
                placeholder="Input Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {!submittedEmail && (
              <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                <p>
                  <WarningCircle width="16px" color="red" />
                  &nbsp;&nbsp;&nbsp;
                  <font color="red">Email is required</font>
                </p>
                <div style={{ paddingBottom: '3px' }}></div>
              </div>
            )}

            <div className="bottomPad">
              <b>Password</b>
              <br />
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control hdInput"
                placeholder="Input Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {!submittedPassword && (
              <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                <p>
                  <WarningCircle width="16px" color="red" />
                  &nbsp;&nbsp;&nbsp;
                  <font color="red">Password is required</font>
                </p>
                <div style={{ paddingBottom: '3px' }}></div>
              </div>
            )}

            <div style={{ textAlign: 'left', width: '100%' }}>
              {errorMsg.length > 0 && (
                <div className="input-group mb-3 font-befa">
                  <a className="form-control hdErrorInput">
                    <WarningCircle width="16px" color="red" /> {errorMsg}
                  </a>
                </div>
              )}
              <div style={{ paddingBottom: '3px' }}></div>
            </div>

            <br />
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block font-befa"
                  style={{
                    borderRadius: '8px',
                    marginTop: '2vw',
                    backgroundColor: '#0296D2',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'inherit',
                  }}
                >
                  {isLoading && (
                    <span
                      className="fa fa-circle-o-notch fa-spin"
                      style={{
                        marginRight: '5px',
                      }}
                    ></span>
                  )}
                  {buttonText}
                </button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
      <div id="requirement" />
    </div>
  );

  return (
    <div className="">
      <div className="row" style={{ maxHeight: '100vh', maxWidth: '100vw' }}>
        <div className="col-sm-12 col-md-12">
          <div className="hold-transition login-page" style={{ backgroundColor: 'white' }}>
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAddComp;