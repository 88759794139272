import React from 'react';
import { Tooltip } from 'react-tooltip';

function CandButton({ mrgnRight, Icon, onClick, color, tooltip = "hint button" }) {
  return (
    <div>
      <div
        className="buttonHover"
        onClick={onClick}
        style={{
          border: '1px solid #E8E9EB',
          borderRadius: '8px',
          width: '52px',
          height: '52px',
          marginTop: '-50px',
          float: 'right',
          marginRight: mrgnRight,
        }}
        data-tooltip-id="tooltip"
        data-tooltip-content={tooltip}
      >
        <Icon
          style={{ color: color, margin: '16px', height: '20px', width: '20px' }}
        />
      </div>
      <Tooltip id="tooltip" />
    </div>
  );
}

export default CandButton;
