import React from 'react'; import { useState, useEffect} from 'react';
import Announcement from './Announcement'
import axios from 'axios' 
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function AnnouncementParent({link}) {
  const orientation = useOrientation();
  const navigate = useNavigate();
  const handleClick = () => {
    // Use the navigate function to navigate to google.com
    navigate('/announcement');
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [link])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + link;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      
      setData(res.data.data);
      if (res.data.status = "200"){
        console.log(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })  
  }   
  if (orientation === 'landscape')
  return (
  <>
  <>
    <div className='borderWithRadius'>
        <div className="text-left" style={{ }}> 
          <div className="row dashboardMarginRight" style={{marginTop:"1.3vw", marginRight:'1vw', marginBottom:'1.3vw', marginLeft:'1vw'}}> 
              <div className="col-md-7 " style={{marginBottom: '0.5vw', fontFamily:'Blender Pro'}}>
                   <p style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}> <b>Announcement</b></p>
              </div>
              <div className="col-md-5" style={{textAlign:"right", fontFamily:'Blender Pro'}}>
                  <a onClick={handleClick} className='cursorPointer' style={{color:'#0296D2'}}>View more</a>
              </div>
              {data.map((item, index) => (
              <Announcement key={'ANM' + index} id={item.id} title={item.title} content={item.konten} date={item.created_at} username={item.display_name} category={item.kategori_name}/>
              ))}
          </div> 
        
        </div>
    </div>
  </>
  
  </>
  )

  if (orientation === 'portrait')
  <>
    <div className='borderWithRadius'>
      <div className="text-left" style={{marginLeft:'1vw' }}> 
        <div className="row dashboardMarginRight" style={{marginTop:"1.5vh", marginRight:'1vw', marginBottom:'1.3vh', marginLeft:'1vw'}}> 
          <div className="col-md-7 " style={{marginBottom: '0.5vw', fontFamily:'Blender Pro'}}>
                <p style={{fontFamily:'Blender Pro Book', fontSize:'2.5vh'}}> <b>Announcement</b></p>
          </div>
          <div className="col-md-5" style={{textAlign:"right", fontFamily:'Blender Pro', marginTop:'-3.5vh', marginBottom:'3vh'}}>
              <a onClick={handleClick} className='cursorPointer' style={{color:'#0296D2'}}>View more</a>
          </div>
          {data.map((item, index) => (
          <Announcement key={'ANM' + index} id={item.id} title={item.title} content={item.konten} date={item.created_at} username={item.display_name} category={item.kategori_name}/>
          ))}
        </div> 
      </div>
    </div>
  </>
}

export default AnnouncementParent