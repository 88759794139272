import React from 'react'; import { useState, useEffect,useRef} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {StatsUpSquare,DownloadSquare,CheckCircle,Prohibition,Clock} from 'iconoir-react'
import Cookies from 'universal-cookie';import axios from 'axios' ;
import CandidDetHelper from './CandidDetHelperTwo';
import CandButton from './CandButton';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import CandDetInput from './CandDetInput';
import CareerHeader from '../careerTemplate/CareerHeader';
import DateTimeInput from '../applyForm/DateTimeInput';


const cookies = new Cookies();
function CandidateExport() {
  const targetRef = useRef();
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const { jobID,id } = useParams();
  const [data, setData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [statusString, setstatusString] = useState([]);
  const [statusState, setStatusState] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [entity, setEntity] = useState("");
  const [role, setRole] = useState("");
  const [joinDatetime, setJoinDatetime] = useState("");
  const [firstDayLoc, setFirstDayLoc] = useState("");
  const [firstDayAdr, setFirstDayAdr] = useState("");
  const [dressCode, setDressCode] = useState("");
  const [picContact, setPicContact] = useState("");
  const [accEditState, setAccEditState] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const candidateListSection = {
    background: "#fff",
    borderRadius:'10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px',
    margin: '20px auto',
    maxWidth: '100vw',
  }
  const navigateAdminHome = () => {
    navigate("/admin");
  };
  const navigateJob = () => {
    navigate("/admin/candidateList/" + jobID);
  };

  useEffect(() => {
    getData();
  }, [])
  const acceptAction = () => {
    setStatusState("accept");
    setstatusString("Accepted")
  };
  const rejectAction = () => {
    setStatusState("reject");
    setstatusString("Rejected")
  };
  const acceptEditState = () => {
    setAccEditState(true);
  };
  const normalAction = (id, string) => {
    setStatusState("");
    MySwal.fire({
      title: '<strong>Confirmation</strong>',
      html: `<p>Status will change to ${string} </p>`,
      width:'800px',
      showCancelButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed'
    }).then(result => {
      if (result.isConfirmed) {
        // Handle the "Proceed" button click
        setStatusApplicantNormal(id) ;
      } else {
        if (id == 3) { setstatusString("Pending")}
        if (id == 7) { setstatusString("Process")}
        MySwal.close()
      }
    })
  };
  const acceptWithDetail = (editOrAdd) => {
    setStatusState("");
    if (entity === "" || role === "" | joinDatetime === "" | firstDayLoc === ""
      || firstDayAdr === "" || dressCode === "" || picContact === "") {
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <p>Please fill every field</p>
      })
      return 
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicantAccept${editOrAdd}`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: 4, id: id, entity: entity,role:role, 
      joinDatetime:joinDatetime, firstDayLoc:firstDayLoc,
      firstDayAdr:firstDayAdr, dressCode:dressCode, picContact:picContact};  
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Succcess</strong>,
            html: <p>Data Edited</p>
          })
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  };


   

  
  const rejectWithDetail = () => {
    //alert("sdsadsadsa");
    //gather data, clicked when its 
    setStatusState("");
    if (rejectReason === "") {
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <p>Reject reason is required</p>
      })
      return 
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicantReject`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: 5, id: id, reject_reason: rejectReason };  
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  };

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/getApplicantDetail/${id}`;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      console.log(res.data.data);
      if (res.data.status = "200"){
        setData(res.data.data);
        setWorkData(res.data.workData);
        setstatusString(res.data.data.status_name);
        setDataLoaded(true);
      }
    })
    .catch(err => {
      console.log(err);
    })  
  }  

  const setStatusApplicantNormal = (statusID) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeStatusApplicant`;
    const config = { headers: { 'x-access-token': sessionToken } };
    const data = { status_id: statusID, id: id };  
    
    axios.post(path, data, config)
    .then(res => {
        //console.log(res.data.data);
        if (res.data.status === "200") {
          getData();
        } else {
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <p>{res.data.message}</p>
          })
        }
    })
    .catch(err => {
        console.log(err);
    });
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    
    return `${day} ${month} ${year}`;
  }
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Adding leading zeros to hours, minutes, and seconds if needed
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${day} ${month} ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  function imageDL(link) {
    window.open(link, '_blank');
  }

  // Trigger print when data and images are fully loaded
  useEffect(() => {
    if (dataLoaded && imagesLoaded) {
      window.print();
    }
  }, [dataLoaded, imagesLoaded]);

  // Track images loading state
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };
  return (
    <div className="pdfDiv">
        <div style={{marginTop:'48px', marginLeft:'80px', paddingRight:'30px'}}>
            <div className='' style={{marginBottom:'48px'}}>
                <CandidDetHelper header="Job Position" text={data.title}/>
                <CandidDetHelper header="Full Name" text={data.name}/>
                <CandidDetHelper header="Birth Date" text={formatDate(data.birth_date) + ` (${data.age})`}/>
                <CandidDetHelper header="Full Domicile Address" text={data.address}/>
                <CandidDetHelper header="Email" text={data.email}/>
                <CandidDetHelper header="Phone Number" text={data.phone}/>
                <CandidDetHelper header="Latest Education Level" text={data.last_education}/>
                <CandidDetHelper header="Major" text={data.major}/>
                <CandidDetHelper header="Institution" text={data.school_univ}/>
                <CandidDetHelper header="Enrollment Year" text={data.enroll_year}/>
                <CandidDetHelper header="Graduation Year" text={data.grad_year}/>
                {workData.length > 0 && (
                    <div style={{marginTop:'15px'}} className='col-md-12'>
                        
                    </div>
                )}
                {workData.map((experience, index) => (<>
                    <div key={index} style={{marginBottom:'10px'}} className='col-md-12'>
                    <h5 style={{color:'#737E86'}}>Experience {index + 1}</h5>
                    </div>
                    <CandidDetHelper header="Company Name" text={experience.company_name}/>
                    <CandidDetHelper header="Company Sector" text={experience.company_sector}/>
                    <CandidDetHelper header="Start Date" text={formatDate(experience.start_date)}/>
                    <CandidDetHelper workHere={experience.still_work_here} header="End Date" text={formatDate(experience.quit_date)}/>
                    <CandidDetHelper header="Position & Division" text={experience.position_division}/>
                    <CandidDetHelper header="Job Description" text={experience.job_desc}/>
                    <CandidDetHelper header="Reason of Resignation" text={experience.quit_reason}/>
                    <CandidDetHelper header="Notice Period" text={experience.notice_period}/>
                    
                    <div key={index} style={{marginBottom:'15px'}} className='col-md-12'>
                        
                    </div>
                    </>
                ))}

                
                <CandidDetHelper header="Job Info From" text={data.find_from}/>
                <div style={{marginBottom:'10px'}} className='col-md-4'> <h5>Photo</h5> </div>
                <div className='col-md-8'>
                    <img className="cursorPointer" onLoad={handleImageLoad} onClick={() => imageDL(process.env.REACT_APP_IMG_DESTINATION + data.photo_address)} style={{maxWidth:'350px'}} src={process.env.REACT_APP_IMG_DESTINATION + data.photo_address}/>
                </div>
                <div style={{marginBottom:'10px',marginTop:'10px'}} className='col-md-4'> <h5>CV</h5> </div>
                <div style={{marginBottom:'10px',marginTop:'10px'}} className='col-md-8'>
                    <a target="_blank" href={process.env.REACT_APP_IMG_DESTINATION + data.cv_address}>{data.cv_address}</a>
                </div>
                <CandidDetHelper header="Apply Date" text={formatDateTime(data.created_at)}/>
            </div>
        </div>
    </div>
  )
}

export default CandidateExport