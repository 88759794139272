import React, { useEffect, useState } from 'react'
import CareerHeader from '../careerTemplate/CareerHeader';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Edit } from 'iconoir-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const cookies = new Cookies();
const tableCand = {
    minWidth: '80vw',
    borderCollapse: 'collapse'
};

const thtdCand = {
    border: "1px solid #ddd",
    padding: "8px"
};

function UserList() {
    const MySwal = withReactContent(Swal)
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
        console.log('data :', data);
    }, [])

    const getData = () => {
        var sessionToken = cookies.get('sessionToken', { path: '/' });
        var path = process.env.REACT_APP_PATH_DESTINATION + `/userList`; 
        const config = { headers: { 'x-access-token': sessionToken } };  
        axios.get(path, config)
        .then(res => {
            console.log(res.data.data)
          if (res.data.status = "200"){
            setData(res.data.data);
          }
        })
        .catch(err => {
          console.log(err);
        })
      }

    const editClicked = (id, username, fullname, email, password, status, level) => {
      MySwal.fire({
        title: <strong>Edit User</strong>,
        html: (
          <div>
            <label htmlFor="username-input">Username :</label>
            <input id="username-input" className="swal2-input" placeholder="Enter username" defaultValue={username} />
            <label htmlFor="fullname-input">Full Name :</label>
            <input id="fullname-input" className="swal2-input" placeholder="Enter full name" defaultValue={fullname} />
            <label htmlFor="email-input">Email :</label>
            <input id="email-input" className="swal2-input" placeholder="Enter email" defaultValue={email} />
            <label htmlFor="password-input">Password :</label>
            <input id="password-input" type="password" className="swal2-input" placeholder="Leave blank if no change" />
            <label htmlFor="level-input">Level :</label>
            <select id="level-input" className="swal2-input" defaultValue={level}>
              <option value="1">Super Admin</option>
              <option value="2">Admin</option>
              <option value="3">User</option>
            </select>
          </div>
        ),
        showCancelButton: true,
        confirmButtonText: 'Edit',
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          const username = document.getElementById('username-input').value;
          const fullname = document.getElementById('fullname-input').value;
          const email = document.getElementById('email-input').value;
          const password = document.getElementById('password-input').value;
          const level = document.getElementById('level-input').value;
          return { username, fullname, email, password, level };
        }
      }).then((result) => {
        if (result.isConfirmed) {
    
          MySwal.fire({
            title: 'Editting user...',
            text: 'Please wait while we edit the user.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              MySwal.showLoading();
            }
          });
    
          var sessionToken = cookies.get('sessionToken', { path: '/' });
          var path = process.env.REACT_APP_PATH_DESTINATION + `/EditUser`;
          const config = { headers: { 'x-access-token': sessionToken } };
          const data = { 
            user_id: id,
            username: result.value.username,
            fullname: result.value.fullname,
            email: result.value.email,
            password: result.value.password,
            status: status,
            level: result.value.level
          };  
          axios.post(path, data, config)
            .then(res => {
              if (res.data.status === "200") {
                MySwal.fire({
                  icon: 'success',
                  title: 'User Edited',
                  text: `User successfully edited.`,
                  willClose: () => {
                    getData();
                  }
                });
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `Failed to edit the user. ${res.data.message}`,
                  willClose: () => {
                    getData();
                  }
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    };
    

    const addClicked = () => {
      MySwal.fire({
        title: <strong>Add User</strong>,
        html: (
          <div>
            <label htmlFor="username-input">Username :</label>
            <input id="username-input" className="swal2-input" placeholder="Enter username" />
            <label htmlFor="fullname-input">Full Name :</label>
            <input id="fullname-input" className="swal2-input" placeholder="Enter full name" />
            <label htmlFor="email-input">Email :</label>
            <input id="email-input" className="swal2-input" placeholder="Enter email" />
            <label htmlFor="password-input">Password :</label>
            <input id="password-input" type="password" className="swal2-input" placeholder="Enter Password" />
            <label htmlFor="level-input">Level :</label>
            <select id="level-input" className="swal2-input">
              <option value="1">Super Admin</option>
              <option value="2">Admin</option>
              <option value="3">User</option>
            </select>
          </div>
        ),
        showCancelButton: true,
        confirmButtonText: 'Add',
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          const username = document.getElementById('username-input').value;
          const fullname = document.getElementById('fullname-input').value;
          const email = document.getElementById('email-input').value;
          const password = document.getElementById('password-input').value;
          const level = document.getElementById('level-input').value;
          return { username, fullname, email, password, level };
        }
      }).then((result) => {
        if (result.isConfirmed) {
    
          MySwal.fire({
            title: 'Creating user...',
            text: 'Please wait while we create the new user.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              MySwal.showLoading();
            }
          });
    
          var sessionToken = cookies.get('sessionToken', { path: '/' });
          var path = process.env.REACT_APP_PATH_DESTINATION + `/CreateNewUser`;
          const config = { headers: { 'x-access-token': sessionToken } };
          const data = { 
            username: result.value.username,
            fullname: result.value.fullname,
            email: result.value.email,
            password: result.value.password,
            status: "1",
            level: result.value.level
          };  
          axios.post(path, data, config)
            .then(res => {
              if (res.data.status === "200") {
                MySwal.fire({
                  icon: 'success',
                  title: 'User Added',
                  text: `New user successfully added.`,
                  willClose: () => {
                    getData();
                  }
                });
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `Failed to add new user. ${res.data.message}`,
                  willClose: () => {
                    getData();
                  }
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    };
    

  const userActive = (id) => {
    MySwal.fire({
      title: 'Changing user status...',
      text: 'Please wait while we process your request.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
          MySwal.showLoading();
      }
    });

    var sessionToken = cookies.get('sessionToken', { path: '/' });
    var path = process.env.REACT_APP_PATH_DESTINATION + `/changeActiveUser/` + id;
    const config = { headers: { 'x-access-token': sessionToken } };
    axios.get(path, config)
      .then(res => {
        console.log(res.data)
        if (res.data.status == "200") {
            MySwal.fire({
                icon: 'success',
                title: 'Status User Changed',
                text: `Status User Changed Successfully.`,
                willClose: () => {
                  getData();
                }
              });
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: `Failed to change user status. ${res.data.messsage}`,
            willClose: () => {
              getData();
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

    return (<>
    <header className="fixed-header">
        <CareerHeader/> 
    </header>
    
    <div style={{marginTop:'100px', marginLeft:'10%'}}>
    <h3>User Role / Level Access Explanation</h3>
    <img src="/UserRole.png" style={{width:'80vw'}}/>
    <h3>Table User List</h3>
    <button style={{  }} onClick={() => addClicked()} className='btn btn-primary' >Add New</button>
    <br/><br/>
    <table style={tableCand} >
        <thead >
            <tr style={{ backgroundColor: '#E8E9EB' }}>
                <th style={thtdCand}>No.</th>
                <th style={thtdCand}>Level Access</th>
                <th style={thtdCand}>Username</th>
                <th style={thtdCand}>Full Name</th>
                <th style={thtdCand}>Email</th>
                <th style={thtdCand}>Active</th>
                <th style={thtdCand}>Edit</th>
            </tr>
        </thead>
        <tbody>
        {data && data.length > 0 && data.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'tableWhite tableHover' : 'tableGray tableHover'}>
                <td style={thtdCand}>{index + 1}</td>
                <td style={thtdCand}>{row.level_access}</td>
                <td style={thtdCand}>{row.username}</td>
                <td style={thtdCand}>{row.fullname}</td>
                <td style={thtdCand}>{row.email}</td>
                <td style={thtdCand}>
                    {row.active === 1 ? (<>
                    <img className="job-type" style={{height:'20px', marginRight:'10px'}}src={process.env.REACT_APP_SELF_PATH_DESTINATION + "ontxt.png"}/> 
                    <img onClick={() => userActive(row.id)}className="cursorPointer" style={{height:'20px', marginLeft:'10px'}} src={process.env.REACT_APP_SELF_PATH_DESTINATION + "SwitchOn.png"}></img>
                    </>):(<>
                    <img className="job-type" style={{height:'20px', marginRight:'10px'}} src={process.env.REACT_APP_SELF_PATH_DESTINATION + "offtxt.png"}/> 
                    <img onClick={() => userActive(row.id)} className="cursorPointer" style={{height:'20px', marginLeft:'5px'}} src={process.env.REACT_APP_SELF_PATH_DESTINATION + "SwitchOff.png"}></img>
                    </>)} 
                </td>
                <td style={thtdCand} onClick={() => editClicked(row.id, row.username, row.fullname, row.email, row.password, row.active, row.level_id)}><Edit className='cursorPointer' /></td>
            </tr>
        ))}
        </tbody>
        <br/><br/><br/>
    </table>
    </div>
    </>)
}

export default UserList