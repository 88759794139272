import React, { useEffect, useState } from 'react'
import CareerHeader from '../careerTemplate/CareerHeader'
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';import axios from 'axios' ;
import CandDetInput from '../candidate/CandDetInput';

import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import FooterPage from '../headerPage/FooterPage';
import BewareFraud from '../headerPage/BewareFraud';
import HeaderIMG from '../job/HeaderIMG';
import DateTimeInput from '../applyForm/DateTimeInput';
import CheckboxInput from '../applyForm/CheckboxInput';
import AFRowHelper from '../applyForm/AFRowHelper';
const cookies = new Cookies(); 
function HeroSectionEdit() {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [overOne, setOverOne] = useState([]);
    const [overTwo, setOverTwo] = useState([]);
    const [bgOne, setBgOne] = useState([]);
    const [bgTwo, setBgTwo] = useState([]);
    const [headingStr, setHeadingStr] = useState("");
    const [subHeadingStr, setSubHeadingStr] = useState("");
    const [newBG1, setNewBG1] = useState(false);
    const [newBG2, setNewBG2] = useState(false);
    const [newPhoto1, setNewPhoto1] = useState(false);
    const [newPhoto2, setNewPhoto2] = useState(false);

  
    const handlePhotoOneChange = (e) => {
        setOverOne(e.target.files[0]);
    };
      
    const handlePhotoTwoChange = (e) => {
        setOverTwo(e.target.files[0]);
    };
          
    const handleBGOneChange = (e) => {
        setBgOne(e.target.files[0]);
    };
      
    const handleBGTwoChange = (e) => {
        setBgTwo(e.target.files[0]);
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
      getData();
    }, [])

    const getData = () => {
        const path2 = process.env.REACT_APP_PATH_DESTINATION + `/getBanner` ;
        axios.get(path2)
            .then(res => {
            setBgOne(res.data.data.background)
            setBgTwo(res.data.data.background_overlay)
            setOverOne(res.data.data.photo_one)
            setOverTwo(res.data.data.photo_two)
            setHeadingStr(res.data.data.heading)
            setSubHeadingStr(res.data.data.sub_heading)
            })
            .catch(err => {
            console.log(err);
        });
    }  

    const submitData = () => {
        //confirmation - no empty field
        if (!headingStr || !subHeadingStr || (overOne.length === 0 && newPhoto1 === true) 
         || (overTwo.length === 0 && newPhoto2 === true)  
         || (bgTwo.length === 0 && newBG2 === true) ) {
            MySwal.fire({
                title: <strong>Fill all fields</strong>,
                html: <p>Please fill all the required fields</p>
            })
            return;
        }

        MySwal.fire({
            title: 'Processing Your Request...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                MySwal.showLoading();
            }
        });

        // Create a FormData object
        const formData = new FormData();
        formData.append('photo_one_file', overOne);
        formData.append('photo_two_file', overTwo);
        formData.append('background_file', bgOne);
        formData.append('background_file_two', bgTwo);
        formData.append('heading', headingStr);
        formData.append('sub_heading', subHeadingStr);
        formData.append('newPhoto1', newPhoto1);
        formData.append('newPhoto2', newPhoto2);
        formData.append('newBG1', newBG1);
        formData.append('newBG2', newBG2);
        formData.append('id', "1");

        var path = process.env.REACT_APP_PATH_DESTINATION + `/editBanner`;   
        axios.post(path,formData)
        .then(res => {
            if (res.data.status == "200"){
                MySwal.fire({
                    title: <strong>Success</strong>,
                    html: <p>Banner Edited</p>
                }).then((result) => {
                    navigate(`/admin/`)
                })
            } else {
                MySwal.fire({
                    title: <strong>Error</strong>,
                    html: <p>{res.data.message}</p>
                })
                console.log(res.data);
            }
        })
        .catch(err => {
            console.log(err);
        })
    } 
  return (
  <div className="" style={{maxHeight: '100%', maxWidth:'100vw' }} >
    <div className="">
        <header className="fixed-header">
          <CareerHeader/> 
        </header>
        <br/><br/><br/><br/>
        <div className="center">
          <div className="center" style={{ alignItems: 'flex-start' }} >
            <div className="center" 
                style={{
                    backgroundColor: '#FFFFFF', 
                    backgroundImage: 'url(/CandidateDetailBG.png)', 
                    backgroundPosition: 'top right', 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 150px',
                    width:'70%',
                    marginLeft:'15%',
                    border: '1px solid #E8E9EB',
                }}
            >   
                <div style={{marginTop:'48px', marginLeft:'80px'}}>
                    <div style={{textAlign:'center'}}>
                        <br/>
                        <h2> Edit Hero Section  </h2>
                        <p>   </p>
                        <br/><br/>
                    </div>
                    <div style={{paddingBottom:'20px'}}>
                        
                        <CandDetInput text="Header" data={headingStr} setData={setHeadingStr}/>
                        <br/>
                        <CandDetInput text="Sub header" data={subHeadingStr} setData={setSubHeadingStr}/>

                        {newPhoto1 === true ? (<>
                        <br/>
                        <div className="form-group" >
                            <h5>Photo One (220 x 220)<span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo1">Choose File </label> 
                            <br/> {overOne && (<p> Chosen: {overOne.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo1" accept=".jpeg, .png, .jpg" onChange={handlePhotoOneChange} />
                        </div>
                        </>) : (<>
                            <h5>Photo One (220 x 220)</h5>
                            <img style={{width:'20vw',marginTop:'0px'}}  src={process.env.REACT_APP_BANNER_DESTINATION + overOne} alt="photo2" />
                            <button style={{marginLeft:'0px',marginTop: '20px', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicket" type="button" onClick={() => setNewPhoto1(true)}>
                            Remove and add new photo
                            </button>
                        </>)}
                        
                        {newPhoto2 === true ? (<>
                        <br/>
                        <div className="form-group" >
                            <h5>Photo Two (220 x 220)<span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo2">Choose File </label> 
                            <br/> {overTwo && (<p> Chosen: {overTwo.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo2" accept=".jpeg, .png, .jpg" onChange={handlePhotoTwoChange} />
                        </div>
                        </>) : (<>
                            <h5>Photo Two (220 x 220)</h5>
                            <img style={{width:'20vw',marginTop:'0px'}}  src={process.env.REACT_APP_BANNER_DESTINATION + overTwo} alt="photo2" />
                            <button style={{marginLeft:'0px',marginTop: '20px', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicket" type="button" onClick={() => setNewPhoto2(true)}>
                            Remove and add new photo
                            </button>
                        </>)}
                        
                        {newBG1 === true ? (<>
                        <br/>
                        <div className="form-group" >
                            <h5>Background One (1280 x 518) (will be faded out) default shape square<span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo3">Choose File </label> 
                            <br/> {bgOne && (<p> Chosen: {bgOne.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo3" accept=".jpeg, .png, .jpg" onChange={handleBGOneChange} />
                        </div>
                        </>) : (<>
                            <h5>Background One (1280 x 518) (will be faded out) default shape square</h5>
                            <img style={{width:'50vw',marginTop:'0px'}}  src={process.env.REACT_APP_BANNER_DESTINATION + bgOne} alt="photo3" />
                            <button style={{marginLeft:'0px',marginTop: '20px', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicket" type="button" onClick={() => setNewBG1(true)}>
                            Remove and add new background
                            </button>
                        </>)}
                        
                        {newBG2 === true ? (<>
                        <br/>
                        <div className="form-group" >
                            <h5>Background Two (1280 x 518) (will be faded in) default shape square <span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo4">Choose File </label> 
                            <br/> {bgTwo && (<p> Chosen: {bgTwo.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo4" accept=".jpeg, .png, .jpg" onChange={handleBGTwoChange} />
                        </div>
                        </>) : (<>
                            <h5>Background Two (1280 x 518) (will be faded in) default shape square</h5>
                            <img style={{width:'50vw',marginTop:'0px'}}  src={process.env.REACT_APP_BANNER_DESTINATION + bgTwo} alt="photo4" />
                            <button style={{marginLeft:'0px',marginTop: '20px', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicket" type="button" onClick={() => setNewBG2(true)}>
                            Remove and add new background
                            </button>
                        </>)}
                        
                        
                        
                    </div>
                    <button className="submitButton" onClick={submitData} style={{marginBottom:'40px'}}> Submit </button>
                </div>
            </div>
            <br/><br/>
          </div>
        </div>
    </div>
    <FooterPage />
  </div>
  )
}

export default HeroSectionEdit