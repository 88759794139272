import React, { useState, useEffect } from 'react';

// Custom hook to detect orientation changes
function useOrientation() {
  const [orientation, setOrientation] = useState(getOrientation());

  // Function to get current orientation
  function getOrientation() {
    return window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape";
  }

  // Event listener to update orientation when it changes
  useEffect(() => {
    function handleOrientationChange() {
      setOrientation(getOrientation());
    }

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []); // Empty dependencies array to run the effect only once during mounting

  return orientation;
}

export default useOrientation;
