import React, { useState } from 'react';
import CandDetInput from './CandDetInput';

function CandDropdown({ presetValues, text, data, setData, readOnly = false }) {
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === 'Others') {
      setIsOtherSelected(true);
      setData('');
    } else {
      setIsOtherSelected(false);
      setData(value);
    }
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      {isOtherSelected ? (
        <CandDetInput text={text} data={data} setData={setData} readOnly={readOnly} />
      ) : (
        <div style={{ marginBottom: '25px' }}>
        <h5 className='font-befa'>{text} <span style={{ color: 'red' }}>*</span></h5>
        <select
          value={data}
          onChange={handleChange}
          className="hdInput font-befa"
          style={{ padding: '10px', width: '92%' }}
          disabled={readOnly}
        >
          {presetValues.map((value, index) => (
            <option key={index} value={value}>{value}</option>
          ))}
        </select>
        </div>
      )}
    </div>
  );
}

export default CandDropdown;
