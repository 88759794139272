import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import {PinAlt, Group, NavArrowDown, Search, StatsUpSquare,Plus,DownloadCircle, FilterAlt} from 'iconoir-react';
import { motion, useAnimation } from 'framer-motion';
import CareerHeader from '../careerTemplate/CareerHeader';
import FooterPage from '../headerPage/FooterPage';
import JobListingAdmin from './JobListingAdmin';
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';

function JobListing({id, title, division_name, location_name, job_type_name }) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  
  // Function to generate URL-friendly title
  const formatTitle = (title) => {
    return title.replace(/[^a-zA-Z0-9]/g, ''); // Remove all non-alphanumeric characters
  }
  
  const navigateApply = (e) => {
    e.stopPropagation();
    navigate('/applyForm/' + formatTitle(title));
  }

  const navigateDetail = () => {
    navigate('/jobDetail/' + formatTitle(title));
  }

  const nameMobile= (txt) =>{
    let maxChar = 17;
    if (txt.length > maxChar) {
      return txt.slice(0, maxChar) + '..';
    }
    return txt;
  }

  if (orientation === 'landscape')
  return (
    <div className="card outerDiv" onClick={() => navigateDetail()}>
      <div className="card-body">
        <p className='font-befa' style={{marginTop:'-20px', fontWeight:'600', fontSize:'16px'}}>{title}</p>
        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
          <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#0296D2', marginRight: '2px', margin:'0'}}>{division_name}</p>
          <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#737E86', marginLeft: '2px', margin:'0'}}>{location_name}</p>
        </div>
        <div className="job-action">
          <p style={{ textTransform: 'uppercase' }} className="job-type font-befa">{job_type_name}</p>
          <button onClick={(e)=>navigateApply(e)} className="btn btn-primary font-befa btnHover" style={{backgroundColor:"#0296D2", fontWeight:'600'}}>Apply Now</button>
        </div>
      </div>
    </div>
  );

  if (orientation === 'portrait')
  return (
    <div className="card-m outerDiv" onClick={() => navigateDetail()}>
      <div className="card-body" >
        <p className='font-befa' style={{marginTop:'-20px', fontWeight:'600', fontSize:'16px'}}>{title}</p>
        <div style={{display:'flex', alignItems:'center', gap:'10px', marginTop:'-10px'}}>
          <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#0296D2', marginRight: '2px', margin:'0'}}>{nameMobile(division_name)}</p>
          <p className='font-befa' style={{fontSize:'12px', fontWeight:'600', color:'#737E86', marginLeft: '2px', margin:'0'}}>{nameMobile(location_name)}</p>
        </div>
      </div>
    </div>
  );
}

function VacancyList({ isAdmin, vacancies, searchTerm }) {
  const [currentPage, setCurrentPage] = useState(1);
  const orientation = useOrientation();
  const itemsPerPage = 8;
  

  const filteredVacancies = vacancies.filter(vacancy => {
    const title = vacancy.title ? vacancy.title.toLowerCase() : '';
    const divisionName = vacancy.division_name ? vacancy.division_name.toLowerCase() : '';
    const locationName = vacancy.location_name ? vacancy.location_name.toLowerCase() : '';
    const jobTypeName = vacancy.job_type_name ? vacancy.job_type_name.toLowerCase() : '';

    const search = searchTerm.toLowerCase();

    return (
      title.includes(search) ||
      divisionName.includes(search) ||
      locationName.includes(search) ||
      jobTypeName.includes(search)
    );
  });

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVacancies.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(filteredVacancies.length / itemsPerPage);
  
  // Change page
  const paginate = pageNumber => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    paginate(1);
  }, [vacancies, searchTerm]);

  if (currentItems.length > 0 )
  return (
    <div className="vacancy-list">
      
      {isAdmin ? (
        currentItems.map(vacancy => (
          <JobListingAdmin key={vacancy.id} data={vacancy} />
        ))
      ) : (
        currentItems.map(vacancy => (
          <JobListing
            id={vacancy.id}
            key={vacancy.id}
            title={vacancy.title}
            division_name={vacancy.division_name}
            location_name={vacancy.location_name}
            job_type_name={vacancy.job_type_name}
          />
        ))
      )}
      
      {/* Pagination */}
      <div className="pagination" style={{marginTop:'60px', marginBottom:'60px'}}>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          className="pagination-btn next-page-btn font-befa"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage >= totalPages}
        >
          <span>&gt;</span>
        </button>
      </div>
    </div>
  )
  else if (orientation === "landscape")
  return(
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src="/EmptySearch.png" alt="no vacancies" style={{width: '600px'}} />
    </div>
  ) 
  else if (orientation === "portrait")
    return(
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src="/EmptySearchM.png" alt="no vacancies" style={{width: '80vw'}} />
    </div>
  ) 
}

const cookies = new Cookies();

function CareerPage({state}) {
const navigate = useNavigate();
const MySwal = withReactContent(Swal)
const backgroundImageUrl = process.env.REACT_APP_SELF_PATH_DESTINATION + "dist/img/HeroSection.png";
const [vacancies, setVacancies] = useState([]);
const [searchTerm, setSearchTerm] = useState(''); // Add this line for search term state
const [divId, setDivId] = useState(0);
const [locId, setLocId] = useState(0);
const [typeId, setTypeID] = useState(0);
const [statusId, setStatusID] = useState(state === 'admin'? 2 : 1);
const [filterExpandDiv, setFilterExpandDiv] = useState(false);
const [filterExpandType, setFilterExpandType] = useState(false);
const [filterExpandLoc, setFilterExpandLoc] = useState(false);
const [filterExpandStatus, setFilterExpandStatus] = useState(false);
const [divisionData, setDivisionData] = useState("");
const [locationData, setLocationData] = useState("");
const [typeData, setTypeData] = useState("");
const [divStr, setDivStr] = useState("Function");
const [locStr, setLocStr] = useState("Location");
const [typeStr, setTypeStr] = useState("Type");
const [statusStr, setStatusStr] = useState("Status");
const [adminState, setAdminState] = useState(false);
const [filterExpandMobile, setFilterExpandMobile] = useState(false);
const [overOne, setOverOne] = useState("");
const [OverTwo, setOverTwo] = useState("");
const [bgOne, setBgOne] = useState("");
const [bgTwo, setBgTwo] = useState("");
const [headingStr, setHeadingStr] = useState("");
const [subHeadingStr, setSubHeadingStr] = useState("");
const [firstLanding, setFirstLanding] = useState(0);
const orientation = useOrientation();

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

const handleSearch = () => {
  // Implement your search logic here
  console.log(`Search for ${searchTerm}`);
};

const getData = () => {
  const path = process.env.REACT_APP_PATH_DESTINATION + `/getJobList/${divId}/${locId}/${typeId}/${statusId}` ;

  axios.get(path)
    .then(res => {
      setVacancies(res.data.data);
    })
    .catch(err => {
      console.log(err);
    });

  const path2 = process.env.REACT_APP_PATH_DESTINATION + `/getBanner` ;

  axios.get(path2)
    .then(res => {
      setBgOne(res.data.data.background)
      setBgTwo(res.data.data.background_overlay)
      setOverOne(res.data.data.photo_one)
      setOverTwo(res.data.data.photo_two)
      setHeadingStr(res.data.data.heading)
      setSubHeadingStr(res.data.data.sub_heading)
      setFirstLanding(firstLanding + 1)
    })
    .catch(err => {
      console.log(err);
    });
};

useEffect(() => {
  getData();
}, [divId, locId, typeId, statusId]);

useEffect(() => {
  setFirstLanding(0);
}, []);

const animationVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
};

const filterClickedDiv = () => {
  setFilterExpandDiv(!filterExpandDiv)
  setFilterExpandType(false)
  setFilterExpandLoc(false)
  setFilterExpandStatus(false)
}

const filterClickedType = () => {
  setFilterExpandType(!filterExpandType)
  setFilterExpandDiv(false)
  setFilterExpandLoc(false)
  setFilterExpandStatus(false)
}

const filterClickedLoc = () => {
  setFilterExpandLoc(!filterExpandLoc)
  setFilterExpandType(false)
  setFilterExpandDiv(false)
  setFilterExpandStatus(false)
}

const filterClickedStatus = () => {
  setFilterExpandStatus(!filterExpandStatus)
  setFilterExpandLoc(false)
  setFilterExpandType(false)
  setFilterExpandDiv(false)
}

const typeChoose = (id,str) => {
  setTypeID(id)
  setFilterExpandType(false)
  setTypeStr(str)
  setFilterExpandMobile(false);
}

const divChoose = (id,str) => {
  setDivId(id)
  setFilterExpandDiv(false)
  setDivStr(str)
  setFilterExpandMobile(false);
}

const locChoose = (id,str) => {
  setLocId(id)
  setFilterExpandLoc(false)
  setLocStr(str)
  setFilterExpandMobile(false);
}

const statusChoose = (id,str) => {
  setStatusID(id)
  setFilterExpandStatus(false)
  setStatusStr(str)
  setFilterExpandMobile(false);
}

const filterClickedMobile = () => {
  setFilterExpandMobile(!filterExpandMobile);
  setFilterExpandDiv(false)
  setFilterExpandType(false)
  setFilterExpandLoc(false)
  setFilterExpandStatus(false)
}

const getDropdownData = () => {
  let path = process.env.REACT_APP_PATH_DESTINATION + `/getDivision`;   
  axios.get(path)
  .then(res => { console.log(res.data.data); if (res.data.status = "200"){setDivisionData(res.data.data); } })
  .catch(err => { console.log(err); })

  let path2 = process.env.REACT_APP_PATH_DESTINATION + `/getLocation`;   
  axios.get(path2)
  .then(res => {  console.log(res.data.data); if (res.data.status = "200"){setLocationData(res.data.data); } })
  .catch(err => { console.log(err); })

  let path3 = process.env.REACT_APP_PATH_DESTINATION + `/getJobType`;   
  axios.get(path3)
  .then(res => {  console.log(res.data.data); if (res.data.status = "200"){setTypeData(res.data.data); } })
  .catch(err => { console.log(err); })
}

useEffect(() => {
  getDropdownData();
  if (state === "admin") {
    setAdminState(true);
    setStatusID(2);
  }
}, [state]);

const navigateAddNewJob = ()=> {
  navigate('/admin/jobAdd')
}

const downloadAllCand = ()=> {
  MySwal.fire({
      title: 'Processing Your Request...',
      text: 'Please wait while we process your request.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
          MySwal.showLoading();
      }
  });
  const path = process.env.REACT_APP_PATH_DESTINATION + `/getAllCandExcelExport` ;
  axios.get(path)
    .then(res => {
      console.log(res.data)
      if (res.data.status == "200") {
        // Trigger file download
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', ''); // Setting download attribute
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Download will start shortly. Or <a href = {res.data.data} target="_blank">click here</a> if it doesnt start</p>
        })
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: `Failed to download. ${res.data.message}`,
          willClose: () => {
            getData();
          }
        });
      }
    })
    .catch(err => {
      console.log(err);
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <p>Something went wrong! {err}</p>
      })
  });
}
const controls = useAnimation();
useEffect(() => {
  // Sequence: animate text first, then images simultaneously
  const sequence = async () => {
    await controls.start('visibleText');
    controls.start('visibleImage1');
    controls.start('visibleImage2');
  };
  sequence();
}, [controls]);
const textVariants = {
  hidden: { opacity: 0, y: -100 },
  visibleText: {
    opacity: 1,
    y: -50,
    transition: { duration: 1 },
  },
};
const image1Variants = {
  hidden: { opacity: 0, y: -170 },
  visibleImage1: {
    opacity: 1,
    y: -120,
    transition: { 
      duration: 0.5,
      type: 'spring',
      stiffness: 50,
      damping: 10,
      bounce:0
    },
  },
};

const image2Variants = {
  hidden: { opacity: 0, y: -70 },
  visibleImage2: {
    opacity: 1,
    y: -120,
    transition: { 
      duration: 0.5,
      type: 'spring',
      stiffness: 50,
      damping: 10,
      bounce:0
    },
  },
};

const controlsTwo = useAnimation();

useEffect(() => {
  controlsTwo.start({
    opacity: 1,
    transition: { duration: 1 },
  });
}, [controlsTwo]);
  
if (orientation === 'landscape' || adminState === true)
return (
    <div className="">
        <header className="fixed-header">
          <CareerHeader /> 
        </header>
        <main>
        <section className="hero-section">
          <div className="hero-content">
            <div className="shadow-box"></div>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <motion.img
            src={process.env.REACT_APP_BANNER_DESTINATION + bgOne}
            alt="BeFa Industrial Estate Logo"
            className="background-img"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
          <motion.img
            src={process.env.REACT_APP_BANNER_DESTINATION + bgTwo}
            alt="BeFa Industrial Estate Logo"
            className="background-img"
            initial={{ opacity: 0 }}
            animate={controlsTwo}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
        </div>
            {/* Animation 2 */}
            <motion.div
              className="overlay-box"
              initial="hidden"
              animate={controls}
              variants={image1Variants}
            ></motion.div>
            <motion.img
              src={process.env.REACT_APP_BANNER_DESTINATION + overOne}
              alt="Over1"
              className="overlay-img"
              initial="hidden"
              animate={controls}
              variants={image1Variants}
            />

            {/* Animation 3 */}
            <motion.div
              className="overlay-box2"
              initial="hidden"
              animate={controls}
              variants={image2Variants}
            ></motion.div>
            <motion.img
              src={process.env.REACT_APP_BANNER_DESTINATION + OverTwo}
              alt="Over2"
              className="overlay-img2"
              initial="hidden"
              animate={controls}
              variants={image2Variants}
            />
            {/* Animation 1 */}
            <motion.h1
              className="font-befa"
              style={{ fontWeight: '700', fontSize: '48px', maxWidth:'50vw',marginBottom:'100px' }}
              initial="hidden"
              animate={controls}
              variants={textVariants}
            >
              {headingStr} <br/>
              <span
              className="font-befa"
              style={{ fontWeight: '600', fontSize: '14px',
                lineHeight: '2', // Adjust line height as needed
                display: 'inline-block', // This can help with layout issues
                margin: 0, // Reset margin
                padding: 0 // Reset padding
                }}

              >
                
                {subHeadingStr}
              </span>
            </motion.h1>
          </div>
        </section>

        <div className="vacancy-list-container">
          <div className="semi-circle-container">
            <img src="/dist/img/OrnamentCircle.png" alt="semi-circle" className="semi-circle" />
          </div>
          {adminState && (<>
            <a className="edit-link" href="/admin/editHeroSection" >Edit Hero Section</a>
            <br/><br/>
          </>)}
          <h1 className="title">Vacancy Lists</h1>
          <p className="subtitle">Explore exciting career opportunities</p>
          {adminState && (<>
            <br/><br/>
            <div className='row'>
            <a style={{marginRight:'30px'}} onClick={()=>navigateAddNewJob()} className="new-job-button cursorPointer" ><span><Plus style={{height:'24px'}} /> New Job Openings</span></a>
            <a style={{backgroundColor:''}} onClick={()=>downloadAllCand()} className="dl-all-cand-button cursorPointer" ><span><DownloadCircle style={{height:'24px'}} /> All Candidate List</span></a>
            </div>
          </>)}  
        </div>

        <section className="vacancy-list-section">
        {!adminState && (<>
          <br></br>
          </>)}  
        <div className="filters">
          <button className="filter-button" onClick={filterClickedDiv}><Group className='filter-icon'/> {divStr} <NavArrowDown style={{marginLeft:'24px'}}/></button>
          <div className="vertical-line" style={{marginRight:'16px'}} ></div>
          <button className="filter-button" onClick={filterClickedLoc}><PinAlt className='filter-icon'/> {locStr} <NavArrowDown style={{marginLeft:'24px'}}/></button>
          <div className="gap">
          <div className="vertical-line" style={{marginRight:'16px'}}></div>
          </div>
          <button className="filter-button" onClick={filterClickedType}><Group className='filter-icon'/> {typeStr} <NavArrowDown style={{marginLeft:'24px'}}/></button>
          <div className="gap">
          <div className="vertical-line" style={{marginRight:'16px'}}></div>
          </div>
          {adminState && (<>
            <button className="filter-button" onClick={filterClickedStatus}><StatsUpSquare className='filter-icon'/> {statusStr} <NavArrowDown style={{marginLeft:'24px'}}/></button>
            <div className="gap">
            <div className="vertical-line" style={{marginRight:'16px'}}></div>
            </div>
          </>)}
          <div className="search-bar font-befa">
          <Search className='font-befa filter-icon'/>
            <input className="font-befa" type="text" placeholder="Keyword" value={searchTerm} onChange={handleSearchChange} />
            <button style={{fontWeight:'600'}} className="search-button font-befa btnHover" onClick={handleSearch}> Search</button>
          </div>
        </div>
        <div>
          {filterExpandDiv && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: '0.5vw',
                marginLeft: '10px',
                height: `auto`,
                width: 'auto',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              className='font-befa'
              initial="hidden"
              animate={filterExpandDiv ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => divChoose(0, "Function")}>
                  All
                </div>
              </div>
              {divisionData && divisionData.map((item) => (
              <div style={{marginTop:'0.5vw'}} key={item.id} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => divChoose(item.id, item.division_name)}>
                  {item.division_name}
                </div>
              </div>
            ))}
            </motion.div>
            </>
          )}
        </div>

        <div>
          {filterExpandType && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: '0.5vw',
                marginLeft: '390px',
                height: `auto`,
                width: 'auto',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              className='font-befa'
              initial="hidden"
              animate={filterExpandType ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
            <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
              <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => typeChoose(0,"Type")}>
                All
              </div>
            </div>
            {typeData && typeData.map((item) => (
              <div style={{marginTop:'0.5vw'}} key={item.id} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => typeChoose(item.id, item.job_type_name)}>
                  {item.job_type_name}
                </div>
              </div>
            ))}
            </motion.div>
            </>
          )}
        </div>

        <div>
          {filterExpandLoc && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: '0.5vw',
                marginLeft: '210px',
                height: `auto`,
                width: 'auto',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              className='font-befa'
              initial="hidden"
              animate={filterExpandLoc ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => locChoose(0,"Location")}>
                  All
                </div>
              </div>
              {locationData && locationData.map((item) => (
              <div style={{marginTop:'0.5vw'}} key={item.id} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => locChoose(item.id,item.location_name)}>
                  {item.location_name}
                </div>
              </div>
            ))}
            </motion.div>
            </>
          )}
        </div>
        <div>
          {filterExpandStatus && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: '0.5vw',
                marginLeft: '580px',
                height: `auto`,
                width: 'auto',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              className='font-befa'
              initial="hidden"
              animate={filterExpandStatus ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => statusChoose(2,"Status")}>
                  All
                </div>
              </div>
              <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => statusChoose(1,"Active")}>
                  Active
                </div>
              </div>
              <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => statusChoose(0,"Inactive")}>
                  Inactive
                </div>
              </div>
              {adminState && (
                <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'auto', display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}} className="tenantTicketDiv cursorPointer" onClick={() => statusChoose(3,"Deleted")}>
                  Deleted
                </div>
              </div>
              )}
            </motion.div>
            </>
          )}
        </div>
        <br></br>
        <br></br>
          <div className="vacancy-list">
            {vacancies.length > 0 ?  (
              <VacancyList isAdmin={adminState} vacancies={vacancies} searchTerm={searchTerm} />
            ) : (<>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <img src={firstLanding <= 1? "/EmptyState.png" : "EmptySearch.png"}  alt="no vacancies" style={{width:'600px'}} />
              </div>
              <br/><br/><br/>
            </>
            )}
          </div>
        </section>
      </main>
      <FooterPage />
    </div>
);

if (orientation === 'portrait' && adminState === false)
  return (
      <div style={{maxWidth:'100vw',overflowX:'hidden'}}>
          <header className="">
          <div className="header-m" style={{marginTop:'-0px'}}>
            <div className="center">
              <h1 className="font-befa">Career at</h1>
              <h1></h1>
              <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
            </div>
          </div>
          </header>
          <main>
          <section className="hero-section">
            <div className="hero-content-m">
              <div className="shadow-box"></div>
              <div style={{ position: 'relative', width: '100%', height: '150%' }}>
                <motion.img
                  src={process.env.REACT_APP_SELF_PATH_DESTINATION + "mobilebg1.png"}
                  alt="BeFa Industrial Estate Logo"
                  className="background-img"
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                />
                <motion.img
                  src={process.env.REACT_APP_SELF_PATH_DESTINATION + "mobilebg2.png"}
                  alt="BeFa Industrial Estate Logo"
                  className="background-img"
                  initial={{ opacity: 0 }}
                  animate={controlsTwo}
                />
              </div>
              {/* Animation 2 */}
              <motion.div
                className="overlay-box"
                initial="hidden"
                animate={controls}
                variants={image1Variants}
              ></motion.div>
              <motion.img
                src={process.env.REACT_APP_BANNER_DESTINATION + overOne}
                alt="Over1"
                className="overlay-img"
                initial="hidden"
                animate={controls}
                variants={image1Variants}
              />
  
              {/* Animation 3 */}
              <motion.div
                className="overlay-box2"
                initial="hidden"
                animate={controls}
                variants={image2Variants}
              ></motion.div>
              <motion.img
                src={process.env.REACT_APP_BANNER_DESTINATION + OverTwo}
                alt="Over2"
                className="overlay-img2"
                initial="hidden"
                animate={controls}
                variants={image2Variants}
              />
              {/* Animation 1 */}
              <motion.h1
                className="font-befa"
                style={{ fontWeight: '700', fontSize: '28px', maxWidth:'100vw',marginBottom:'50px' }}
                initial="hidden"
                animate={controls}
                variants={textVariants}
              >
                {headingStr} <br/>
                <span
                className="font-befa"
                style={{ fontWeight: '600', fontSize: '14px',
                  lineHeight: '2', // Adjust line height as needed
                  display: 'inline-block', // This can help with layout issues
                  margin: 0, // Reset margin
                  padding: 0 // Reset padding
                  }}
                >
                  {subHeadingStr}
                </span>
              </motion.h1>
            </div>
          </section>
  
          <div className="vacancy-list-container">
            <div className="semi-circle-container" >
              <img style={{height:'100px', width:'auto', marginTop:'-40px'}} src="/dist/img/OrnamentCircle.png" alt="semi-circle" className="semi-circle" />
            </div>
            <h1 className="title">Vacancy Lists</h1>
            <p className="subtitle">Explore exciting career opportunithbhhies</p>
          </div>
  
          <section className="vacancy-list-section-m">
            <div className="row">
              <div className="filters-m2"> 
              <FilterAlt onClick={filterClickedMobile} className='filter-button-mm' />
              </div>
            
              <div className="filters-m">
                <div className="search-bar">
                <Search className='filter-icon'/>
                  <input className="font-befa"  type="text" placeholder="Keyword" value={searchTerm} onChange={handleSearchChange} />
                </div>
              </div>
            </div>
          
            <div>
            {filterExpandMobile && (
              <>
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0.5vw',
                  marginLeft: '10px',
                  height: `auto`,
                  width: 'auto',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F' 
                }}
                initial="hidden"
                animate={filterExpandMobile ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className="cursorPointer" onClick={() => filterClickedDiv()}>
                    Function
                  </div>
                </div>
                <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className="cursorPointer" onClick={() => filterClickedLoc()}>
                    Location
                  </div>
                </div>
                <div style={{marginTop:'0.5vw'}} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className="cursorPointer" onClick={() => filterClickedType()}>
                    Type
                  </div>
                </div>
              </motion.div>
              </>
            )}
          </div>
          <div>
            {filterExpandDiv && (
              <>
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0.5vw',
                  marginLeft: '110px',
                  height: `auto`,
                  width: 'auto',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F' 
                }}
                initial="hidden"
                animate={filterExpandDiv ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div style={{marginTop:'0.5vw', background: divId == 0?'#E8E9EB':''}} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black' }} className="cursorPointer" onClick={() => divChoose(0, "Function")}>
                    All
                  </div>
                </div>
                {divisionData && divisionData.map((item) => (
                <div style={{marginTop:'0.5vw', background: divId == item.id?'#E8E9EB':''}} key={item.id} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className="cursorPointer" onClick={() => divChoose(item.id, item.division_name)}>
                    {item.division_name}
                  </div>
                </div>
              ))}
              </motion.div>
              </>
            )}
          </div>
  
          <div>
            {filterExpandType && (
              <>
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0.5vw',
                  marginLeft: '110px',
                  height: `auto`,
                  width: 'auto',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F' 
                }}
                initial="hidden"
                animate={filterExpandType ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
              <div style={{marginTop:'0.5vw', background: typeId == 0?'#E8E9EB':''}} className="outerTenantDiv" >
                <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className=" cursorPointer" onClick={() => typeChoose(0,"Type")}>
                  All
                </div>
              </div>
              {typeData && typeData.map((item) => (
                <div style={{marginTop:'0.5vw', background: typeId == item.id?'#E8E9EB':''}} key={item.id} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}} className="cursorPointer" onClick={() => typeChoose(item.id, item.job_type_name)}>
                    {item.job_type_name}
                  </div>
                </div>
              ))}
              </motion.div>
              </>
            )}
          </div>
  
          <div>
            {filterExpandLoc && (
              <>
              <motion.div
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  marginTop: '0.5vw',
                  marginLeft: '110px',
                  height: `auto`,
                  width: 'auto',
                  borderRadius: '8px',
                  zIndex: 5,
                  border: '0px solid black',
                  boxShadow: '0px 4px 40px 0px #0000001F' 
                }}
                initial="hidden"
                animate={filterExpandLoc ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div style={{marginTop:'0.5vw', background: locId == 0?'#E8E9EB':''}} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}}  className="cursorPointer" onClick={() => locChoose(0,"Location")}>
                    All
                  </div>
                </div>
                {locationData && locationData.map((item) => (
                <div style={{marginTop:'0.5vw', background: locId == item.id?'#E8E9EB':''}} key={item.id} className="outerTenantDiv" >
                  <div style={{ width:'auto',height:'50px', display: 'flex', justifyContent: 'space-between',margin:'10px', padding:'10px',border:'0px solid black'}}  className="cursorPointer" onClick={() => locChoose(item.id,item.location_name)}>
                    {item.location_name}
                  </div>
                </div>
              ))}
              </motion.div>
              </>
            )}
          </div>
          <br></br>
          <br></br>
            <div className="vacancy-list">
              {vacancies.length > 0 ?  (
                <VacancyList isAdmin={adminState} vacancies={vacancies} searchTerm={searchTerm} />
              ) : (<>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   <img src={firstLanding <= 1? "/EmptyStateM.png" : "EmptySearchM.png"}  alt="no vacancies" style={{width:'80vw'}} />
                </div>
                <br/><br/><br/>
              </>
              )}
            </div>
          </section>
        </main>
        <FooterPage />
      </div>
  );
};

export default CareerPage;
