import React, { useEffect, useState } from 'react'
import axios from 'axios' ;import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import CareerHeader from './CareerHeader';
import {Edit} from 'iconoir-react';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';

const cookies = new Cookies();
const tableCand = {
    minWidth: '80vw',
    borderCollapse: 'collapse'
};
    
const thtdCand = {
    border: "1px solid #ddd",
    padding: "8px"
};
function SinglePageTable() {
  const MySwal = withReactContent(Swal)
  const {tableName,tableField} = useParams();
  const [data, setData] = useState([]);
  const [tableString, setTableString] = useState("");
  useEffect(() => {
    getData();
    if (tableName === "division") {setTableString("Function")}
    if (tableName === "location") {setTableString("Location")}
    if (tableName === "jobtype") {setTableString("Job Type")}
  },[])

  const addClicked = () => {
    MySwal.fire({
      title: <strong>Add {tableString}</strong>,
      html: (
        <div>
          <label htmlFor="name-input">Name:</label>
          <input id="name-input" className="swal2-input" placeholder="Enter name" />
        </div>
      ),
      showCancelButton: true,
      confirmButtonText: 'Add',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const inputValue = document.getElementById('name-input').value;
        return inputValue;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + `/add${tableName}`;
        const config = { headers: { 'x-access-token': sessionToken } };
        const data = { data: result.value};  
        axios.post(path, data, config)
        .then(res => {
            //console.log(res.data.data);
            if (res.data.status === "200") {
              window.location.reload(true);
            } else {
              MySwal.fire({
                title: <strong>Error</strong>,
                html: <p>{res.data.message}</p>
              })
            }
        })
        .catch(err => {
            console.log(err);
        });
      }
    });
  };

  const editClicked = (id, name) => {
    MySwal.fire({
      title: <strong>Edit {tableString}</strong>,
      html: (
        <div>
          <label htmlFor="name-input">Name:</label>
          <input id="name-input" className="swal2-input" defaultValue={name} />
        </div>
      ),
      showCancelButton: true,
      confirmButtonText: 'Edit',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const inputValue = document.getElementById('name-input').value;
        return inputValue;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + `/edit${tableName}`;
        const config = { headers: { 'x-access-token': sessionToken } };
        const data = { data: result.value, id:id};  
        axios.post(path, data, config)
        .then(res => {
            //console.log(res.data.data);
            if (res.data.status === "200") {
              window.location.reload(true);
            } else {
              MySwal.fire({
                title: <strong>Error</strong>,
                html: <p>{res.data.message}</p>
              })
            }
        })
        .catch(err => {
            console.log(err);
        });
      }
    });
  };
  const getData = () => {
    var path = process.env.REACT_APP_PATH_DESTINATION + `/get${tableName}`;   
    axios.get(path)
    .then(res => {
      if (res.data.status = "200"){
        setData(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  return (<>
  <header className="fixed-header">
    <CareerHeader/> 
  </header>
  <div style={{marginTop:'100px', marginLeft:'10%'}}>
    <h3>Table {tableString}</h3>
    <button style={{  }} className='btn btn-primary' onClick={() => addClicked()}>Add New</button>
    <br/><br/>
  <table style={tableCand} >
    <thead >
        <tr style={{ backgroundColor: '#E8E9EB' }}>
        <th style={thtdCand}>No</th>
        <th style={thtdCand}>Details</th>
        <th style={thtdCand}>Edit</th>
        </tr>
    </thead>
    <tbody>
        {data && data.length > 0 && data.map((row, index) => (
        <tr key={index} className={index % 2 === 0 ? 'tableWhite tableHover' : 'tableGray tableHover' }>
            <td style={thtdCand}> {index + 1} </td>
            <td style={thtdCand}>{row[tableField]}</td>
            <td onClick={() => editClicked(row.id,row[tableField])} style={thtdCand}> <Edit className='cursorPointer'/> </td>
        </tr>
        ))}
    </tbody>
  </table>
  </div>
</>)
}

export default SinglePageTable