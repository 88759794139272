import React, { useState, useEffect } from 'react';
import { NavArrowUp, NavArrowDown } from 'iconoir-react';
import {useNavigate } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { BrowserView, MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();

function TicketsByToMe({ header, link, arrowShow = false, arrowUpFunction, arrowDownFunction }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [widthCard, setWidthCard] = useState("");
  const [lotsCard, setLotsCard] = useState(true);
  const navigate = useNavigate();
  const orientation = useOrientation();

  useEffect(() => {
    getData();
  }, [link]);

  const ticketClick = (item) => {
    console.log("link",link);
    console.log("item",item);

    if (item.status_name == "Overdue"){
      item.status_name = "On Progress"
      item.status_id = 2
    }

    if (link === "/ticketToMe"){
      //navigate('/ticketList/to/' + item.status_id + "/" + item.status_name)
      window.location.href='/ticketList/to/' + item.status_id + "/" + item.status_name;
    } else if (link === "/ticketByMe"){
      //navigate('/ticketList/by/' + item.id+ "/" + item.status_name)
      window.location.href='/ticketList/by/' + item.id+ "/" + item.status_name;
    }
  }

  const getData = () => {
    setLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    //console.log("session token = " + sessionToken);
    var path = process.env.REACT_APP_PATH_DESTINATION + link;
    const config = { headers: { ['x-access-token']: sessionToken } };
    axios.get(path, config)
      .then(res => {
        console.log("tickets to me by me logging", res.data.data)
        setData(res.data.data);
        if (res.data.data.length <= 4 ){
          setWidthCard("18vw");
          setLotsCard(false);
        } else {
          setLotsCard(true);
        }
        // Introduce a delay of at least 0.5 seconds
        setTimeout(() => {
          setLoading(false);
        }, 250);
        if (res.data.status === "200") {
          // console.log("success stats 200");
        }
      })
      .catch(err => {
        console.log(err);
        // Ensure the loading spinner stays visible for at least 0.5 seconds on error
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  }
  var mrgnLeft = arrowShow ? "" : "-2vw" ;

  if (orientation === 'landscape')
  return (
  <>
  <>
    <div
      style={{
        position: "relative",
        background: `url("dist/img/dashboardCard.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "10vw",
        width: "98%",
        opacity: "1",
        marginTop: "1.5vw",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "15px",
        overflow: "hidden", // Hide overflowing content
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999, // Ensure it's on top
          }}
        >
          <div className="loading-spinner">Loading...</div>
        </div>
      )}

      <p className="textBlueDashboardHeader" style={{ position: "absolute", top: "1vw", left: "7.3vw", transform: "translateX(-50%)", marginLeft:mrgnLeft,fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}>
        <b>{header}</b>
        {arrowShow && (
          <>
            <NavArrowUp className="circleArrowDB" onClick={arrowUpFunction} />
            <NavArrowDown className="circleArrowDB" onClick={arrowDownFunction} />
          </>
        )}
      </p>
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "space-between",
        width: "98%",
        borderRadius: "12px",
        marginTop: "1vw",
      }}>
        {data.map((item, index) => (
          <div onClick={() => ticketClick(item)} key={'ttm' + index} className="button buttonBlueDashboard cursorPointer" style={{width:widthCard}}> 
            <p className="textBlueDashboardHeader" style={{ marginTop: '-0.2vw',fontSize:'1vw' ,fontFamily:'Blender Pro'}}>{item.status_name}</p>
            <b className="numberBlueDashboardHeader">{item.ticket_count}</b>
          </div>
        ))}
      </div>
    </div>
    </>

  </>
  );

  if (orientation === 'portrait')
  return (
    <>
    <div
      style={{
        position: "relative",
        background: `url("dist/img/dashboardCard.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "13vh",
        width: "95%",
        opacity: "1",
        marginTop: "2vh",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "15px",
        overflow: "hidden", // Hide overflowing content
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999, // Ensure it's on top
          }}
        >
          <div className="loading-spinner">Loading...</div>
        </div>
      )}

      <p className="textBlueDashboardHeader" style={{ position: "absolute", top: "1.2vh", marginLeft:'2vw',fontFamily:'Blender Pro Book', fontSize:'2.5vh'}}>
        <b>{header}</b>
        {arrowShow && (
          <>
            <NavArrowUp className="circleArrowDBMobile" onClick={arrowUpFunction} style={{marginTop:'-1vh'}}/>
            <NavArrowDown className="circleArrowDBMobile" onClick={arrowDownFunction}  style={{marginTop:'-1vh'}}/>
          </>
        )}
      </p>
      
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "space-between",
        width: "98%",
        borderRadius: "12px",
        marginTop: "2vh",
      }}>
        {data.map((item, index) => (
          <div onClick={() => ticketClick(item)} key={'ttm' + index} className="button buttonBlueDashboardMobile cursorPointer" style={{width:lotsCard? "14.5vw":"21vw", marginLeft:lotsCard?"0vw":"1vw", marginRight:lotsCard?"0vw":"1vw"}}> 
            <p className="textBlueDashboardHeaderMobile" style={{ marginTop: '0vh',fontFamily:'Blender Pro', fontSize:lotsCard? '1.25vh' : '1.6vh'}}>{item.status_name}</p>
            <b className="numberBlueDashboardHeaderMobile" style={{ marginTop: '0vh',fontFamily:'Blender Pro'}}>{item.ticket_count}</b>
          </div>
        ))}
      </div>
    </div>
    </>)
}

export default TicketsByToMe;
