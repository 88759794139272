import React from 'react'; import { useState, useEffect} from 'react';
import { AnimatePresence, motion } from "framer-motion"
import ContentHeader from '../../content/ContentHeader';
import axios from 'axios' 
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import TicketsByToMe from './TicketsByToMe';
import TypeOfIssueParent from './TypeOfIssueParent';
import TypeOfIssueExtParent from './TypeOfIssueExtParent';
import LatestActivityParent from './LatestActivityParent';
import AnnouncementParent from './AnnouncementParent';
import UrgentTicketParent from './UrgentTicketParent';
import UrgentTicketExtParent from './UrgentTicketExtParent';
import FAQDashboardParent from './FAQDashboardParent';
import { BrowserView, MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
function DashboardTwo({changeNavState}) {
  const orientation = useOrientation();
  const sessionData = cookies.get('sessionData', { path: '/' })
  const int_ext = sessionData.int_ext;
  //alert("internal external = " + int_ext);
  //console.log("sessiondata",sessionData)
  useEffect(() => {
    getData();
    changeNavState('Dashboard');
  }, [])

  const getData = () => {
    
  }   

  const IssueLinkStates = ['/typeofIssuesByMe', '/typeofIssuesToMe'];
  const [currentIssueLinkIndex, setCurrentIssueLinkIndex] = useState(0);
  const IssuelinkPath = IssueLinkStates[currentIssueLinkIndex];
  const IssuesByMe = () => {
    //alert("issue by me parent");
    setCurrentIssueLinkIndex((prevIndex) => (prevIndex + 1) % ticketLinkStates.length);
  }

  const IssuesToMe = () => {
    //alert("issue to me parent");
    setCurrentIssueLinkIndex((prevIndex) => (prevIndex + 1) % ticketLinkStates.length);
  }

  const urgentLinkStates = ['/urgentTicketByMe', '/urgentTicketToMe'];
  const [currentUrgentLinkIndex, setCurrentUrgentLinkIndex] = useState(0);
  const UrgentlinkPath = urgentLinkStates[currentUrgentLinkIndex];
  const UrgentByMe = () => {
    //alert("issue by me parent");
    setCurrentUrgentLinkIndex((prevIndex) => (prevIndex + 1) % urgentLinkStates.length);
  }

  const UrgentToMe = () => {
    //alert("issue to me parent");
    setCurrentUrgentLinkIndex((prevIndex) => (prevIndex + 1) % urgentLinkStates.length);
  }

  const ticketStates = ['Tickets By Me', 'Tickets To Me'];
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
  const ticketLinkStates = ['/ticketByMe', '/ticketToMe'];
  const [currentLinkTicketIndex, setCurrentLinkTicketIndex] = useState(0);

  const toggleTickets = () => {
    setCurrentTicketIndex((prevIndex) => (prevIndex + 1) % ticketStates.length);
    setCurrentLinkTicketIndex((prevIndex) => (prevIndex + 1) % ticketLinkStates.length);
  };

  const headerText = ticketStates[currentTicketIndex];
  const linkPath = ticketLinkStates[currentLinkTicketIndex];


  const arrowUp = () => {
    toggleTickets();
  }

  const arrowDown = () => {
    toggleTickets();
  }

  const [theme, setTheme] = useState('default'); // Initialize with your default theme
  const changeTheme = (newTheme) => {
    const publicUrl = process.env.REACT_APP_SELF_PATH_DESTINATION;
    setTheme(newTheme);
    const themeLink = document.getElementById('theme-link');
    
    themeLink.setAttribute('href', `${publicUrl}themes/${newTheme}.css`);
  };

  
  const duration = 1;
  const type = "ease-in"

  if (orientation === 'landscape')
  return (
<>
<section className="content" style={{marginLeft: "-2vw"}}>
    <div className="container-fluid">
        {int_ext == 1 && (
          <TicketsByToMe 
            header={headerText}
            link={linkPath}
            arrowShow={true}
            arrowUpFunction={arrowUp}
            arrowDownFunction={arrowDown}
          />
        )}
        {int_ext == 2 && (
          <TicketsByToMe 
            header={headerText}
            link={linkPath}
            arrowShow={false}
            arrowUpFunction={arrowUp}
            arrowDownFunction={arrowDown}
          />
        )}

        
      <br/>
      <div className='row mx-auto text-center' style={{width: "100%"}}>
        <div className="col-md-8 text-left h-100"  style={{ width: '100%'}}> 
          <motion.div
            initial={{ x:0, y:150, rotate:0 ,opacity: 0}}
            animate={{ x:0, y:0, rotate:0 ,opacity: 1}}
            transition={{ duration: duration,type: type }}
          > 
          {int_ext == 1 && (
              <TypeOfIssueParent 
                link={IssuelinkPath}
                byMeFunction={IssuesByMe}
                toMeFunction={IssuesToMe}
              />
            )}
            {int_ext == 2 && (
              <TypeOfIssueExtParent 
                  link={IssuelinkPath}
                  byMeFunction={IssuesByMe}
                  toMeFunction={IssuesToMe}
              />
            )}
          
          </motion.div>
          <br/>
          <motion.div
            initial={{ x:0, y:200, rotate:0, opacity: 0 }}
            animate={{ x:0, y:0, rotate:0,opacity: 1 }}
            transition={{ duration:  duration,type: type }}
          > 
            {int_ext == 1 && (
              <FAQDashboardParent link="/faqDashboardInt"/>
            )}
            {int_ext == 2 && (
              <FAQDashboardParent link="/faqDashboardExt" />
            )}
             
          </motion.div>
        </div>
     
      <div className='col-md-4 h-100' style={{}}>
        <motion.div
            initial={{ x:0, y:100, rotate:0 ,opacity: 0}}
            animate={{ x:0, y:0, rotate:0,opacity: 1 }}
            transition={{ duration: duration,type: type }}
        > 
          <AnnouncementParent link="/dashboardAnnounce"/>
        </motion.div>
        <br/>
        <motion.div
            initial={{ x:0, y:50, rotate:0 ,opacity: 0}}
            animate={{ x:0, y:0, rotate:0 ,opacity: 1}}
            transition={{ duration: duration,type: type }}
        >  
        {/*//TODO: get int or ext here based on session data*/}
        {int_ext == 1 && (
          <UrgentTicketParent />
        )}
        {int_ext == 2 && (
          <UrgentTicketExtParent />
        )}
        
        </motion.div>
      </div>
      </div>
    </div>
    <br/>
   </section>
  </>  
  );
else return (
<>
  <section className="content" style={{marginLeft: "-2vw", marginTop:'8vh'}}>
    <div className="container-fluid" >
        {int_ext == 1 && (
          <TicketsByToMe 
            header={headerText}
            link={linkPath}
            arrowShow={true}
            arrowUpFunction={arrowUp}
            arrowDownFunction={arrowDown}
          />
        )}
        {int_ext == 2 && (
          <TicketsByToMe 
            header={headerText}
            link={linkPath}
            arrowShow={false}
            arrowUpFunction={arrowUp}
            arrowDownFunction={arrowDown}
          />
        )}
      <br/>
      <div className='row mx-auto text-center' style={{width: "100%"}}>
        <div className="col-md-12 text-left h-100"  style={{ width: '100%'}}> 
          <motion.div
            initial={{ x:0, y:150, rotate:0 ,opacity: 0}}
            animate={{ x:0, y:0, rotate:0 ,opacity: 1}}
            transition={{ duration: duration,type: type }}
          > 
          {int_ext == 1 && (
              <TypeOfIssueParent 
                link={IssuelinkPath}
                byMeFunction={IssuesByMe}
                toMeFunction={IssuesToMe}
              />
            )}
            {int_ext == 2 && (
              <TypeOfIssueExtParent 
                  link={IssuelinkPath}
                  byMeFunction={IssuesByMe}
                  toMeFunction={IssuesToMe}
              />
            )}
          
          </motion.div>
          <br/>

        </div>
     
      <div className='col-md-12 h-100' style={{}}>
        
          <AnnouncementParent link="/dashboardAnnounce"/>
        
        <br/>
        <motion.div
            initial={{ x:0, y:50, rotate:0 ,opacity: 0}}
            animate={{ x:0, y:0, rotate:0 ,opacity: 1}}
            transition={{ duration: duration,type: type }}
        >  
        {/*//TODO: get int or ext here based on session data*/}
        {int_ext == 1 && (
          <UrgentTicketParent />
        )}
        {int_ext == 2 && (
          <UrgentTicketExtParent />
        )}
        
        </motion.div>
        <br/>
        <motion.div
            initial={{ x:0, y:200, rotate:0, opacity: 0 }}
            animate={{ x:0, y:0, rotate:0,opacity: 1 }}
            transition={{ duration:  duration,type: type }}
          > 
            {int_ext == 1 && (
              <FAQDashboardParent link="/faqDashboardInt"/>
            )}
            {int_ext == 2 && (
              <FAQDashboardParent link="/faqDashboardExt" />
            )}
             
          </motion.div>
      </div>
      </div>
    </div>
    <br/>
  </section>
</>
)
}

export default DashboardTwo;
