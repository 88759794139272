import React from 'react'
import bewarefraud from './BewareFraud.png'; 
import bewarefraudM from './BewareFraudM.png'; 
import './footer.css';  
import useOrientation from '../../general/useOrientation';
function BewareFraud() {
  const orientation = useOrientation();

  if (orientation === 'landscape')
    return (
      <div style={{textAlign:'center'}}>
        <img src={bewarefraud} alt="BewareFraud" style={{width:'77%'}} />
      <br/><br/>
      </div>
    )
  if (orientation === 'portrait')
    return (
    <div style={{textAlign:'center', margin:'-35px 0px -20px 0px'}}>
      <img src={bewarefraudM} alt="BewareFraudMobile" style={{width:'100%'}} />
    <br/><br/>
    </div>
  )
}

export default BewareFraud