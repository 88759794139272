import React from 'react'; import { useState, useEffect} from 'react';
import LatestActivity from './LatestActivity';
import axios from 'axios' 
import Cookies from 'universal-cookie';
import { BrowserView , MobileView} from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function FAQDashboardParent({link}) {
  const orientation = useOrientation();
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [link])

  const gotoFaq = (id) => {
    window.location.href='/faq/' + id
  }   

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + link;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      
      setData(res.data.data);
      if (res.data.status = "200"){
        console.log("this is faq data");
        console.log(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })  
  } 
  if (orientation === 'landscape')
  return (
  <>
  <>
    <div className='borderWithRadius'> 
      <div className="row" style={{marginTop:"1.5vw", marginBottom:'1.25vw'}}>
        <div className="col-md-9">
          <p style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}} className='dashboardMarginLeft'><b>Frequently Asked Questions</b></p>
        </div>
        <div className="col-md-3" style={{textAlign:"right"}}>
          
        </div>
      </div>
      {data.map((item, index) => (
        <img
          className='cursorPointer'
          onClick={() => gotoFaq(item.id)}
          key={"fadq"+index} 
          src={process.env.REACT_APP_FAQ_BG_DESTINATION + item.background} 
          alt={"FAQ " + item.id}
          style={{
            borderRadius: '8px', 
            height: '8vw', 
            width: '15.4vw', 
            marginLeft:'1vw',
          }}
        />
      ))}<div style={{marginBottom : '-0.2vw'}}> &nbsp; </div>
    </div>
  </>
  
  </>
  )
  if (orientation === 'portrait')
  return (
    <>
    <div className='borderWithRadius'> 
      <div className="row" style={{marginTop:"1.5vh", marginBottom:'1.25vh', textAlign:"left",marginLeft:'1vw'}}>
        <div className="col-md-9">
          <p style={{fontFamily:'Blender Pro Book', fontSize:'2.5vh'}} className='dashboardMarginLeft'><b>Frequently Asked Questions</b></p>
        </div>
        <div className="col-md-3" style={{textAlign:"right"}}>
          
        </div>
      </div>
      <div style={{textAlign:'left',marginLeft:'4vw'}}>
      {data.map((item, index) => (
        <img
          className='cursorPointer'
          onClick={() => gotoFaq(item.id)}
          key={"fadq"+index} 
          src={process.env.REACT_APP_FAQ_BG_DESTINATION + item.background} 
          alt={"FAQ " + item.id}
          style={{
            borderRadius: '8px', 
            height: '20vw', 
            marginBottom:'2vh',
            align:'left', 
            marginRight:'5vw'
          }}
        />
      ))}
      </div>
      <div style={{marginBottom : '-1vh'}}> &nbsp; </div>
    </div>
  </>
  )
}

export default FAQDashboardParent