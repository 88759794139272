import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function TimeAgoBland({ time }) {
  const [timeStr, setTimeStr] = useState('');

  const getTimeAgo = (timeDiff) => {
    if (timeDiff < 60) {
      return 'few seconds ago';
    } else if (timeDiff >= 60 && timeDiff < 3600) {
      const mins = Math.floor(timeDiff / 60);
      return mins === 1 ? '1 min ago' : `${mins} mins ago`;
    } else if (timeDiff >= 3600 && timeDiff < 86400) {
      const hours = Math.floor(timeDiff / 3600);
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (timeDiff >= 86400 && timeDiff < 2592000) {
      const days = Math.floor(timeDiff / 86400);
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (timeDiff >= 2592000 && timeDiff < 31536000) {
      const months = Math.floor(timeDiff / 2592000);
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else {
      const years = Math.floor(timeDiff / 31536000);
      return years === 1 ? '1 year ago' : `${years} years ago`;
    }
  };

  useEffect(() => {
    const currentTime = new Date();
    let timeDate;
    try {timeDate = new Date(time);}
    catch {  timeDate = '-'  }

    if (isNaN(timeDate)) {
      setTimeStr('-');
    } else {
      const timeDifference = (currentTime - timeDate) / 1000; // Difference in seconds
      setTimeStr(getTimeAgo(timeDifference));
    }
  }, [time]);

  return (<>
  <BrowserView>
    <div style={{ color: '#0296D2', fontSize:'1vw',marginLeft:'2vw' }}>
      <b>{timeStr}</b>
    </div>
  </BrowserView>
  <MobileView>
    <div style={{ color: '#0296D2', fontSize:'15px',marginLeft:'2vw' }}>
      <b>{timeStr}</b>
    </div>
  </MobileView>
    </>
  );
  
}

export default TimeAgoBland;
