import React, { Component } from 'react'

export class ContentHeader extends Component {
    render() {
    const title = this.props.title;
        return (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0">
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default ContentHeader

