import React, { useEffect, useState } from 'react'
import TimeAgoBland from '../general/TimeAgoBland'
import {Cancel} from 'iconoir-react'
import Cookies from 'universal-cookie';
import axios from 'axios' 
import {useNavigate} from "react-router-dom"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import useOrientation from '../general/useOrientation';

const cookies = new Cookies();

function SingleNotif({data,del,mobileClick}) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  const [unread, setUnread] = useState(true);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  useEffect(() => {
    if (data.read == 1){
      setUnread(false);
    }
    setTitle(`${data.created_by_name} ${data.action} Ticket`)
    setDesc(data.description);
    //readNotif();
  }, [data])

  const redirect = () => {
    navigate(data.link);
  };
  const redirectMobie = () => {
    navigate(data.link);
    mobileClick();
  };

  function readNotif() {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/readNotif/" + data.id;   
    axios.get( dbpath, config)
      .then(res => { 
        if (res.data.status == "200"){

        } else {

        }
      })
  }

  if (orientation === 'landscape')
  return (<>
  <>
    <div style={{backgroundColor: unread ? '#EFFAFF':'white' ,height:'auto', paddingTop:'1vw', paddingBottom:'1vw',borderBottom:'1px solid #E8E9EB', }}>
       <TimeAgoBland time={data.created_at}  />
       <Cancel className="cursorPointer" onClick={() => del(data.id)} style={{marginLeft:'20vw', marginTop:'-3.5vw', height:'2vw'}}/>
        <p onClick={redirect} className="cursorPointer" style={{marginTop:'-1.5vw',marginLeft:'2vw',marginRight:'2vw', fontSize:'1.2vw', fontFamily:'Blender Pro', fontWeight:'500'}}><b>{title}</b></p>
        <p onClick={redirect} className="cursorPointer" style={{marginTop:'-1.5vw',marginLeft:'2vw',marginRight:'2vw', marginTop:'-1vw',fontSize:'1.1vw', fontFamily:'Blender Pro', color:'#404041'}}> {desc} </p>
    </div>
  </>
  </>
  )
  if (orientation === 'portrait')
  return (
    <>
    <div style={{backgroundColor: unread ? '#EFFAFF':'white' ,height:'auto', width:'100%', paddingTop:'2vh', paddingBottom:'2vh',borderBottom:'1px solid #E8E9EB', }}>
      <TimeAgoBland time={data.created_at}  />
      <Cancel className="cursorPointer" onClick={() => del(data.id)} style={{marginLeft:'85vw', marginTop:'-5vh', height:'2vh'}}/>
      <p onClick={redirectMobie} className="cursorPointer" style={{marginTop:'-2vh',marginLeft:'2vw',marginRight:'2vw', fontSize:'2vh', fontFamily:'Blender Pro', fontWeight:'500'}}><b>{title}</b></p>
      <p onClick={redirectMobie} className="cursorPointer" style={{marginTop:'-1.5vh',marginLeft:'2vw',marginRight:'2vw', fontSize:'1.75vh', fontFamily:'Blender Pro', color:'#404041'}}> {desc} </p>
    </div>
  </>
  )
}

export default SingleNotif