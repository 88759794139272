import React from 'react';

function CheckboxInput({ text, checked, setChecked }) {
  return (
    <div className="font-befa" style={{ marginBottom: '20px' }}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        {' '}{text}
      </label>
    </div>
  );
}

export default CheckboxInput;
