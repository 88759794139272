import React from 'react'

function CandidDetHelper({header="",text="",workHere=0}) {
  if (workHere === 1)
  return (<>
    <div style={{marginBottom:'10px'}} className='col-md-4'>
        <h5 className='font-befa'>{header}</h5>
    </div>
    <div className='col-md-8'>
        <p className='font-befa'>Present</p>
    </div>
    </>
  )
  else
    return (<>
      <div style={{marginBottom:'10px'}} className='col-md-4'>
          <h5 className='font-befa'>{header}</h5>
      </div>
      <div className='col-md-8'>
          <p className='font-befa'>{text}</p>
      </div>
      </>
    )
}

export default CandidDetHelper