import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { AddUser } from 'iconoir-react';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();

function CareerHeader() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const orientation = useOrientation();

  useEffect(() => {
    setLoggedIn(cookies.get('sessionExist'));
  }, []);

  let sessionData = cookies.get('sessionData');

  const handleLogout = () => {
    setLoggedIn(false);
    sessionData = null;
    cookies.set('sessionExist', false, { path: '/' });
    cookies.remove('sessionToken');
    cookies.remove('sessionData');
    cookies.remove('sessionExist');
    
    window.location.href="/logout";
  };

  if (loggedIn) {
    return (
      <div className="header">
        <p className="font-befa" style={{position:'absolute', left:'10px',top: '18px'}}>Hi, {sessionData.fullname}</p>
        <div className="center font-befa" style={{ textAlign: 'center' }}>
          <h1 className="font-befa">Career at</h1>
          <h1></h1>
          <img className='cursorPointer' onClick={() => navigate("/admin/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
        </div>
        <AddUser onClick={() => navigate("/admin/userList")} className="cursorPointer" style={{ color:'#0296D2', position: 'absolute', right: '120px', top: '15px', width:'30px', height:'30px' }}/>
        <button 
          onClick={handleLogout} className="btn btnSubmitTicketMobile"
          style={{ position: 'absolute', right: '20px', top: '12px' }}
        >
          <span className='font-befa'>Logout</span>
        </button>
      </div>
    );
  } else if (orientation === 'portrait' ) {
    return (
      <div className="header font-befa" style={{marginTop:'-58px'}}>
        <div className="center">
          <h1 className="font-befa">Career at</h1>
          <h1></h1>
          <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="header">
        <div className="center">
          <h1 className="font-befa">Career at</h1>
          <h1></h1>
          <img className='cursorPointer' onClick={() => navigate("/")} src="/dist/img/logoBefa.png" alt="BeFa Industrial Estate Logo" />
        </div>
      </div>
    );
  }
}

export default CareerHeader;
