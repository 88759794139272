import React from 'react'

function HeaderIMG({link}) {
  return (
    <section className="hero-section">
    <div className="hero-content-job">
        <div class="shadow-box"></div>
        <img src={process.env.REACT_APP_IMG_JOB_DESTINATION + link} alt="Job Career Logo" className="background-img-header" />
    </div>
    </section>
  )
}

export default HeaderIMG