import React, { useEffect, useState } from 'react';

function DateTimeInput({ isEdit = false, text, data, setData, readOnly = false, typeString = "date", defaultVar = false }) {
  // Function to format the date for `datetime-local` input
  const formatDateForInput = (date) => {
    if (!date) return '';
    const d = new Date(date);
    // Format date as 'YYYY-MM-DDTHH:MM'
    const formattedDate = d.getFullYear() + '-' +
                          ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
                          ('0' + d.getDate()).slice(-2) + 'T' +
                          ('0' + d.getHours()).slice(-2) + ':' +
                          ('0' + d.getMinutes()).slice(-2);
    return formattedDate;
  };

  const getToday8AM = () => {
    const d = new Date();
    d.setHours(8, 0, 0, 0); // Set time to 8:00 AM
    return d;
  };

  const [formattedDate, setFormattedDate] = useState(
    defaultVar && typeString === 'datetime-local' ? formatDateForInput(getToday8AM()) : formatDateForInput(data)
  );

  useEffect(() => {
    if (defaultVar && typeString === 'datetime-local') {
      setFormattedDate(formatDateForInput(getToday8AM()));
      setData(formatDateForInput(getToday8AM()));
    } else if (typeString === 'datetime-local') {
      setFormattedDate(formatDateForInput(data));
      setData(formatDateForInput(data))
    } else {
      setFormattedDate(data);
      setData(data);
    }
  }, [data, typeString, defaultVar]);

  if (isEdit === true) { readOnly = true; }

  return (
    <div className="font-befa" style={{ marginBottom: '25px' }}>
      {isEdit === true ? (
        <h5 className="font-befa">{text} (undeditable, edit via change active)</h5>
      ) : (
        <h5 className="font-befa">{text} <span style={{ color: 'red' }}>*</span></h5>
      )}
      <input
        readOnly={readOnly}
        type={typeString}
        className="hdInput font-befa"
        style={{ padding: '10px', width: '92%' }}
        value={formattedDate}
        onChange={(e) => setData(e.target.value)}
      />
    </div>
  );
}

export default DateTimeInput;
