import React, { useEffect, useState } from 'react'
import CareerHeader from '../careerTemplate/CareerHeader'
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';import axios from 'axios' ;
import CandDetInput from '../candidate/CandDetInput';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import FooterPage from '../headerPage/FooterPage';
import DropdownInput from '../careerTemplate/DropdownInput';
import CheckboxInput from '../applyForm/CheckboxInput';
import HtmlInput from '../careerTemplate/HtmlInput';
import DateTimeInput from '../applyForm/DateTimeInput';
import HeaderIMG from './HeaderIMG';
import SinglePageTable from '../careerTemplate/SinglePageTable';
const cookies = new Cookies(); //cookies for the answer, dont forget this TODO
function JobAddEdit({state}) {
    const MySwal = withReactContent(Swal)
    const {jobID} = useParams();
    const [jobPosition, setJobPosition] = useState("");
    const [division, setDivision] = useState("1");
    const [location, setLocation] = useState("1");
    const [type, setType] = useState("1");
    const [divisionData, setDivisionData] = useState("");
    const [locationData, setLocationData] = useState("");
    const [typeData, setTypeData] = useState("");
    const [gpaRequired, setGpaRequired] = useState(false);
    const [responsibilities, setResponsibilitites] = useState("");
    const [qualifications, setQualifications] = useState("");
    const [photo, setPhoto] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [imageLink, setImageLink] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [newBanner, setNewBanner] = useState(true);
    const [editDate, setEditDate] = useState(false);
    
    const navigate = useNavigate();
    function sanitizeInput(input) {
        // Use a regular expression to replace all double quotes with single quotes
        return input.replace(/"/g, "'");
    }

    useEffect(() => {
      if (state === "edit"){
        getEditData();
        setIsEdit(true);
      } else {
        // Get today's date
        const today = new Date();
        // Get the date 30 days from today
        const futureDate = new Date();
        futureDate.setDate(today.getDate() + parseInt(process.env.REACT_APP_RUNNING_DAYS_TO_AUTOCLOSE));

        // Set the formatted dates as default values
        setStartDate(formatDateFromDB(today));
        setEndDate(formatDateFromDB(futureDate));
      }
      getDropdownData();
      window.scrollTo(0, 0);
    }, [])

    const getDropdownData = () => {
        let path = process.env.REACT_APP_PATH_DESTINATION + `/getDivision`;   
        axios.get(path)
        .then(res => { console.log(res.data.data); if (res.data.status = "200"){setDivisionData(res.data.data); } })
        .catch(err => { console.log(err); })

        let path2 = process.env.REACT_APP_PATH_DESTINATION + `/getLocation`;   
        axios.get(path2)
        .then(res => {  console.log(res.data.data); if (res.data.status = "200"){setLocationData(res.data.data); } })
        .catch(err => { console.log(err); })

        let path3 = process.env.REACT_APP_PATH_DESTINATION + `/getJobType`;   
        axios.get(path3)
        .then(res => {  console.log(res.data.data); if (res.data.status = "200"){setTypeData(res.data.data); } })
        .catch(err => { console.log(err); })
    }

    const getEditData = () => {
        var path = process.env.REACT_APP_PATH_DESTINATION + `/getJobListWithID/${jobID}`;   
        axios.get(path)
        .then(res => {
          console.log(res.data.data);
          if (res.data.status = "200"){
            setDivision(res.data.data.division_id);
            setLocation(res.data.data.location_id);
            setType(res.data.data.job_type_id);
            setImageLink(res.data.data.image_link);
            setNewBanner(false);
            setJobPosition(res.data.data.title);
            setStartDate(formatDateFromDB(res.data.data.start_date));
            setEndDate(formatDateFromDB(res.data.data.end_date));
            setResponsibilitites(res.data.data.responsibilities);
            setQualifications(res.data.data.qualifications);
            if (res.data.data.require_gpa == 1){
                setGpaRequired(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
    
    const formatDateFromDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const submitData = () => {
        //confirmation - no empty field
        if (!jobPosition || !division || !location || !type || !responsibilities || !qualifications || (photo.length === 0 && newBanner === true)  ) {
            MySwal.fire({
                title: <strong>Fill all fields</strong>,
                html: <p>Please fill all the required fields</p>
            })
            return;
        }

        
        MySwal.fire({
            title: 'Processing Your Request...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                MySwal.showLoading();
            }
        });

        //sanitize
        const sResponsibilities = sanitizeInput(responsibilities);
        const sQualifications = sanitizeInput(qualifications);

        let gpaRequiredInt = 0;
        if (gpaRequired) { gpaRequiredInt = 1}


        // Create a FormData object
        const formData = new FormData();
        formData.append('jobPosition', jobPosition);
        formData.append('division', division);
        formData.append('location', location);
        formData.append('type', type);
        formData.append('responsibilities', sResponsibilities);
        formData.append('qualifications', sQualifications);
        formData.append('photo', photo);
        formData.append('gpaRequired', gpaRequiredInt);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('addEdit', state);
        formData.append('jobID', jobID);
        formData.append('newBanner', newBanner);

        let addEditLink = "/jobAdd";
        if (isEdit) { addEditLink = "/jobEdit"}

        var path = process.env.REACT_APP_PATH_DESTINATION + addEditLink;   
        axios.post(path,formData)
        .then(res => {
            if (res.data.status == "200"){
                MySwal.fire({
                    title: <strong>Success</strong>,
                    html: <p>Data {isEdit === true? "Edited" :"Added"}</p>
                }).then((result) => {
                    navigate(`/admin`)
                })
            } else {
                MySwal.fire({
                    title: <strong>Error</strong>,
                    html: <p> {res.data.message}</p>
                })
                console.log(res.data);
            }
        })
        .catch(err => {
            console.log(err);
        })
    } 
    
    const handlePhotoChange = (e) => {
        setPhoto(e.target.files[0]);
    };

  return (
  <div className="" style={{maxHeight: '100%', maxWidth:'100vw' }} >
    <div className="">
        <header className="fixed-header">
          <CareerHeader/> 
        </header>
        <div className="center">
            {isEdit === true ? (
                <HeaderIMG link={imageLink}/>
            ) : (
                <HeaderIMG link="Header.png"/>
            )}
          <br/>
          <br/><br/>
          <div className="center" style={{ alignItems: 'flex-start' }} >
            <div className="center" 
                style={{
                    backgroundColor: '#FFFFFF', 
                    backgroundImage: 'url(/CandidateDetailBG.png)', 
                    backgroundPosition: 'top right', 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 150px',
                    width:'70%',
                    marginLeft:'15%',
                    border: '1px solid #E8E9EB',
                    borderRadius:'8px'
                }}
            >   
                <div style={{marginTop:'48px', marginLeft:'80px'}}>
                    <div style={{textAlign:'center'}}>
                        <br/>
                        {!isEdit && (
                            <h2> Add New Job Opening  </h2>
                        )}
                        {isEdit && (
                            <h2> Edit Existing Job Opening  </h2>
                        )}
                        <br/><br/>
                    </div>
                    <div style={{paddingBottom:'20px'}}>
                        <CandDetInput text="Job Position" data={jobPosition} setData={setJobPosition}/>
                        <DropdownInput text="Function" data={divisionData} setDropdown={setDivision} type='1' defValue={division}/>
                        <a href="/admin/singleTable/division/division_name"> Function Edit </a> <br/><br/>
                        <DropdownInput text="Location" data={locationData} setDropdown={setLocation} type='2' defValue={location}/>
                        <a href="/admin/singleTable/location/location_name"> Location Edit </a> <br/><br/>
                        <DropdownInput text="Job Type" data={typeData} setDropdown={setType} type='3' defValue={type}/>
                        <a href="/admin/singleTable/jobtype/job_type_name"> Job Type Edit </a> <br/>
                        <br/>
                        <CheckboxInput text="GPA is required for this position (optional) " checked={gpaRequired} setChecked={setGpaRequired}/>
                        <HtmlInput txt="Responsibilities" desc={responsibilities} setDesc={setResponsibilitites}/>
                        <HtmlInput txt="Qualifications" desc={qualifications} setDesc={setQualifications}/>
                        
                        {editDate === true &&(<>
                            <br/>
                            <DateTimeInput isEdit={isEdit} text="Start Date" data={startDate} setData={setStartDate}/>
                            <DateTimeInput isEdit={isEdit} text="End Date" data={endDate} setData={setEndDate}/>
                            </>
                        )}

                        
                        {isEdit &&(<>
                            <br/>
                            <DateTimeInput isEdit={isEdit} text="Start Date" data={startDate} setData={setStartDate}/>
                            <DateTimeInput isEdit={isEdit} text="End Date - Turn Off The Day After" data={endDate} setData={setEndDate}/>
                            </>
                        )}
                        
                        {newBanner === true ? (<>
                        <br/>
                        <div className="form-group" >
                            <h5>Banner (1280 x 240) <span style={{ color: 'red' }}>*</span></h5>
                            <label style={{backgroundColor:'#F5F5F6', color:'#7B7B7B', padding:'10px 10px 10px 10px'}} htmlFor="photo">Choose File </label> 
                            <br/> {photo && (<p> Chosen: {photo.name} </p>)} <small style={{marginTop:'-20px'}}>File Type: JPG, JPEG, PNG <br/> Max Size: 5 MB</small>
                            <input style={{visibility:"hidden"}} type="file" id="photo" accept=".jpeg, .png, .jpg" onChange={handlePhotoChange} />
                        </div>
                        </>) : (<>
                            <h5>Banner (1280 x 240)</h5>
                            <img style={{width:'50vw',marginTop:'0px',maxHeight:'22.5vh'}}  src={process.env.REACT_APP_IMG_JOB_DESTINATION + imageLink} alt="Job Career Logo" />
                            <button style={{marginLeft:'0px',marginTop: '20px', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicket" type="button" onClick={() => setNewBanner(true)}>
                            Remove and add new banner
                            </button>
                        </>)}
                    </div>
                    <button className="submit-button" onClick={submitData} style={{marginBottom:'40px'}}> Post </button>
                    { (editDate === false && state === "add") && (
                        <button className="submit-button" onClick={() => setEditDate(true)} style={{marginBottom:'40px', marginLeft:'40px'}}> Edit Date </button>
                    )} 
                </div>
            </div>
            <br/><br/>
          </div>
        </div>
    </div>
    <FooterPage />
  </div>
  )
}

export default JobAddEdit