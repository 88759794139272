import React from 'react'; import { useState, useEffect} from 'react';
import axios from 'axios' 
import Cookies from 'universal-cookie';
import UrgentTicketExt from './UrgentTicketExt';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();

function UrgentTicketExtParent() {
    const [data, setData] = useState([]);
    const orientation = useOrientation();
    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + "/urgentTicketByMeExt";   
        const config = {headers:{['x-access-token']: sessionToken}};
        axios.get(path, config)
        .then(res => {
            setData(res.data.data);
            if (res.data.status = "200"){
               console.log(res.data.data);
            }
        })
        .catch(err => {
            console.log(err);
        })
    }  
  
  return (
    <div className='borderWithRadius'>
      <div className="row dashboardMarginRight" style={{marginTop:"1.3vw", marginRight:'1vw', marginBottom:'1.3vw', marginLeft:'1.6vw'}}>
        <p style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}><b>Ongoing Tickets & Requests</b></p>
        <p style={{marginTop:"-0.6vw", textAlign:'left',fontFamily:'Blender Pro Book'}}>Tickets & Requests based on latest updates</p>
        <br/>
      </div>
      {data.map((item, index) => (
        <UrgentTicketExt key={'UT' + index} data={item} />
      ))}
      <div style={{marginBottom:'0.2vw'}}>&nbsp;</div>
    </div>
  )
}

export default UrgentTicketExtParent