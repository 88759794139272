import ContentHeader from "../content/ContentHeader"

const NoPage = () => {
    return <> 
    <ContentHeader title=" "/> 
    <section className="content">
  <div className="error-page">
    <h2 className="headline text-warning"> 404</h2>
    <div className="error-content">
      <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found.</h3>
      <p>
        We could not find the page you were looking for. <br/>
        <a href="/dashboard">Return to dashboard</a> <br />
        <a href="/backToPortal">Return to portal</a> <br />
      </p>
    </div>
  </div>
</section>

</>
  };
  
  export default NoPage;