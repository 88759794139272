import React, { useState , useEffect } from 'react';
import '../../../scripts/custom.css';
import { WarningCircle } from 'iconoir-react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
import CareerHeader from '../careerTemplate/CareerHeader';
function LoginComp({isLoading, buttonText, changeScreen, handleSubmit, username, password, setUsername, setPassword, login, errorMsg}) {
  // Add state variables to track form submission and email field
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [submittedPass, setSubmittedPass] = useState(false);
  const orientation = useOrientation();
  useEffect(() => {
    setSubmittedEmail(true);
    setSubmittedPass(true);
  }, [])                           
  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (username.length == 0) {
      setSubmittedEmail(false);
      //alert("no username");
      return;
    } else {
      setSubmittedEmail(true);
      //alert("have username");
    }

    if (password.length == 0) {
      setSubmittedPass(false);
      //alert("no pass");
      return;
    } else {
      setSubmittedPass(true);
      //alert("have pass");
    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
    //continue if no return
    handleSubmit(e);
  };
  
  return (
<div className="">
    <div className="" style={{ maxHeight: '100%', maxWidth: '100vw' }}>
        <div className="">
            <CareerHeader />
            <div className="hold-transition login-page">
                <div
                    className="hold-transition login-page"
                    style={{
                        backgroundImage: "url(/dist/img/bg-login.png)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw", // Ensure the width is also full screen
                    }}
                >
                    {login}
                    <br/><br/><br/>
                    <div className="login-box login-container" style={{ height: 'auto', width: '358px' }}>
                        <div className="">
                            <div className="card-body login-card-body" style={{ marginBottom: "-32px" }}>
                                <div className="login-logo font-befa" style={{ textAlign: 'left' }}>
                                    <p style={{ color: '#282828', marginBottom: '20px', marginLeft: '-10px', fontWeight: '700', fontSize: '24px', marginTop: '-10px' }}>Login</p>
                                </div>
                                <form style={{ width: '120%', marginLeft: '-10%' }} onSubmit={handleFormSubmit}>
                                    <div className="bottomPad textAlign" style={{ color: '#282828', marginBottom: '8px', marginLeft: '20px' }}><b>Email</b> <span style={{ color: 'red' }}> *</span> <br /></div>
                                    <div className="input-group mb-3">
                                        <input style={{ height: '52px', width: '278px !important', marginBottom: '30px', marginLeft: '20px', marginRight: '20px' }} type="text" className="form-control hdInput" placeholder="Input Email" name="email" autoComplete="off" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>

                                    {/* Display email error message only if submitted and email is empty */}
                                    {!submittedEmail && (
                                        <div className="font-befa" style={{ textAlign: 'left', width: '100%', marginTop: '-30px', marginBottom:'27px', marginLeft: '20px', position: 'relative' }}>
                                            <p style={{ position: 'absolute' }}>
                                                <WarningCircle width="16px" color="red" />
                                                &nbsp;&nbsp;&nbsp;
                                                <font color="red">Email is required</font>
                                            </p>
                                            <div style={{ paddingBottom: '3px' }}></div>
                                        </div>
                                    )}

                                    <div className="bottomPad textAlign" style={{ color: '#282828', marginBottom: '8px', marginLeft: '20px', marginTop:'-20px' }}><b>Password</b><span style={{ color: 'red' }}> *</span> <br /></div>
                                    <div className="input-group mb-3">
                                        <input style={{ height: '52px', width: '50px !important', marginLeft: '20px', marginRight: '20px', marginBottom:'50px' }} type="password" className="form-control hdInput" placeholder="Input Password" name="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    {/* Display password error message only if submitted and password is empty */}
                                    {!submittedPass && (
                                        <div className="font-befa" style={{ textAlign: 'left', width: '100%', marginTop: '-43px', marginBottom:'22px', marginLeft: '20px', position: 'relative' }}>
                                            <p style={{ position: 'absolute', marginTop: '-10px' }}>
                                                <WarningCircle width="16px" color="red" />
                                                &nbsp;&nbsp;&nbsp;
                                                <font color="red">Password is required</font>
                                            </p>
                                        </div>
                                    )}

                                    <div style={{ textAlign: 'left', width: '100%', position: 'relative', marginBottom: '22px' }}>
                                        {errorMsg.length > 0 && (
                                            <div className="font-befa" style={{ position: 'relative', marginTop: '-43px', marginLeft: '20px' }}>
                                                <p style={{ position: 'absolute', marginTop: '-10px' }}>
                                                    <WarningCircle width="16px" color="red" />&nbsp;&nbsp;&nbsp;
                                                    <font color="red">{errorMsg}</font>
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <button
                                                type="submit"
                                                className="btn btn-primary font-befa"
                                                style={{
                                                    borderRadius: "8px",
                                                    backgroundColor: "#0296D2",
                                                    position: "relative",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start", // Align content to the left
                                                    marginTop: errorMsg.length > 0 || !submittedPass ? '0px' : '-43px', // Adjust based on errorMsg
                                                    marginBottom: "15px",
                                                    marginLeft: "20px",
                                                    height: "36px",
                                                    padding: "0.5rem 1rem", // Adjust padding as needed
                                                    fontSize: "12px",
                                                    fontWeight: "600"
                                                }}
                                            >
                                                {isLoading && (
                                                    <span
                                                        className="fa fa-circle-o-notch fa-spin"
                                                        style={{
                                                            marginRight: "5px", // Adjust the margin as needed
                                                        }}
                                                    ></span>
                                                )}
                                                {buttonText}
                                            </button>
                                        </div>
                                    </div>

                                    <br />
                                </form>

                            </div>
                            <hr style={{ width: "112%", marginLeft: "-6%" }} />
                            <div style={{ color: '#0296D2', textAlign: 'left', width: '100%', marginTop: '15px', marginLeft: '10px', fontWeight: '600' }}>
                                <a className="cursorPointer" onClick={changeScreen}>Forget Password</a>
                            </div>
                            
                        </div>
                        <div id="requirement" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )

}

export default LoginComp